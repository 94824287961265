
<div class="modal-body" id="chooseCompanyModal">
  <form [formGroup]="formGroup">
    <div class="form-group" *ngIf="!isUserExternal">
      <label for="roleFriendlyName">Report Type</label>
      <div class="input-group">
        <select
          class="custom-select form-control"
          formControlName="roleFriendlyName"
          id="reportTypeModal"
          (change)="handleRoleChange()"
        >
          >
          <option
            *ngFor="let option of roles"
            [ngValue]="option"
          >
            {{ option }}
          </option>
        </select>
        <div class="input-group-append spinner" *ngIf="isLoadingRole">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="parentCustomerId">Company</label>
      <div class="input-group" *ngIf="!isUserExternal">
        <div class="input-with-icon-left">
          <input
            id="parentCustomerModal"
            [(ngModel)]="selectedNameWithCount"
            [ngModelOptions]="{ standalone: true }"
            [typeahead]="parentCustomers"
            typeaheadOptionField="nameWithCount"
            [typeaheadMinLength]="0"
            [typeaheadOptionsLimit]="100"
            (typeaheadOnSelect)="handleParentChange($event.item)"
            class="form-control"
            placeholder="Select a Customer..."
            autocomplete="off"
          />
          <div class="input-icon">
            <em class="modus-icon material-icons">search</em>
          </div>
        </div>
        <div class="input-group-append" *ngIf="!isLoadingParent">
          <button
            class="btn btn-icon-only btn-tertiary"
            id="clearButton"
            (click)="clearParent()"
          >
            <em class="modus-icon material-icons">close</em>
          </button>
        </div>
        <div class="input-group-append spinner" *ngIf="isLoadingParent">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="input-group" *ngIf="isUserExternal">
        {{user.customerCName}}
      </div>
    </div>
    <div class="form-group">
      <label for="childCustomerId">Affiliate</label>
      <div class="input-group">
        <select
          class="custom-select form-control"
          formControlName="childCustomerId"
          id="childCustomerId"
        >
          <option *ngFor="let option of childCustomers" [ngValue]="option?.id">
            {{ option?.nameWithCount }}
          </option>
        </select>
        <div class="input-group-append spinner" *ngIf="isLoadingChild">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" id="chooseCompanyCancel" (click)="close(true)">
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary"
    id="chooseCompanySaveButton"
    [disabled]="!isDirty() || !isValid() || isLoading"
    (click)="save()"
  >
    Choose Company
  </button>
</div>

