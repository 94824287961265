/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modals: BsModalRef[] = [];

  private readonly modalClassesLarge = 'modal-lg modal-dialog-centered';
  private readonly modalClassesMedium = 'modal-md modal-dialog-centered';
  private readonly modalClassesSmall = 'modal-sm modal-dialog-centered';
  private readonly modalClassesExtraSmall = 'modal-xs modal-dialog-centered';

  constructor(private bsModalService: BsModalService) { }

  public show(content: any, size: ModalSize, ignoreBackdropClick: boolean = false, initialState: any = ({})): BsModalRef {
    const classes = this.getModalClasses(size);

    const result = this.bsModalService.show(content, {
      keyboard: !ignoreBackdropClick,
      class: classes,
      ignoreBackdropClick,
      initialState
    });

    this.modals.push(result);

    return result;
  }

  public hide(bsModalRef: BsModalRef): void {
    if (bsModalRef !== null && bsModalRef.content !== null) {
      bsModalRef.hide();
      bsModalRef.content = null;
    }
  }

  public hideAll(): void {
    this.modals.forEach((modal: BsModalRef) => {
      if (modal){
        this.hide(modal);
      }
    });
  }

  public getModalClasses(size: ModalSize) {
    let result = '';

    switch (size) {
      case ModalSize.extraSmall:
        result = this.modalClassesExtraSmall;
        break;

      case ModalSize.large:
        result = this.modalClassesLarge;
        break;

      case ModalSize.medium:
        result = this.modalClassesMedium;
        break;

      case ModalSize.small:
        result = this.modalClassesSmall;
        break;
    }

    return result;
  }
}

export enum ModalSize {
  large = 0,
  medium = 1,
  small = 2,
  extraSmall = 3,
}
