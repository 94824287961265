/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorService } from '../../../../src/app/services/error.service';
import { IdentityService } from '../../../../src/app/services/identity.service';
import { StateService } from '../../../../src/app/services/state.service';
import { UserService } from '../../../../src/app/services/user.service';
import { environment } from '../../../../src/environments/environment';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit, OnDestroy {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() agreeEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;

  public showButtons = true;
  public subscriptions: Subscription[] = [];
  public busy = false;
  public disagreeToolTip = 'Please scroll to the bottom of the agreement';
  public agreeToolTip = 'Please scroll to the bottom of the agreement';

  constructor(
    public identityService: IdentityService,
    public stateService: StateService,
    public userService: UserService,
    public router: Router,
    public errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public close() {
    this.closeEvent.emit();
  }

  public accept() {
    const user = { ...this.stateService.currentUser };
    user.tosAccepted = true;
    user.tosDate = new Date();
    user.tosVersion = environment.tosVersion;
    this.busy = true;

    this.subscriptions.push(
      this.userService.postUpdateTerms(user.appId, true, user.tosVersion).subscribe(
        () => {
          this.stateService.setCurrentUser(user);
          this.agreeEvent.emit();
        },
        (error) => {
          this.errorService.navigateToErrorPage('An error occurred while updating Terms of Service acceptance.');
        }
      )
    );
  }

  public decline() {
    const user = this.stateService.currentUser;
    this.busy = true;
    user.tosAccepted = false;
    user.tosDate = new Date();
    user.tosVersion = environment.tosVersion;

    this.subscriptions.push(
      this.userService.postUpdateTerms(user.appId, false, user.tosVersion).subscribe(
        () => {
          sessionStorage.clear();
          localStorage.clear();
          this.identityService.logout();
        },
        (error) => {
          this.errorService.navigateToErrorPage('An error occurred while updating Terms of Service acceptance.');
        }
      )
    );
}
}
