<div class="modal-header">
  <h5 class="modal-title" id="profile-title">
    <p>Choose a company to view report.</p>
  </h5>
</div>
<ul class="list-group"
  *ngIf="!loading">
  <li class="list-group-item"
  *ngFor="let item of companies"
  (click)="pick(item)"
  [class.active]="item === selection"
  >
    {{item.customerName}}
  </li>
</ul>
<app-spinner *ngIf="loading"></app-spinner>
<div class="modal-footer">
  <button type="button"
  class="btn btn-primary" (click)="select()"
  [disabled]="selection === null || loading">
    Select
  </button>
</div>
