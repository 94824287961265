import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  public readonly showManufacturerFeature$: Observable<boolean>;

  private readonly subjectShowManufacturerFeature = new BehaviorSubject<boolean>(false);

  constructor() {
    this.showManufacturerFeature$ = this.subjectShowManufacturerFeature.asObservable();
  }

  public get showManufacturerFeature(): boolean {
    return this.subjectShowManufacturerFeature.getValue();
  }

  public setShowManufacturerFeature(showFlag: boolean) {
    this.subjectShowManufacturerFeature.next(showFlag);
  }
}
