import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertViewModel } from 'apps/analytics-portal/src/app/models/alert-view-model';
import { CustomerRole } from 'apps/analytics-portal/src/app/models/customer-role';
import { GetAlertViewModel } from 'apps/analytics-portal/src/app/models/get-alert-view-model';
import { ResponseModel } from 'apps/analytics-portal/src/app/models/responses/response-model';
import { AlertsService } from 'apps/analytics-portal/src/app/services/alerts.service';
import { CustomerService } from 'apps/analytics-portal/src/app/services/customer.service';
import { CustomerFeatureType } from 'apps/analytics-portal/src/app/models/customer-feature-type';
import { LifeCycleService } from 'apps/analytics-portal/src/app/services/life-cycle.service';
import { StateService } from 'apps/analytics-portal/src/app/services/state.service';
import { Observable, Subscription } from 'rxjs';
import { ErrorService } from '../services/error.service';
import { ToastService } from '../services/toast.service';
import { AlertTrigger } from '../models/alert-trigger';
import { AlertMonitor } from '../models/alert-monitor';
import { InternalNotificationService } from '../services/internal-notification.service';
import { PortalPageName } from '../models/portal-page-name';
import { FiltersService } from '../services/filters.service';
import { RegionalFiltersViewModel } from '../models/regional-filters-view-model';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnDestroy {

  @ViewChild('iframe') iframe: ElementRef;
  @HostBinding('class') class = 'modus-content-rows';

  public items: Observable<AlertViewModel[]> = null;
  public selectedItem: AlertViewModel = null;
  public hasItems = false;
  public isCreating = false;
  public isPremium = false;
  public editing = false;
  public subscriptions: Subscription[] = [];
  public regions: RegionalFiltersViewModel[];

  constructor(public stateService: StateService,
    public alertsService: AlertsService,
    public lifeCycleService: LifeCycleService,
     public toastService: ToastService,
     public customerService: CustomerService,
     public errorService: ErrorService,
     public internalNotificationService: InternalNotificationService,
     public filtersService: FiltersService,
     public router: Router) { }

  public get showSelectedItem(): boolean {
    return this.selectedItem !== null;
  }

  ngOnInit(): void {
    if(this.customerService.getCustomerRoleFromString(this.stateService.currentUser.customerRole) !== CustomerRole.manufacturer){
      this.router.navigateByUrl('/home');
      return;
    }
    this.isPremium = (this.stateService.currentUser.customerFeatureType === CustomerFeatureType.premium);
    if(this.isPremium){
      this.stateService.showModalProgress('Getting Alerts...');
      this.subscriptions.push(this.stateService.settingClaims.subscribe(
        setting => {
          if(!setting){
            this.getAlerts();
          }
        }
      ));

      this.items = this.stateService.alerts$;
      this.items.subscribe((items) => this.hasItems = items.length > 0);
      this.subscriptions.push(this.filtersService.getRegions().subscribe(
        (regionResponse: ResponseModel<RegionalFiltersViewModel[]>) => {
        this.regions = (regionResponse && regionResponse.data)? regionResponse.data : [];
        }
      ));
    }
  }

  ngOnDestroy(): void {
    this.filtersService.clearFilters();
    this.lifeCycleService.unsubscribeSubscriptions(this.subscriptions);
  }

  public openNotification(item: AlertViewModel){
    this.selectedItem = item;
    this.isCreating = false;
  }

  public deleteAlert() {
    this.stateService.showModalProgress('Deleting Alert...');
    this.stateService.deleteAlert(this.selectedItem);
    this.subscriptions.push(this.alertsService.deleteAlert(this.selectedItem.alertDefinitionId).subscribe(() => {
      this.stateService.hideModalProgress();
      this.toastService.success('Alert deleted.');
    }));
    this.selectedItem = null;
  }

  public editAlert() {
    this.editing = true;
    this.isCreating = true;
  }

  public closeAlert() {
    this.selectedItem = null;
  }

  initiateCreationProcess(){
    this.isCreating = true;
    this.selectedItem = new AlertViewModel();
  }

  public sendMarketingEmail(){
    this.subscriptions.push(this.internalNotificationService.sendRequestPremium(PortalPageName.Alerts).subscribe(() => {
    },
    (error) => {
      this.errorService.navigateToErrorPage('Error sending premium account request');
    }));
  }

  disableEditorComponent(alert: AlertViewModel){
    let successMsg = 'Alert successfully created!';
    if(this.editing) {
      successMsg = 'Alert changes saved!';
    }
    this.selectedItem = alert;
    if(this.editing || this.isCreating){
      this.getAlerts();
    }
    this.isCreating = false;
    this.editing = false;
    if (alert !== null){
      this.toastService.success(successMsg);
    }
  }

  private getAlerts(){
    this.subscriptions.push(this.alertsService.getAlerts().subscribe((response: ResponseModel<GetAlertViewModel[]>) => {
      const alerts: AlertViewModel[] = [];
      const responseAlerts = response ? response.data : [];
      if(response !== null && response.data !== undefined) {
        for(let i = 0; i < responseAlerts.length; i++) {
          const alert = response.data[i];
          alerts.push({
            alertDefinitionId: alert.alertDefinitionId,
            trigger: AlertTrigger[alert.trigger],
            count: alert.count,
            monitor: AlertMonitor[alert.monitor],
            ads: alert.ads,
            catalogKeys: alert.searchFilters.catalogKeys,
            regionalFilters: alert.searchFilters.regionalFilters,
            name: alert.name,
            dataStartDate: alert.dataStartDate,
            dataEndDate: alert.dataEndDate,
            notificationEndDate: alert.notificationEndDate,
            emailSharing: alert.emailSharing
          });
          if(this.selectedItem !== null &&
            alert.alertDefinitionId === this.selectedItem.alertDefinitionId){
            this.selectedItem = alerts[i];
          }
        }
      }
      this.stateService.setAlerts(alerts? alerts: []);
      this.stateService.hideModalProgress();
    }, () =>{
      this.stateService.hideModalProgress();
      this.errorService.navigateToErrorPage();
    }));
  }
}
