/* eslint-disable max-len */
/* eslint-disable quote-props */
/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LifeCycleService } from 'apps/analytics-portal/src/app/services/life-cycle.service';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { environment } from '../../src/environments/environment';
import { IdentityService } from './services/identity.service';
import { ModalService, ModalSize } from './services/modal.service';
import { StateService } from './services/state.service';
import { RefreshTokenComponent } from './shared/refresh-token/refresh-token.component';

export const ROOT_SELECTOR = 'app-root';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: ROOT_SELECTOR,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  public isAuthenticated = false;
  public isSidebarOpen = false;
  public subscriptions: Subscription[] = [];

  public hideHeaderFooterAndNav = true;
  public showAuthenticatedHeader = false;
  public showAuthenticatedFooter = false;
  public showAuthenticatedNavigation = false;
  public showUnauthenticatedHeader = false;
  public showUnauthenticatedFooter = false;
  public bsModalRef: BsModalRef = null;

  private warnUserAboutTokenRefreshOn: moment.Moment = null;

  constructor(
    private modalService: ModalService,
    public stateService: StateService,
    public router: Router,
    public identityService: IdentityService,
    public lifeCycleService: LifeCycleService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsKey,
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'page_path': event.urlAfterRedirects
          });
      }
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.stateService.hideHeaderFooterAndNav$.subscribe((hide: boolean) => {
        this.hideHeaderFooterAndNav = hide;
        this.evaluateHeaderFooterAndNavVisibility();
      })
    );

    this.subscriptions.push(
      this.stateService.isAuthenticated$.subscribe(
        auth => {
          this.isAuthenticated = auth;
          this.evaluateHeaderFooterAndNavVisibility();
        })
    );

    this.subscriptions.push(
      this.stateService.tokenTimeout$.subscribe(
        expiresOn => {
          this.warnUserAboutTokenRefreshOn = moment(expiresOn).subtract({
            milliseconds: environment.warningBeforeLogoutLengthInMilliseconds
          });
        }
      )
    );

    this.subscriptions.push(
      this.router.events.subscribe( event=> {
        if(event instanceof NavigationStart) {
          this.modalService.hideAll();
          this.stateService.refreshModalIsOpen = false;
        }
      })
    );

    setInterval(() => {
      const now = moment(new Date());
      if (now.isAfter(this.stateService.tokenTimeout) && this.stateService.idToken !== null) {
        this.identityService.logout();
        return;
      }
      if (this.isAuthenticated && now.isAfter(this.warnUserAboutTokenRefreshOn) && !this.stateService.refreshModalIsOpen) {
        this.showModalRefreshToken();
      }
    }, 5000);

    this.stateService.initBackButtonBehavior();
  }

  ngOnDestroy(): void {
    this.lifeCycleService.unsubscribeSubscriptions(this.subscriptions);
  }

  public showModalRefreshToken(): void {
    this.stateService.refreshModalIsOpen = true;
    this.modalService.hideAll();
    this.bsModalRef = this.modalService.show(RefreshTokenComponent, ModalSize.large, true);

    this.subscriptions.push(
      this.bsModalRef.content.refreshEvent.subscribe(() => {
        this.identityService.refreshAcessToken(() => {
          this.modalService.hide(this.bsModalRef);
          this.stateService.refreshModalIsOpen = false;
        });
      })
    );
  }

  public setIsSidebarOpen(value: boolean): void {
    this.isSidebarOpen = value;
  }

  public evaluateHeaderFooterAndNavVisibility(): void {
    if (this.hideHeaderFooterAndNav) {
      this.showAuthenticatedFooter = false;
      this.showAuthenticatedHeader = false;
      this.showAuthenticatedNavigation = false;
      this.showUnauthenticatedFooter = false;
      this.showUnauthenticatedHeader = false;
    } else {
      this.showAuthenticatedFooter = this.isAuthenticated;
      this.showAuthenticatedHeader = this.isAuthenticated && this.router.url !== '/terms';
      this.showAuthenticatedNavigation = this.isAuthenticated;
      this.showUnauthenticatedFooter = !this.isAuthenticated;
      this.showUnauthenticatedHeader = !this.isAuthenticated;
    }
  }
}
