<div class="component-wrapper">
  <div class="max-width-wrapper">
    <div *ngIf="closeButton" class="header">
      <h5>Data Sharing</h5>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <img class="top-image" [ngClass]="{ 'with-button': closeButton }" src="{{ imagePath }}" />
    <img class="logo" src="{{ logoPath }}" />
    <h2>{{ title }}</h2>
    <p class="info-text">{{ text }}</p>
    <div class="form-group">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" checked class="custom-control-input" id="acceptedCheckbox" [(ngModel)]="accepted" />
        <label class="custom-control-label" for="acceptedCheckbox">I Accept</label>
      </div>
      <button type="submit" class="btn btn-primary" (click)="submitNewUserRelation()">{{ buttonText }}</button>
    </div>
  </div>
</div>
