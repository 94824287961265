/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { EmbedReportViewModel } from "../../models/embed-report-view-model";
import { EmbedTokenViewModel } from "../../models/embed-token-view-model";
import { ForecastRequestModel } from "../../models/requests/forecast-request";
import { CustomerService } from "../../services/customer.service";
import { LogService } from "../../services/log.service";
import { ReportsService } from "../../services/reports.service";
import { StateService } from "../../services/state.service";
import { ToastService } from "../../services/toast.service";

@Component({
    selector: 'app-forecast-report',
    templateUrl: './forecast-report.component.html',
    styleUrls: ['./forecast-report.component.scss']
})

export class ForecastReportComponent implements OnInit, OnDestroy {
    @ViewChild('report', { read: ElementRef }) report: ElementRef;
    @Input() set updateForecastRequest(req: ForecastRequestModel) {
        if (req != null) {
            this.forecastReq = req;
            this.initReport();
        }
    }

    public forecastReq: ForecastRequestModel = new ForecastRequestModel();
    private subscriptions: Subscription[] = [];
    private expirationTimers: any[] = [];

    constructor(
        public stateService: StateService,
        public toastService: ToastService,
        public reportService: ReportsService,
        public customerService: CustomerService,
        public logService: LogService
    ) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.expirationTimers.forEach(e => clearInterval(e));
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public initReport(): void {
        if (this.forecastReq != null) {
            this.stateService.showModalProgress("Model is Building...");

            this.subscriptions.push(
                this.reportService.getCommodityForecast(this.forecastReq).subscribe((x: EmbedReportViewModel) => {
                    this.embedReport(x, this.report.nativeElement);
                }, err => {
                    this.stateService.hideModalProgress();
                    this.toastService.error("Error Loading Model. Please Try Again Later.", true);
                })
            );
        }
    }

    private embedReport(embedReport: EmbedReportViewModel, nativeElement: any): void {
        const config = this.reportService.getIEmbedConfigurationBase(embedReport);
        const report = this.reportService.embedPowerBiReport(nativeElement, config);
        this.stateService.hideModalProgress();

        report.on('loaded', event => {
            this.setTokenExpirationListener(embedReport.embedToken, nativeElement);
        });
    }

    private setTokenExpirationListener(token: EmbedTokenViewModel, element: any, minutesToRefresh = -1) {
        const currentTime = Date.now();
        const expiration = Date.parse(token.expiration);
        const safetyInterval = minutesToRefresh * 60 * 1000;
        const timeout = expiration - currentTime - safetyInterval;

        if(timeout <= 0){
            this.initReport();
        }
        else {
            this.logService.writeLine('Report Embed Token will be updated in ' + timeout + ' milliseconds.');
            this.expirationTimers.push(
                setTimeout(() => {
                    this.subscriptions.push(
                        this.reportService.getCommodityForecast(this.forecastReq).subscribe((x: EmbedReportViewModel) => {
                            this.reportService.resetEmbedToken(element, x);
                            this.setTokenExpirationListener(x.embedToken, element);
                        }, err => {
                            this.toastService.error("Error Loading Model. Please Try Again Later.", true);
                        })
                    );
                    this.logService.writeLine('Token reset');
                }, timeout)
            );
        }
    }
}