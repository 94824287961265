<div class="modal-progress">
  <div class="progress-container" id="dataLoadingModal">
    <img
      src="../../../assets/images/loader2.gif"
      width="90"
      height="75"
      alt="Loading Animation"
    />
    <p class="card-caption">{{ loadingText }}</p>
  </div>
</div>
