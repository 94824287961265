/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { UserViewModel } from '../../models/user-view-model';
import { StateService } from '../../services/state.service';
import { UserService } from '../../services/user.service';
import { UserInformationWrapper } from '../../models/user-information-wrapper';
import { UserInformationModel } from '../../models/user-information-model';
import { ReportsService } from '../../services/reports.service';
import { CustomerService } from '../../services/customer.service';
import { ErrorService } from '../../services/error.service';
import { ReportViewModel } from '../../models/report-view-model';
import { CustomerViewModel } from '../../models/customer-view-model';
import { Subscription } from 'rxjs/internal/Subscription';
import { CustomerRole } from '../../models/customer-role';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProfileComponent implements OnInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();

  public user = new UserViewModel();
  public parentCustomer: CustomerViewModel;
  public busyMessage = null;
  public profileForm: FormGroup;
  public customerForm: FormGroup;
  public isLoadingRole = false;
  public isLoadingParent = false;
  public isLoadingChild = false;
  public hasChildren;

  public isSelectingCustomer = false;
  public skipProfile = false; //if the user is going directly to choose company

  public reports: ReportViewModel[];
  public selectedNameWithCount = '';
  public customerChanged = false;

  public childCustomers: CustomerViewModel[] = [];
  public contractorList: CustomerViewModel[] = [];
  public distributorList: CustomerViewModel[] = [];
  public internalList: CustomerViewModel[] = [];
  public manufacturerList: CustomerViewModel[] = [];
  public parentCustomers: CustomerViewModel[] = [];
  public subscriptions: Subscription[] = [];

  public currentChild: CustomerViewModel;

  constructor(public reportsService: ReportsService,
    public customerService: CustomerService,
    public userService: UserService,
    public stateService: StateService,
    public storageService: StorageService,
    public errorService: ErrorService,
    public modalService: ModalService,
    private router: Router) {
  }

  public get isUserExternal(): boolean {
    return this.user?.customerRole.toLowerCase() !== CustomerRole.internal.toLowerCase();
  }

  public get isLoading(): boolean {
    return this.isLoadingChild || this.isLoadingParent || this.isLoadingRole;
  }

  ngOnInit(): void {
    this.user = this.stateService.currentUser;
    this.hasChildren = this.stateService.hasChildren;

    this.profileForm = new FormGroup({
      jobTitle: new FormControl(this.user.jobTitle),
      phoneNumber: new FormControl(this.user.phoneNumber),
      userTimezoneId: new FormControl(this.user.userTimezoneId)
    });


  }

 /**
  *Show Choose company component.
  *
  * @memberof ProfileComponent
  */
  public showChooseCompany(): void {
    this.isSelectingCustomer = true;
  }

  /**
   * Hide the "Choose Company view"
   *
   * @param customerChanged A new  customer was selected.
   * @param goBack True if the user selected the "My Profile" link explicitly.
   * @memberof ProfileComponent
   */
  public hideChooseCompany(customerChanged: boolean, goBack = false){
    this.customerChanged = customerChanged;
    if(this.skipProfile && !goBack){
      this.close(); //close directly if the user started in the choose company modal
    }
    else{
      this.isSelectingCustomer = false;
    }
  }

  public close(): void {
    if(this.customerChanged){ //refresh the page to show the new customer information
      const currentLocation = this.router.url;
      this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentLocation]);
      });
    }
    this.closeEvent.emit();
  }

  public async profileSave() {
    if(!this.isDirty()){
      this.close();
      return;
    }
    const jobTitle = this.profileForm.value.jobTitle === ''? null : this.profileForm.value.jobTitle;
    const phoneNumber = this.profileForm.value.phoneNumber === ''? null : this.profileForm.value.phoneNumber;
    const userObj = new UserInformationWrapper();
    userObj.data = [];
    userObj.data.push(new UserInformationModel(jobTitle, phoneNumber));
    this.user.jobTitle = jobTitle;
    this.user.phoneNumber = phoneNumber;
    this.busyMessage = 'Updating Profile';
    this.userService.updateUserInformation(userObj).subscribe(
      () => {
        this.user.jobTitle = jobTitle;
        this.user.phoneNumber = phoneNumber;
        this.close();
      }
    );
  }

  public isDirty(): boolean {
    return this.stateService.isDirty(this.user, this.profileForm.value);
  }
}
