import { Component, HostListener, OnInit } from '@angular/core';
import { ReportsService } from '../services/reports.service';
import { LogService } from '../services/log.service';
import { StateService } from '../services/state.service';
import { ModalService } from '../services/modal.service';
import { ErrorService } from '../services/error.service';
import { CustomerRole } from '../models/customer-role';
import { UserViewModel } from '../models/user-view-model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { models } from 'powerbi-client';
import { take } from 'rxjs/operators';
import { EmbedReportViewModel } from '../models/embed-report-view-model';
import { EmbedTokenViewModel } from '../models/embed-token-view-model';

@Component({
  selector: 'app-reports',
  templateUrl: './tabbed-reports.component.html',
  styleUrls: [ './reports.component.scss'],
})

export class ReportsBaseComponent implements OnInit {
  public user: UserViewModel;
  public reportLayoutType: models.LayoutType;
  public showError = false;
  public embedToken: EmbedTokenViewModel;
  public subscriptions: Subscription[] = [];
  public reportLoaded = new BehaviorSubject<boolean>(false);
  public loading = false;
  public report: any;
  public expirationTimers: any[] = [];

  constructor(
    public reportsService: ReportsService,
    public logService: LogService,
    public stateService: StateService,
    public modalService: ModalService,
    public errorService: ErrorService
  ) {

  }

  public get role(): string {
    return this.stateService.effectiveParentCustomer.role;

  }

  public get isInternalRoleSelected(): boolean {
    return this.role.toLowerCase() === CustomerRole.internal.toLowerCase();
  }

  async ngOnInit(): Promise<void> {
    const user = this.stateService.currentUser;
    this.user = user;
  }

  ngOnDestroy(): void {
    this.expirationTimers.forEach(e => clearInterval(e));
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getReportLayoutType(viewWidth: number): models.LayoutType {
    if (viewWidth <= 576) {
      return models.LayoutType.MobilePortrait;
    } else {
      return models.LayoutType.Master;
    }
  }

    public embedPowerBIReport(reportId: string, element: HTMLElement): void {
      this.subscriptions.push(this.reportsService
        .getEmbedReport(reportId)
        .pipe(take(1))
        .subscribe(
          (embedReport: EmbedReportViewModel) => {
            this.embedBIReport(embedReport, element);

            this.reportsService.resetEmbedToken(element, embedReport);
            return embedReport;
          },
          (error) => {
            this.showError = true;
            this.stateService.hideModalProgress();
            console.error('Get Embed Report Error:', error);
          }
        ));
    }

    public embedPowerBIReportInternal(reportId: string, element: HTMLElement): void {
      const customerId = this.isInternalRoleSelected? '' : this.stateService.effectiveChildCustomer.id.toString();
      this.subscriptions.push(this.reportsService
        .getInternalEmbedReport(customerId, reportId, this.role)
        .pipe(take(1))
        .subscribe(
          (embedReport: EmbedReportViewModel) => {
            this.embedBIReport(embedReport, element);

            this.reportsService.resetEmbedToken(element, embedReport);
            return embedReport;
          },
          (error) => {
            console.log(customerId);
            this.stateService.hideModalProgress();
            this.showError = true;
            console.error('Get Embed Report Error:', error);
          }
        ));
    }

    public embedPowerBIReportChild(reportId: string, element: HTMLElement): void {
      this.subscriptions.push(this.reportsService
        .getChildEmbedReport(reportId)
        .pipe(take(1))
        .subscribe(
          (embedReport: EmbedReportViewModel) => {
            this.embedBIReport(embedReport, element);

            // indicates that tab has been loaded
            this.reportsService.resetEmbedToken(element, embedReport);
            return embedReport;
          },
          (error) => {
            this.stateService.hideModalProgress();
            this.showError = true;
            console.error('Get Embed Report Error:', error);
          }
        ));
    }

    public embedBIReport(embedReport: EmbedReportViewModel, element: HTMLElement): void {
      this.embedToken = embedReport.embedToken;

      const config = this.reportsService.getIEmbedConfigurationBase(embedReport, this.reportLayoutType);
      this.report = this.reportsService.embedPowerBiReport(element, config);

      this.report.off('loaded');
      this.report.on('loaded', () => {
        setTimeout(() => {
          this.reportLoaded.next(true);
          this.loading = false;
          this.stateService.hideModalProgress();
        }, 2000);
      });

      this.report.off('error');
      this.report.on('error', (event) => {
        console.error('Report Display Error:', event.detail);
        setTimeout(() => {
          this.reportLoaded.next(true);
          this.loading = false;
          this.stateService.hideModalProgress();
        }, 2000);
      });
    }

}
