/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService, ModalSize } from '../../../services/modal.service';
import { StateService } from '../../../services/state.service';
import { TermsOfServiceComponent } from '../../../shared/terms-of-service/terms-of-service.component';
import { AboutComponent } from '../../components/about/about.component';
import { ProfileComponent } from '../../../shared/profile/profile.component';
import { environment } from '../../../../environments/environment';
import { CustomerRole } from '../../../models/customer-role';
import { CustomerService } from '../../../services/customer.service';

@Component({
  selector: 'app-home-default',
  templateUrl: './home-default.component.html',
  styleUrls: ['./home-default.component.scss']
})
export class HomeDefaultComponent implements OnInit, OnDestroy {
  public bsModalRef: BsModalRef;
  public showMessagesFeature = false;
  public isElligibleCustomerRole = false;
  public subscriptions: Subscription[] = [];

  constructor(public modalService: ModalService, private stateService: StateService, public customerService: CustomerService) {
  }

  ngOnInit() {
    this.stateService.setIsAuthenticated(true);
    const customerRole = this.customerService.getCustomerRoleFromString(this.stateService.currentUser.customerRole);
    if (customerRole === CustomerRole.contractor || customerRole === CustomerRole.manufacturer) {
      this.isElligibleCustomerRole = true;
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public openModalProfile(): void {
    this.bsModalRef = this.modalService.show(ProfileComponent, ModalSize.medium);

    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.closeModal();
    });
  }

  public openModalAbout(): void {
    this.bsModalRef = this.modalService.show(AboutComponent, ModalSize.small);

    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.closeModal();
    });
  }

  public openModalTerms(): void {
    this.bsModalRef = this.modalService.show(TermsOfServiceComponent, ModalSize.large);

    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.closeModal();
    });

    this.bsModalRef.content.agreeEvent.subscribe(() => {
      this.closeModal();
    });
  }

  public closeModal(): void {
    this.modalService.hide(this.bsModalRef);
  }
}
