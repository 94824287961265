import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})

// Excluded from code coverage since calling these methods will cause the test runner to fail the build
export class ErrorService {
  public errorMessage: string = null;
  constructor(public router: Router) { }

  public static handleHttpErrorResponse(errorResponse: HttpErrorResponse): Observable<never> {
    const logService = new LogService();
    if (errorResponse.status === 0) {
      logService.writeLine([`An error occurred while contacting ${errorResponse.url}:`, errorResponse.error]);
    } else {
      logService.writeLine([`${errorResponse.url} returned an error`, errorResponse.error]);
    }

    return throwError(
      errorResponse.error ? errorResponse.error : 'An unknown error occured'
    );
  }

  public navigateToErrorPage(additionalErrorInformation: string = null): void {
    this.errorMessage = additionalErrorInformation;
    this.router.navigateByUrl('/error');
  }
}
