<div>
  <mep-data-sharing
    imagePath="../../assets/images/backgrounds/data-sharing-background.png"
    title="test"
    text="test"
    userUuid="{{stateService.currentUser.userId}}"
    buttonText="Continue"
    getUserRelations="true"
    class="data-sharing">
  </mep-data-sharing>
</div>
  
