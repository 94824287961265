/*
 * All rights reserved.
 *
 * The entire contents of this file is protected by U.S. and
 * International Copyright Laws. Unauthorized reproduction,
 * reverse-engineering, and distribution of all or any portion of
 * the code contained in this file is strictly prohibited and may
 * result in severe civil and criminal penalties and will be
 * prosecuted to the maximum extent possible under the law.
 *
 * CONFIDENTIALITY
 *
 * This source code and all resulting intermediate files, as well as the
 * application design, are confidential and proprietary trade secrets of
 * Trimble Inc.
 */

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PublicService } from '../../../../../src/app/services/public.service';
import { LogoutReasons } from '../../../home/models/logout-reasons';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-unauthenticated',
  templateUrl: './video-intro.component.html',
  styleUrls: ['./video-intro.component.scss']
})
export class VideoIntroComponent implements OnInit, OnDestroy {
  currentRoute = '';
  public showManufacturerFeatureFlag = false;
  public showDistributorFeatureFlag = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private renderer2: Renderer2,
    public router: Router,
    public publicService: PublicService,
    public toastService: ToastService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    if(sessionStorage.getItem(LogoutReasons.logoutReason) === LogoutReasons.userNotFound) {
      // eslint-disable-next-line max-len
      this.toastService.error('Your account has not been fully set up yet. Please contact your account manager for help setting up your account.');
    }
    if(sessionStorage.getItem(LogoutReasons.logoutReason) === LogoutReasons.distributor) {
      // eslint-disable-next-line max-len
      this.toastService.error('Distributor users are not currently supported.');
    }

    const vidyardImport = this.renderer2.createElement('script');
    vidyardImport.type = `text/javascript`;
    vidyardImport.src = 'https://play.vidyard.com/embed/v4.js';
    vidyardImport.async = true;

    this.renderer2.appendChild(this.document.body, vidyardImport);

    const vidyardLaunch = this.renderer2.createElement('script');
    vidyardLaunch.type = `text/javascript`;
    vidyardLaunch.text = `
      function launchLightbox(val) {
        var players = VidyardV4.api.getPlayersByUUID(val);
        var player = players[0];
        player.showLightbox();
      }
    `;

    this.renderer2.appendChild(this.document.body, vidyardLaunch);

    this.subscriptions.push(
      this.publicService.showManufacturerFeature$.subscribe((showFeature: boolean) => {
        this.showManufacturerFeatureFlag = showFeature;
      })
    );


  }

  public showManufacturerFeature() {
    this.publicService.setShowManufacturerFeature(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
