/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { AlertMonitor } from 'apps/analytics-portal/src/app/models/alert-monitor';
import { AlertTrigger } from 'apps/analytics-portal/src/app/models/alert-trigger';
import { EmailSharingModel } from './email-sharing-model';
import { RegionalViewModel } from './regional-view-model';

export class AlertViewModel {
  public alertDefinitionId: string;
  public trigger: AlertTrigger;
  public count: number;
  public monitor: AlertMonitor;
  public ads: string[];
  public catalogKeys: string[];
  public regionalFilters: RegionalViewModel[];
  public name: string;
  public dataStartDate: string;
  public dataEndDate: string;
  public notificationEndDate: string;
  public emailSharing: EmailSharingModel[];
}
