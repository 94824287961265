/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomerRole } from '../models/customer-role';
import { CustomerService } from '../services/customer.service';
import { StateService } from '../services/state.service';
import { ToastService } from '../services/toast.service';
import { CustomerFeatureType } from '../models/customer-feature-type';

@Component({
  selector: 'app-home-component',
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'modus-content-rows';
  public subscriptions: Subscription[] = [];
  public showDefaultHome = false;
  public showManufacturerHome = false;
  public customerRole = '';

  constructor(
    public stateService: StateService,
    public customerService: CustomerService,
    public toastService: ToastService,
    private router: Router) {
  }

  ngOnInit() {
    this.customerRole = this.customerService.getCustomerRoleFromString(this.stateService.effectiveRole);
    this.showDefaultHome = this.customerRole !== CustomerRole.manufacturer;
    this.showManufacturerHome = (this.customerRole === CustomerRole.manufacturer && this.stateService.currentUser.customerFeatureType.toUpperCase() !== CustomerFeatureType.advertisement);
    //reload after claims set if needed
    this.subscriptions.push(this.stateService.settingClaims.subscribe(
      s => {
        if(!s){
          if(this.customerRole !== this.customerService
            .getCustomerRoleFromString(this.stateService.effectiveRole)){
              const currentLocation = this.router.url;
              this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
                this.router.navigate([currentLocation]);
              });
          }
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
