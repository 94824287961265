/*
 * All rights reserved.
 *
 * The entire contents of this file is protected by U.S. and
 * International Copyright Laws. Unauthorized reproduction,
 * reverse-engineering, and distribution of all or any portion of
 * the code contained in this file is strictly prohibited and may
 * result in severe civil and criminal penalties and will be
 * prosecuted to the maximum extent possible under the law.
 *
 * CONFIDENTIALITY
 *
 * This source code and all resulting intermediate files, as well as the
 * application design, are confidential and proprietary trade secrets of
 * Trimble Inc.
 */

import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../src/environments/environment';
import { StateService } from '../services/state.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {

  constructor(
    private stateService: StateService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    const gainSightScript = this.document.querySelector('#gainsight-script');
    if (gainSightScript) {
      gainSightScript.setAttribute('src', environment.gainsightScriptPath);
    }
    this.stateService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.insertGainsightIdentity();
      }
    });
    this.insertGoogleAnalytics();
  }

  insertGainsightIdentity() {
    try {
      const aptrinsicScript = `aptrinsic('identify',
      {
        'id': '${this.stateService.currentUser.userId}',             // Required for logged in app users
        'email': '${this.stateService.currentUser.email}',
        'firstName': '${this.stateService.currentUser.firstName}',
        'lastName': '${this.stateService.currentUser.lastName}',
        'accountName': '${this.stateService.currentUser.customerCName}'
      },
      {
        'id': '${this.stateService.currentUser.customerId}',                         //Required
        'name': '${this.stateService.currentUser.customerCName}',
        'Customer_Role': '${this.stateService.currentUser.customerRole}'
      });`;


      const scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.innerHTML = aptrinsicScript;

      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    } catch {
      // Ignore Uncaught ReferenceError: aptrinsic is not defined thrown
      // (Occurs when unit testing this method)
    }
  }

  insertGoogleAnalytics() {
    // Add async global Google Analytics script
    const scriptTag1 = document.createElement('script');
    scriptTag1.async = true;
    scriptTag1.type = 'text/javascript';
    scriptTag1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
    document.getElementsByTagName('head')[0].appendChild(scriptTag1);

    // Add standard Google Analytics script
    let script = 'window.dataLayer = window.dataLayer || [];\n';
    script += 'function gtag(){dataLayer.push(arguments);}\n';
    // eslint-disable-next-line @typescript-eslint/quotes
    script += "gtag('js', new Date());\n";
    // eslint-disable-next-line @typescript-eslint/quotes
    script += "gtag('config', '" + environment.googleAnalyticsKey + "')\n";

    const scriptTag2 = document.createElement('script');
    scriptTag2.type = 'text/javascript';
    scriptTag2.innerHTML = script;
    document.getElementsByTagName('head')[0].appendChild(scriptTag2);
  }
}
