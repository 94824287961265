/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { CustomClaim } from '../models/requests/custom-claim';
import { AuthorizationSession } from '../models/responses/authorization-session';
import { UserInformationWrapper } from '../models/user-information-wrapper';
import { UserViewModel } from '../models/user-view-model';
import { ErrorService } from './error.service';
import { CustomerViewModel } from '../models/customer-view-model';
import { AccountViewModel } from '../models/responses/account-view-model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseUrl = `${environment.userServiceUrl}User`;

  constructor(private http: HttpClient) { }

  public postUserViewModel(userViewModel: UserViewModel, customerRole: string = ''): Observable<UserViewModel> {
    const url = `${this.baseUrl}?persona=${customerRole}`;

    return this.http.post<UserViewModel>(url, userViewModel)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public putUserViewModel(userViewModel: UserViewModel): Observable<UserViewModel> {
    return this.http.put<UserViewModel>(this.baseUrl, userViewModel)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public getUserProfile(customerRole: string = ''): Observable<UserViewModel> {
    const url = `${this.baseUrl}/Applications?persona=${customerRole}`;
    return this.http.get<UserViewModel>(url)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public updateUserInformation(user: UserInformationWrapper){
    const url = `${this.baseUrl}/UserProfile`;
    return this.http.post<UserInformationWrapper>(url, user)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public postUpdateLoginCount(appId: string){
    const url = `${this.baseUrl}/UpdateLoginCount`;
    return this.http.post<string>(url, {})
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }
  public postUpdateTerms(appId: string, tosAccepted: boolean, tosVersion: string): Observable<string>{
    const url = `${this.baseUrl}/UpdateTerms/${tosAccepted}/${tosVersion}`;
    return this.http.post<string>(url, {})
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public getAllCustomers(){
    const url = `${this.baseUrl}/AllCustomers`;
    return this.http.get<AccountViewModel[]>(url)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  /**
   *Custom claim for bearer token.
   *
   * @param claims
   * @memberof UserService
   */
  public addClaim(claims: CustomClaim[]): Observable<AuthorizationSession>{
    const url = `${this.baseUrl}/AddClaim`;
    return this.http.post<AuthorizationSession>(url, claims).
      pipe(catchError(ErrorService.handleHttpErrorResponse));
  }
}
