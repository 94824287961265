/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

export class UserViewModel {
  public appId = '';
  public customerAddress1 = '';
  public customerAddress2 = '';
  public customerCity = '';
  public customerCName = '';
  public customerCountry = '';
  public customerCTidv1 = '';
  public customerId: number;
  public customerFeatureType = 'PREMIUM';
  public customerPhone = '';
  public customerPostalCode = '';
  public customerPurchaseDate: any;
  public customerRole = '';
  public customerState = '';
  public customerTosAccepted = false;
  public customerTosAcceptedBy = '';
  public customerTosDate: any;
  public customerTosVersion = '';
  public customerUcc = '';
  public email = '';
  public firstName = '';
  public jobTitle = '';
  public lastLogin: Date;
  public lastName = '';
  public loginCount: number;
  public phoneNumber = '';
  public piiEffectiveDate: any;
  public piiFlag = true;
  public tosAccepted = false;
  public tosDate: any;
  public tosVersion = '';
  public userBookmark = '';
  public userId = '';
  public userLanguage = '';
  public userTimezone: number;
  public userTimezoneId: number;
  public enterpriseId = 0;
}
