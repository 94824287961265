import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutReasons } from '../../../../src/app/home/models/logout-reasons';
import { ToastService } from '../../../../src/app/services/toast.service';
import { IdentityService } from '../../../../src/app/services/identity.service';
import { PublicService } from '../../../../src/app/services/public.service';

@Component({
  selector: 'app-unauthenticated-header',
  templateUrl: './unauthenticated-header.component.html',
  styleUrls: ['./unauthenticated-header.component.scss']
})
export class UnauthenticatedHeaderComponent implements OnInit {

  @Input() currentRoute: string;

  constructor(
    private identityService: IdentityService,
    private publicService: PublicService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
  }

  public login(): boolean {
    sessionStorage.removeItem(LogoutReasons.logoutReason);
    this.identityService.navigateToTIDLogin(location.pathname);
    return false;
  }

  public showVideoComponent() {
    this.publicService.setShowManufacturerFeature(false);
  }

  public showManufacturerFeature() {
    this.publicService.setShowManufacturerFeature(true);
  }

}
