/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { LogService } from '../../../../../src/app/services/log.service';
import { VersionService } from '../../../../../src/app/services/version.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})

export class AboutComponent implements OnInit, OnDestroy {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  public notificationVersionNo = ' ';
  public portalVersionNo = ' ';
  public reportsVersionNo = ' ';
  public userVersionNo = ' ';

  private subsciptionArray: Subscription[];

  constructor(public versionService: VersionService, public logService: LogService) { }

  ngOnInit(): void {

    this.subsciptionArray = [];

    // Portal Version
    try {
      this.subsciptionArray.push(
        this.versionService.getVersion().subscribe((res: any) => {
          this.portalVersionNo = this.formatVersion(res.PingResponse.Version);
        })
      );
    } catch (error) {
      this.logError(error);
    }

    // User Version
    try {
      this.subsciptionArray.push(
        this.versionService.getUserServiceVersion().subscribe((res: any) => {
          this.userVersionNo = this.formatVersion(res.version);
        })
      );
    } catch (error) {
      this.logError(error);
    }

    // Notification Version
    try {
      this.subsciptionArray.push(
        this.versionService.getNotificationServiceVersion().subscribe((res: any) => {
          this.notificationVersionNo = this.formatVersion(res.version);
        })
      );
    } catch (error) {
      this.logError(error);
    }

    // Reports Version
    try {
      this.subsciptionArray.push(
        this.versionService.getReportServiceVersion().subscribe((res: any) => {
          this.reportsVersionNo = this.formatVersion(res.buildVersion);
        })
      );
    } catch (error) {
      this.logError(error);
    }
  }

  ngOnDestroy() {
    this.subsciptionArray.forEach(element => { element.unsubscribe(); });
  }

  public close(): void {
    this.closeEvent.emit();
  }

  public formatVersion(versionNumber: string) {
    const result = versionNumber || '';
    if (result.includes('-')) {
      return result.substr(0, result.indexOf('-'));
    }
    else {
      return result;
    }
  }

  public logError(error: any) {
    this.logService.writeLine('An error occurred fetching estimation version...');
    this.logService.writeLine(error);
  }
}
