/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  public allowLogging = true;
  constructor() {
    this.allowLogging = environment.debug;
  }

  public setAllowLogging(allow: boolean): void {
    this.allowLogging = allow;
  }

  public writeLine(args: any): void {
    if (!this.allowLogging) {
      return;
    }

    console.log(args);
  }
}
