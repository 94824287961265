import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LifeCycleService {

  constructor() { }

  public unsubscribeSubscriptions(subscriptions) {
    subscriptions.forEach(s => s.unsubscribe());
  }
}
