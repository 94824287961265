import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'apps/analytics-portal/src/app/services/data-sharing.service';
import { ModalService, ModalSize } from 'apps/analytics-portal/src/app/services/modal.service';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { LogoutReasons } from '../home/models/logout-reasons';
import { ClaimType } from '../models/claim-type';
import { CustomerModel } from '../models/customer-model';
import { CustomerRole } from '../models/customer-role';
import { CustomClaim } from '../models/requests/custom-claim';
import { AuthExchangeResponseModel } from '../models/responses/auth-exchange-response-model.model';
import { AuthorizationSession } from '../models/responses/authorization-session';
import { UserViewModel } from '../models/user-view-model';
import { ErrorService } from './error.service';
import { StateService } from './state.service';
import { StorageService } from './storage.service';
import { ToastService } from './toast.service';
import { UserService } from './user.service';
import { CustomerSelectComponent } from '../reports/contractor/customer-select.component';
import { CustomerViewModel } from '../models/customer-view-model';
import { LicenseViewModel } from '../models/responses/license-view-model';
import { LicenseRequestModel } from '../models/requests/license-request-model';
import { AccountViewModel } from '../models/responses/account-view-model';
import { ProfilesService } from '@hcworkspace/analytics-portal/data-sharing';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  public subscriptions: Subscription[] = [];
  public modalRef: BsModalRef;

  constructor(
    public stateService: StateService,
    public http: HttpClient,
    public storageService: StorageService,
    public userService: UserService,
    public router: Router,
    public errorService: ErrorService,
    public profilesService: ProfilesService,
    public modalService: ModalService,
    public dataSharingService: DataSharingService,
    public toastService: ToastService
  ) { }

  public navigateToTIDLogin(finalRoute: string) {
    this.storageService.setRedirectUrl(finalRoute);
    const url = new URL(window.location.href);
    const isAutomation = url.searchParams.get('automation');
    if(isAutomation && isAutomation === 'true') {
      // eslint-disable-next-line max-len
      this.navigate(`${environment.tidUrl}/oauth/authorize?response_type=code&client_id=${environment.clientId}&redirect_uri=${location.origin + '/login'}&scope=openid ${environment.tidAppName}&identity_provider=trimble_automation`);
    }
    else {
      // eslint-disable-next-line max-len
      this.navigate(`${environment.tidUrl}/oauth/authorize?response_type=code&client_id=${environment.clientId}&redirect_uri=${location.origin + '/login'}&scope=openid ${environment.tidAppName}`);
    }
  }

  public navigate(url: string) {
    window.location.href = url;
  }

  public exchangeAuthCode(authCode: string): Observable<AuthExchangeResponseModel> {
    return this.http.post<AuthExchangeResponseModel>(
      `${environment.authenticationUrl}/authentications/v3/Authentication/${authCode}/${environment.productId}`, {}
    ).pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public createLicenseRequest(accountNumber: string, sku: string): Observable<boolean> {
    const licenseRequest = {
      accountId: accountNumber,
      coreFeature: 'FEA-CONANYLT-PREM',
      deviceId: 0,
      licenseType: 1 //emsNamed
    } as LicenseRequestModel;

    return this.checkOutLicense(licenseRequest).pipe(
      // bcs i assume we'll look at skus im disabling the lint
      // eslint-disable-next-line arrow-body-style
      map((res) => {
        return true;
        //dont need sku check rn?
      // if(res.sku in []){
      //   return true;
      // }
      // else{
      //   return false;
      // }
    },
    (error) => {
      if(error.status in [401, 404]){
        return false;
      }
      else{
        throw new Error('Error connecting to licensing');
      }
    }));
  }
  public checkOutLicense(req: LicenseRequestModel ): Observable<LicenseViewModel>{
    return this.http.post<LicenseViewModel>(
      `${environment.authenticationUrl}/licenses/License?api-version=4`, req
    ).pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public setContractorLicense(company: AccountViewModel): number{
    const choiceCustomer = {
      id: company.snowflakeId,
      name: company.customerName,
      role: CustomerRole.contractor
    } as CustomerModel;
    this.stateService.setEffectiveParentCustomer(choiceCustomer);
    this.stateService.setEffectiveChildCustomer(choiceCustomer);

    if(company.snowflakeId > 0){
      this.addCustomerIdClaims(choiceCustomer, choiceCustomer);
    }

    return company.snowflakeId;
  }

  public async setUser(res: AuthExchangeResponseModel) {
    let personaOverride = '';
    if(this.storageService.getRedirectUrl().indexOf('/contractor/') > 0){
      // treat all workcenter users as contractors
      personaOverride = CustomerRole.contractor;
    }
    this.userService.getUserProfile(personaOverride).subscribe(
      (user: UserViewModel) => {

        this.stateService.setCurrentUser(user);
        if(this.licenseCheck()){
          this.incrementLoginCountAndDateForUser(user);
          this.addCustomerIdClaims(this.stateService.effectiveParentCustomer,
          this.stateService.effectiveChildCustomer, true);
        }

      },
      async (error) => {
        switch (error.status) {
          case 404:
            this.handleUserNotFound(res);
            break;

          default:
            this.handleUserServiceError();
            break;
        }
      }
    );
  }

  public async addCustomerIdClaims(parentCustomer: CustomerModel, childCustomer: CustomerModel, login = false){
    // if(this.stateService.currentUser.customerId === -1){
    //   this.logout();
    // }
    const claims = [
      {
        type: ClaimType.parentCustomerId,
        value: parentCustomer.id.toString()
      },
      {
        type: ClaimType.childCustomerId,
        value: childCustomer.id.toString()
      }
    ] as CustomClaim[];
    if (this.stateService.currentUser.customerRole.toLowerCase()
    === CustomerRole.internal.toLowerCase()) {
      this.stateService.setEffectiveRole(parentCustomer.role);
    }
    this.subscriptions.push(
      this.userService.addClaim(claims).subscribe(
        (session: AuthorizationSession) => {
          if(session !== null){
            //do not override this.stateService.idToken with Token value from session object.
            //session.Token is jwtToken after customclaim is added
            this.stateService.setAccessToken(session.accessToken);
            //this.stateService.setIdToken(session.token);
            this.stateService.setTokenTimeout(moment(session.expires));
          //set effective child
          this.stateService.setEffectiveChildCustomer(childCustomer);

          // Internal users
          if (this.stateService.currentUser.customerRole.toLowerCase()
             === CustomerRole.internal.toLowerCase()) {
             // save external customer choices
             this.stateService.setEffectiveParentCustomer(parentCustomer);
             // Save role
             this.storageService.setReportRoleChoice(this.stateService.effectiveRole);
          }

          // We save parent for external users as well to validate
          this.storageService.setParentCustomer(this.stateService.effectiveParentCustomer.id.toString(),
            this.stateService.effectiveParentCustomer.name);
          this.storageService.setChildCustomer(this.stateService.effectiveChildCustomer.id.toString(),
            this.stateService.effectiveChildCustomer.name);
          this.stateService.setSettingClaims(false);
          }
          else{
            this.logout();
          }
        },
        error => {
          this.stateService.setEffectiveRole(this.stateService.effectiveParentCustomer.role);

          this.stateService.setSettingClaims(false);
          if(login ){
            this.handleUserServiceError();
          }
          else{
            this.toastService.error('Error setting company.');
          }
        }
      )
    );
  }

  public async initializeUser(res: AuthExchangeResponseModel) {
    // Check if User has set their Data Sharing Preference
    // const userRelationsList = await this.getUserRelationsList();
    // const dataSharingPreference = this.profilesService.accepted(userRelationsList);
    // this.stateService.setDataSharingAccepted(dataSharingPreference);
    // if( dataSharingPreference === null  && !this.storageService.getRedirectUrl().includes('data-sharing')) {
    //   const navigateToRedirectUrl = this.navigateToRedirectUrl.bind(this);
    //   this.dataSharingService.setUpDataSharingModal(false, false, navigateToRedirectUrl);
    // } else {
      this.navigateToRedirectUrl();
    // }
    this.stateService.setIsAuthenticated(true);
    this.stateService.setHasValidToken(true);
    this.stateService.setTokenTimeout(moment(res.expires));
  }


  public handleUserNotFound(response: AuthExchangeResponseModel): void {
    this.createNewUser(response).subscribe((newUser: UserViewModel) => {
      this.stateService.setCurrentUser(newUser);
      if(this.licenseCheck()){
        this.addCustomerIdClaims(this.stateService.effectiveParentCustomer,
          this.stateService.effectiveChildCustomer, true);
      }
    },
      async (error) => {
        this.handleUserServiceError();
      });
  }

  public handleUserServiceError(): void {
    if(this.storageService.getRedirectUrl().indexOf('contractor') > 0){
      this.stateService.setHasValidToken(true);
      this.navigateToRedirectUrl();
    }
    else{
      this.errorService.navigateToErrorPage('An error was encountered while contacting the UserService');
    }
  }

  public createNewUser(res: AuthExchangeResponseModel): Observable<UserViewModel> {
    const names = this.getFirstAndLastNameFromString(res.name);

    const user = new UserViewModel();
    user.userId = res.uuid;
    user.appId = environment.appId;
    user.firstName = names.firstName;
    user.lastName = names.lastName;
    user.email = res.email;
    user.piiFlag = false;
    user.tosAccepted = false;
    user.customerTosAccepted = false;
    user.lastLogin = new Date();
    user.loginCount = 1;

    if(this.storageService.getRedirectUrl().indexOf('contractor') > 0){
      // treat all workcenter users as contractors
      return this.userService.postUserViewModel(user, CustomerRole.contractor);
    }
    return this.userService.postUserViewModel(user);
  }

  public getFirstAndLastNameFromString(name: string): any {
    name = name?.trim() ?? '';
    const array = name.split(/\s+/);
    const firstName = array[0];
    const lastName = name.slice(firstName.length).trim();

    return {
      firstName,
      lastName
    };
  }

  public incrementLoginCountAndDateForUser(user: UserViewModel): void {
    this.userService.postUpdateLoginCount(user.appId).subscribe();
  }

  public refreshAcessToken(callback: any = null): void {
    this.http.post<AuthExchangeResponseModel>(
      `${environment.authenticationUrl}/authentications/v3/Authentication/refresh/${this.stateService.accessToken}`, {}
    )
      .subscribe(res => {
        this.stateService.setIsAuthenticated(true);
        this.stateService.setHasValidToken(true);
        this.stateService.setAccessToken(res.accessToken);
        this.stateService.setTokenTimeout(moment(res.expires));
        this.stateService.setIdToken(res.idToken);
        if (callback) {
          callback();
        };
      },
      err => {
        this.errorService.navigateToErrorPage(`refreshAccessToken failed: '${err.message}'`);
      });
  }

  public logout() {
    this.stateService.setIsAuthenticated(false);
    const idTokenHint = this.stateService.idToken ? `id_token_hint=${this.stateService.idToken}&` : null;
    // eslint-disable-next-line max-len
    this.navigate(`${environment.tidUrl}/oauth/logout?${idTokenHint}post_logout_redirect_uri=${environment.postLogoutRedirectUri}`);
  }

  public licenseCheck(): boolean{
    if(this.stateService.currentUser.customerRole.toLowerCase() === CustomerRole.distributor.toLowerCase()){
      sessionStorage.setItem(LogoutReasons.logoutReason, LogoutReasons.distributor);
      this.logout();
      return false;
    }
    if(this.stateService.currentUser === null || this.stateService.currentUser.customerId < 0) {
      if(this.storageService.getRedirectUrl().indexOf('contractor') > 0){
        // we don't want to log out unlicensed users coming from Work Center
        this.stateService.setContractorLicense(false);
        return false;
      }
      else{
        sessionStorage.setItem(LogoutReasons.logoutReason, LogoutReasons.userNotFound);
        this.logout();
        return false;
      }
    }
    else {
      return true;
    }
  }

  public navigateToRedirectUrl() {
    this.router.navigateByUrl(this.storageService.getRedirectUrl() === '/' ?
    this.stateService.getDefaultRouterPath() :
    this.storageService.getRedirectUrl(), {replaceUrl: true});
  }

  async getUserRelationsList() {
    let result =  await lastValueFrom(this.profilesService.getUserRelations(this.stateService.currentUser.userId));
    return result.data;
  }
}
