import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AlertsService } from 'apps/analytics-portal/src/app/services/alerts.service';
import { ErrorService } from 'apps/analytics-portal/src/app/services/error.service';
import moment from 'moment';
import { AdViewModel } from '../../models/ad-view-model';
import { AlertMonitor } from '../../models/alert-monitor';
import { AlertTrigger } from '../../models/alert-trigger';
import { AlertViewModel } from '../../models/alert-view-model';
import { StateService } from '../../services/state.service';
import { FiltersService } from 'apps/analytics-portal/src/app/services/filters.service';
import { ResponseModel } from 'apps/analytics-portal/src/app/models/responses/response-model';
import { CatalogViewModel } from '../../models/catalog-view-model';
import { SearchFiltersViewModel } from '../../models/search-filters-view-model';
import { CreateAlertViewModel } from '../../models/create-alert-view-model';
import { EmailSharingModel } from '../../models/email-sharing-model';
import { Subscription } from 'rxjs';
import { LifeCycleService } from '../../services/life-cycle.service';
import { RegionalFiltersViewModel } from '../../models/regional-filters-view-model';
import { CountyViewModel } from '../../models/county-view-model';
import { RegionalViewModel } from '../../models/regional-view-model';

@Component({
  selector: 'app-alert-setup',
  templateUrl: './alert-setup.component.html',
  styleUrls: ['./alert-setup.component.scss']
})
export class AlertSetupComponent implements OnInit, OnDestroy {
  @Input() alert = new AlertViewModel();
  @Output() returnEvent = new EventEmitter<any>();

  public emailRegex = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$';
  public step = 1;
  public userEmail = '';
  public alertForm: FormGroup;
  public textBoxDisabled = true;
  public adList: AdViewModel[];
  public defaultDate;
  public validDateRange = true;
  public validEmailList = true;
  public dateRangeRequired = false;
  public minDate: Date;
  public emailList = [];
  bsInlineValue = new Date();
  bsInlineRangeValue: Date[];
  maxDate = new Date();
  public companySelect = [];
  public regionSelect: RegionalFiltersViewModel[] = [];
  public selectedStates: RegionalFiltersViewModel[] = [];
  public catalogNumberSelect: CatalogViewModel[] = [];
  public catalogNumberBuffer: CatalogViewModel[] = [];
  public hideCatalogs: CatalogViewModel[] = [];
  public emails: string[];
  public initialBuffer = 500;
  public fetchMore = 50;
  public catalogSearching = false;
  public allCompaniesSelected = false;
  public adjustedDate = false;
  public largeSelectAll = false;
  public filtersLoaded = false;
  public subscriptions: Subscription[] = [];
  public noAds = false;
  public regionalSelectAll = true;
  public missingCountyMessage = false;
  public maxCatalogKeys = 30000;
  public maxCatalogKeysMessage = false;


  constructor(
    public stateService: StateService,
    public alertsService: AlertsService,
    public errorService: ErrorService,
    public filtersService: FiltersService,
    public lifeCycleService: LifeCycleService)
  {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsInlineRangeValue = [this.bsInlineValue, this.maxDate];
  }
  /**
   * Determine if alert in progress is an ad type.
   *
   * @readonly
   * @memberof AlertSetupComponent
   */
  public get adSelection(): boolean {
    return this.alertForm.value.monitorSelection === AlertMonitor[AlertMonitor.ADCLICKS] ||
    this.alertForm.value.monitorSelection === AlertMonitor[AlertMonitor.ADIMPRESSIONS];
  }

  /**
   *Determine if alert in progress is a search type.
   *
   * @readonly
   * @memberof AlertSetupComponent
   */
  public get searchSelection(): boolean {
    return this.alertForm.value.monitorSelection === AlertMonitor[AlertMonitor.SEARCH];
  }

  /**
   *Determine if alert in progress is a count type.
   *
   * @readonly
   * @memberof AlertSetupComponent
   */
  public get triggerIsCount(): boolean {
    return this.alertForm.value.triggerSelection === AlertTrigger[AlertTrigger.COUNT];
  }

  /**
   * Number of catalogs selected for summary step.
   *
   * @readonly
   * @memberof AlertSetupComponent
   */
  public get catalogsSelected() {
    if(this.alertForm.value.catalogNumbers.length === this.catalogNumberSelect.length){
      return 'All';
    }
    else{
      return this.alertForm.value.catalogNumbers.length;
    }
  }

  /**
   * List of states selected for summary step.
   *
   * @readonly
   * @memberof AlertSetupComponent
   */
  public get statesSelected(): string {
    let states = '';
    const stateCount = this.selectedStates.length;
    if (this.regionalSelectAll || stateCount === this.regionSelect.length){
      return 'All';
    }
    let i = 0;
    //list first 5
    for(; i < 4; i++){
      if(i < stateCount - 1){
         states += this.selectedStates[i].stateName + ', ';
      }
      else{
        break;
      }
    }
    states += this.selectedStates[i].stateName;
    // Count of states not listed
    if(stateCount > 5){
      states += (', ' + (stateCount - 5).toString() + ' more');
    }
    return states;
  }

  /**
   * Number of counties selected for summary step.
   *
   * @readonly
   * @memberof AlertSetupComponent
   */
  public get countiesSelected() {
    if (this.regionalSelectAll){
      return 'All';
    }
    const selectedCounties = this.alertForm.value.counties;
    let count = selectedCounties.length;
    selectedCounties.forEach(element => {
      if(element.counties !== undefined){
        count += element.counties.length - 1;
      }
    });
    return count;
  }

  /**
   * Formatting for the selected counties in the box.
   *
   * @param item
   * @memberof AlertSetupComponent
   */
  public formatCountyCard(item): string{
    if(item.counties !== undefined){
      return item.stateName + ' - All Counties';
    }
    else{
      return item.stateCode + ' - ' + item.countyName;
    }
  }

  public formatDate(inputDate: string){
    const date = moment(inputDate).utcOffset(0);
    const local = date.local();
    return local.format('MM/DD/yyyy');
  }

  ngOnInit(): void {
    // initial population of email dropdown
    this.userEmail = this.stateService.currentUser.email;
    this.emails = [this.userEmail];
    let emailSharing;
    // FOR EDITING
    if(this.alert.emailSharing !== undefined){
      emailSharing = [];
      if(this.alert.emailSharing !== null) {
        this.alert.emailSharing.forEach((email) => {
          emailSharing.push(email.email);
        });
      }
    }
    //create form
    this.alertForm = new FormGroup({
      monitorSelection: new FormControl(this.alert.monitor !== undefined ? AlertMonitor[this.alert.monitor] : '', [Validators.required]),
      triggerSelection: new FormControl(this.alert.trigger !== undefined ? AlertTrigger[this.alert.trigger] : '', [Validators.required]),
      triggerCount: new FormControl(this.alert.count, [Validators.min(1), Validators.max(9999)]),
      start: new FormControl(this.alert.dataStartDate !== undefined ?
        [new Date(moment(this.alert.dataStartDate).format('YYYY-MM-DD z')),
        new Date(moment(this.alert.dataEndDate).format('YYYY-MM-DD z'))] :
        [new Date(moment().format('YYYY-MM-DD z')),new Date(moment().add(1, 'years').format('YYYY-MM-DD z'))]),
      end: new FormControl(this.alert.dataEndDate),
      selectedAds: new FormControl((this.alert.ads !== undefined && this.alert.ads !== null)
       ? this.alert.ads : []),
      catalogNumbers: new FormControl([]),
      states: new FormControl([]),
      counties: new FormControl([]),
      alertName: new FormControl(this.alert.name, [Validators.required, Validators.max(100)]),
      notificationEndDate: new FormControl(this.alert.notificationEndDate !== undefined ?
        new Date(moment(this.alert.notificationEndDate).format('YYYY-MM-DD z'))
        : new Date(moment().add(1, 'days').format('YYYY-MM-DD z')),
         [Validators.required, this.dateValidator(1)]),
      alertsEmailSharing: new FormControl(emailSharing !== undefined?  emailSharing: [this.userEmail])
    });
    //initial population of existing trigger selection and date range
    if (this.alertForm.get('triggerSelection').value !== AlertTrigger[AlertTrigger.COUNT]){
      this.alertForm.controls['start'].disable();
      //this.alertForm.get('start').patchValue(['', '']);
      this.dateRangeRequired = false;
      this.alertForm.controls['start'].disable();
    }
    else{
      this.dateRangeRequired = true;
      this.alertForm.controls['start'].enable();
    }
    // FOR EDITING
    if (this.alert.trigger !== AlertTrigger.COUNT){
      this.alertForm.controls['triggerCount'].disable();
    }
    if(this.alert.trigger !== undefined){
      //setting date values based on existing trigger
      this.onTriggerFreqChange(AlertTrigger[this.alert.trigger]);
    }

    this.onChanges();
  }

  ngOnDestroy(): void {
    this.catalogNumberSelect = null;
    this.alertForm = null;
    this.lifeCycleService.unsubscribeSubscriptions(this.subscriptions);
  }

  onChanges(){
    this.subscriptions.push(this.alertForm.get('triggerSelection').valueChanges.subscribe(() => {
      if(this.alertForm.get('triggerSelection').value !== AlertTrigger[AlertTrigger.COUNT]){
        this.alertForm.controls['triggerCount'].disable();
        this.alertForm.get('triggerCount').patchValue(null);
      }
      else{
        this.alertForm.controls['triggerCount'].enable();
      }
    }));
  }

  /**
   * Convert list of catalogs from the existing alert to the format used in the dropdown.
   * FOR EDITING
   *
   * @memberof AlertSetupComponent
   */
  commoditiesToCatalogs(): CatalogViewModel[]{
    let selectedCatalogs: CatalogViewModel[] = [];
    selectedCatalogs = this.hideCatalogs.filter(x => this.alert.catalogKeys.indexOf(x.catalogKey) !== -1);

    return selectedCatalogs;
  }

  /**
   * Convert selected regions from existing alert into the format used in the dropdown.
   * FOR EDITING
   *
   * @memberof AlertSetupComponent
   */
  regionalFiltersForForm(){
    let existingCounties = [];
    // setting to empty strings allows "All Selected" card to show without any selection.
    this.alertForm.patchValue({
      states: [''],
      counties: ['']
    });

    this.alert.regionalFilters.forEach(state => {
      // find the state that matches the one given
      let stateMatch = this.regionSelect.find(x => x.stateCode === state.state);
      // add it to selected states
      stateMatch = this.selectState(stateMatch);
      // if all counties are selected
      if(stateMatch.counties.length === state.counties.length){
        existingCounties.push(stateMatch);
      }
      // else filter for selected counties by ids
      else{
        existingCounties = existingCounties.concat(stateMatch.counties.filter(item => state.counties
          .indexOf(item.countyId) !== -1));
      }
    });
    this.alertForm.patchValue({
      states: this.selectedStates,
      counties: existingCounties
    });
  }

  proceedToNextStep(nextstep: number) {
    if(nextstep === 4){
      // summary step
      this.alertForm.disable();
    }
    else{
      this.alertForm.enable();
    }
    // setting trigger and monitor
    if(nextstep === 1){
      //non-count
      if(this.alertForm.get('triggerSelection').value !== AlertTrigger[AlertTrigger.COUNT]){
        this.alertForm.controls['triggerCount'].disable();
        this.alertForm.get('triggerCount').patchValue(null);
      }
      if(!this.dateRangeRequired) {
        this.alertForm.controls['start'].disable();
      }
    }
    //setting filters
    if(nextstep === 2){
      const monitor = this.alertForm.get('monitorSelection').value;
      // Load ad list
      if(monitor === AlertMonitor[AlertMonitor.ADCLICKS] || monitor === AlertMonitor[AlertMonitor.ADIMPRESSIONS]){
        if(this.adList === undefined){
          this.subscriptions.push(this.filtersService.getAds().subscribe((response: ResponseModel<AdViewModel[]>) => {
            this.adList = response? response.data : [];
            this.noAds = response? false : true;
          },
          (err) => this.errorService.navigateToErrorPage()));
        }
      }
      // or load search filters.
      else if(monitor === AlertMonitor[AlertMonitor.SEARCH]){
        if(!this.filtersLoaded){
          this.stateService.showModalProgress('Getting Filters...');
          // Commodities, catalogs
          this.subscriptions.push(this.filtersService.getSearch().subscribe((response: ResponseModel<SearchFiltersViewModel>) => {
            //this.companySelect = response.data.companies;
            this.catalogNumberSelect = (response && response.data)? response.data.catalogs : [];
            // Need to sort so that all values of a commodity are loaded into the buffer
            this.catalogNumberSelect.forEach((a) => {
              if(!a.commodity){
                a.commodity = 'No Commodity';
              }
            });
            this.catalogNumberSelect.sort((a, b) => (a.commodity > b.commodity ? 1 : (a.commodity === b.commodity ? 0 : -1)));
            this.filtersLoaded = true;
            // hideCatalogs is the full list, won't be shown
            this.hideCatalogs = this.catalogNumberSelect;
            this.loadInitialCatalogBuffer();
            this.stateService.hideModalProgress();
            // FOR EDITING
            if(this.alert.catalogKeys && this.alertForm.value.catalogNumbers.length === 0){
              if(this.alert.catalogKeys.length === 0){
                this.selectAllCatalogs();
              }
              else{
                this.alertForm.patchValue({
                  catalogNumbers: this.commoditiesToCatalogs()
                });
              }
            }
            // Regions
            this.subscriptions.push(this.filtersService.getRegions().subscribe(
              (regionResponse: ResponseModel<RegionalFiltersViewModel[]>) => {
              this.regionSelect = (regionResponse && regionResponse.data)? regionResponse.data : [];
              // Sort alphabetical
              this.regionSelect.sort((a, b) => a.stateName > b.stateName ? 1 : -1);
              this.regionSelect.forEach(element => {
                element.counties.sort((a, b) => a.countyName > b.countyName ? 1 : -1);
              });
              // FOR EDITING
              if(this.alert.regionalFilters && this.alertForm.value.states.length === 0){
                if(this.alert.regionalFilters.length === 0){
                  this.selectAllStates();
                }
                else{
                  this.regionalFiltersForForm();
                }
              }
              else{
                this.selectAllStates();
              }
              this.stateService.hideModalProgress();
            }));
        },
        (err) => {
          this.stateService.hideModalProgress();
          this.errorService.navigateToErrorPage();
        }));
        }
      }
    };
    this.step = nextstep;
  }

  /**
   * First set of items to populate catalogs dropdown.
   *
   * @memberof AlertSetupComponent
   */
  loadInitialCatalogBuffer() {
    this.catalogNumberBuffer = this.catalogNumberSelect.slice(0, this.initialBuffer);
    this.loadMore();
  }

  submit(){
    this.stateService.showModalProgress('Submitting Alert...');
    const editing = this.alert.name !== undefined;

    if(editing){
      // format for endpoint
      const alert = this.processOldAlert();
      this.subscriptions.push(
      this.alertsService.editAlert(alert).subscribe(() => {
        this.stateService.hideModalProgress();
        // format to show on close
        alert.trigger = AlertTrigger[alert.trigger.toString()];
        alert.monitor = AlertMonitor[alert.monitor.toString()];
        alert.alertDefinitionId = this.alert.alertDefinitionId;

        this.setDateRange(alert);
        this.returnEvent.emit(alert);
      },
      (err) => this.errorService.navigateToErrorPage())
      );
    }
    else{
      // format for endpoint
      const alert = this.processNewAlert();
      this.subscriptions.push(
      this.alertsService.createAlert(alert).subscribe((response: ResponseModel<string>)  => {
        this.stateService.hideModalProgress();
        //format to show on close
        const newAlert = this.processOldAlert();
        newAlert.alertDefinitionId = response.data;
        newAlert.trigger = AlertTrigger[alert.trigger.toString()];
        newAlert.monitor = AlertMonitor[alert.monitor.toString()];
        this.setDateRange(newAlert);
        this.returnEvent.emit(newAlert);
      },
      (err) => this.errorService.navigateToErrorPage())
      );
    }
  }

  /**
   * Date range for when setup is closed.
   *
   * @param alert
   * @memberof AlertSetupComponent
   */
  setDateRange(alert){
    switch (alert.trigger) {
      case AlertTrigger.DAILY:
        alert.dataStartDate = new Date(moment().add(-1, 'days').format('YYYY-MM-DD z')).toDateString();
        alert.dataEndDate = new Date(moment().add(-1, 'days').format('YYYY-MM-DD z')).toDateString();
        break;
      case AlertTrigger.WEEKLY:
        alert.dataStartDate = new Date(moment().add(-1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD z')).toDateString();
        alert.dataEndDate = new Date(moment().add(-1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD z')).toDateString();
        break;
      case AlertTrigger.MONTHLY:
        alert.dataStartDate = new Date(moment().add(-1, 'months').startOf('month').format('YYYY-MM-DD z')).toDateString();
        alert.dataEndDate = new Date(moment().add(-1, 'months').endOf('month').format('YYYY-MM-DD z')).toDateString();
        break;
      default:
        break;
    }
  }

  cancel(){
    console.log('canceled');
    this.returnEvent.emit(null);
  }

  /**
   * When the frequency is changed, end date needs to change.
   *
   * @param trigFreq
   * @memberof AlertSetupComponent
   */
  onTriggerFreqChange(trigFreq) {
    this.alertForm.get('triggerCount').setValidators([Validators.min(1), Validators.max(9999)]);

    switch(+AlertTrigger[trigFreq]) {
      case AlertTrigger.DAILY:
        // notificationDateValidation parameters:
        // Count boolean, min date for validator, min date for date picker, default date
        this.notificationDateValidation(false, 1, 2);
        break;
      case AlertTrigger.WEEKLY:
        this.notificationDateValidation(false, 7, 8);
        break;
      case AlertTrigger.MONTHLY:
        this.notificationDateValidation(false, 31, 30);
        break;
      case AlertTrigger.COUNT:
        this.alertForm.get('triggerCount').addValidators([Validators.required]);
        this.notificationDateValidation(true, 1, 2);
        break;
      default:
        break;
    }

    this.alertForm.get('triggerCount').updateValueAndValidity();
  }

  /**
   * Change validators and autofill for notification end date based on trigger.
   * Activate or deactivate and fill date range for count type.
   *
   * @param count
   * @param futureDays
   * @param minDate
   * @memberof AlertSetupComponent
   */
  notificationDateValidation(count: boolean, futureDays: number, minDate: number){
    if(this.adjustedDate){
      // setting date in datepicker to existing end date
      this.alertForm.get('notificationEndDate').patchValue(new Date(moment(this.alert.notificationEndDate).format('YYYY-MM-DD z')));
    }
    //to prevent that exisitng date from overriding ones that are set
    this.adjustedDate = false;
    //set form validators (datepicker) with minimum date
    this.alertForm.get('notificationEndDate').setValidators([Validators.required, this.dateValidator(futureDays)]);
    this.alertForm.get('notificationEndDate').updateValueAndValidity();
    this.minDate = new Date(moment().add(minDate, 'days').format('YYYY-MM-DD z'));

    //data start date and end date
    if(count){
      this.alertForm.controls['start'].enable();
      if(this.alert.dataStartDate === undefined || this.alert.trigger !== AlertTrigger.COUNT){
        this.alertForm.get('start').patchValue([new Date(moment()
         .format('YYYY-MM-DD z')),new Date(moment().add(1, 'years').format('YYYY-MM-DD z'))]);
         this.alertForm.get('start').updateValueAndValidity();
      }
      this.dateRangeRequired = true;

    }
    else{
      this.alertForm.controls['start'].disable();
      //this.alertForm.get('start').patchValue(['', '']);
      this.dateRangeRequired = false;
    }

    //if the end date was set before but it is too far back
    if(this.alert.notificationEndDate !== undefined &&
       (new Date(moment(this.alert.notificationEndDate).format('YYYY-MM-DD z'))) < this.minDate){
        this.adjustedDate = true;
    }
    // if this is a new alert or the end date needed to be moved forward
    if(this.alert.notificationEndDate === undefined || this.adjustedDate){
      // most alert types are 6 month out
      let defaultDate = new Date(moment().add(6, 'months').format('YYYY-MM-DD z'));
      //daily is only one month out
      if(futureDays === 1 && !count){
        defaultDate = new Date(moment().add(1, 'months').format('YYYY-MM-DD z'));
      }

      this.alertForm.get('notificationEndDate').patchValue(defaultDate);
    }
  }
  dateValidator(daysInFuture: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const oneDay = 24 * 60 * 60 * 1000;
      const checkDate = new Date().getUTCMilliseconds() + (oneDay * daysInFuture);

      if(!(control && control.value)) {
        return null;
      }

      const chosenDate = new Date(control.value).getUTCMilliseconds();
      return chosenDate < checkDate
        ? {invalidDate: 'Date not allowed' }
        : null;
    };
  }
  convertToUtc(localDate: string) {
    const converted = moment(localDate);
    return converted.utc().toISOString();
  }

  /**
   * Validator for the date range on a count type.
   *
   * @param event
   * @memberof AlertSetupComponent
   */
  validateDateRange(event: Date[]){
    if(this.dateRangeRequired && this.alertForm.get('triggerSelection').value !== AlertTrigger[AlertTrigger.COUNT]
      && event[1] <= new Date(moment().add(1, 'days').format('YYYY-MM-DD'))){
      this.validDateRange = false;
    }
    else{
      this.validDateRange = true;
    }
  }

  /**
   * Disables next button on filters page.
   *
   * @memberof AlertSetupComponent
   */
  disableFilterSelection() {
    const vals = this.alertForm.value;
    if((vals.selectedAds === null) ||
    (vals.catalogNumbers === null)){
      return false;
    }

    if (this.maxCatalogKeysMessage) {
      return true;
    }

    return ((vals.selectedAds.length < 1) &&
            (vals.catalogNumbers.length < 1)) ||
            this.missingCountyMessage;
  }

  /**
   * Check for if any state has no counties selected.
   *
   * @param added
   * @memberof AlertSetupComponent
   */
  invalidRegions(added: boolean){
    if(added){
      this.missingCountyMessage = false;
    }
    this.selectedStates.forEach((e) => {
      if(this.alertForm.value.counties.find(
        c =>  c.stateName === e.stateName) === undefined){
          this.missingCountyMessage = true;
        }
    });
  }


/**
 * check for catalog items > 30,000
 *
 * @param maxed
 * @memberof AlertSetupComponent
 */
  maxedCatalogKeys() {
    if (this.catalogsSelected === 'All') {
      this.maxCatalogKeysMessage = false;
    }
    else if(this.alertForm.value.catalogNumbers.length > this.maxCatalogKeys){
      this.maxCatalogKeysMessage = true;
    }
    else {
      this.maxCatalogKeysMessage = false;
    }
  }


  processNewAlert(): CreateAlertViewModel{
    const ret: CreateAlertViewModel = {
      name: this.alertForm.value.alertName,
      trigger: this.alertForm.value.triggerSelection as AlertTrigger,
      count: this.alertForm.value.triggerCount,
      monitor: this.alertForm.value.monitorSelection as AlertMonitor,
      ads: this.adSelection ? this.alertForm.value.selectedAds : null,
      catalogKeys: this.sortCommodities(),
      regionalFilters: this.sortRegions(),
      dataEndDate: this.convertToUtc(this.dateRangeRequired ? this.alertForm.value.start[1] : null),
      dataStartDate: this.convertToUtc(this.dateRangeRequired ? this.alertForm.value.start[0] : null),
      notificationEndDate: this.convertToUtc(this.alertForm.value.notificationEndDate),
      alertsEmailSharing: this.alertForm.value.alertsEmailSharing
    };

    return ret;
  }

  processOldAlert(): AlertViewModel{
    const emailSharingList = this.processEmails();
    const ret: AlertViewModel = {
      name: this.alertForm.value.alertName,
      alertDefinitionId: this.alert.alertDefinitionId,
      trigger: this.alertForm.value.triggerSelection as AlertTrigger,
      count: this.alertForm.value.triggerCount,
      monitor: this.alertForm.value.monitorSelection as AlertMonitor,
      ads: this.adSelection ? this.alertForm.value.selectedAds : null,
      catalogKeys: this.sortCommodities(),
      regionalFilters: this.sortRegions(),
      dataEndDate: this.convertToUtc(this.dateRangeRequired ? this.alertForm.value.start[1] : null),
      dataStartDate: this.convertToUtc(this.dateRangeRequired ? this.alertForm.value.start[0] : null),
      notificationEndDate: this.convertToUtc(this.alertForm.value.notificationEndDate),
      emailSharing: emailSharingList
    };

    return ret;
  }


  /**
   * Format list of emails to with the additional information required.
   * Guids for existing emails, deletion note for deleted emails, a
   * if a welcome email needs to be sent.
   *
   * @return
   * @memberof AlertSetupComponent
   */
  processEmails(): EmailSharingModel[] {
    const processedEmails: EmailSharingModel[] = [];
    const existingEmails: EmailSharingModel[] = this.alert.emailSharing;
    let enteredEmails: string[] = this.alertForm.value.alertsEmailSharing;

    const deletedEmails: EmailSharingModel[] = [];
    const keptEmails: EmailSharingModel[] = [];

    if(enteredEmails.length !== 0) {
      enteredEmails = enteredEmails.map(addr => addr.toLowerCase());
      enteredEmails = [...new Set(enteredEmails)];
    }
    else{
      enteredEmails = [];
    }

    const newEmails: string[] = enteredEmails;
    if(this.alert.emailSharing !== undefined &&
        this.alert.emailSharing !== null){
      //sort emails into 3 groups
      existingEmails.forEach((email) => {
        if(enteredEmails.indexOf(email.email.toLowerCase()) === -1){
          deletedEmails.push(email);
        }
        else{
          newEmails.splice(newEmails.indexOf(email.email.toLowerCase()), 1);
          keptEmails.push(email);
        }
      });
      //emails that will be deleted from the alert
      deletedEmails.forEach((email) => {
        processedEmails.push({
          email: email.email,
          alertSharingGuid: email.alertSharingGuid,
          sendWelcomeNotification: false,
          isDeleted: true
        });
      });
    }
    //emails that will stay in the alert
    keptEmails.forEach((email) => {
      processedEmails.push({
        email: email.email,
        alertSharingGuid: email.alertSharingGuid,
        sendWelcomeNotification: email.sendWelcomeNotification,
        isDeleted: false
      });
    });

    enteredEmails.forEach((emailAddress) => {
      processedEmails.push({
        email: emailAddress,
        alertSharingGuid: null,
        sendWelcomeNotification: true,
        isDeleted: false
      });
    });

    return processedEmails;
  }

  /**
   * Check the emails to see if they are formatted correctly.
   *
   * @memberof AlertSetupComponent
   */
  validateEmailList(){
    if (this.emails.length <= 0){
      this.validEmailList = true;
      return;
    }
    let invalidEntry = false;

    this.emails.forEach(element => {

      element = element.toLowerCase();
      if((element.match(this.emailRegex) ? element.match(this.emailRegex).length : 0) <= 0){
        this.validEmailList = false;
        invalidEntry = true;
      }
    });
    if (!invalidEntry){
      this.validEmailList = true;
    }
  }

  newEmail(address){
    return address;
  }

  onAdd(event: string){
    this.emails.push(event.toLowerCase());
    this.validateEmailList();
  }

  onRemove(event){
    this.emails = this.emails.filter(e => e !== event.value);
    this.validateEmailList();
  }

  onCheckChange(event) {
    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      this.alertForm.value.selectedAds.push(+event.target.value);
    }
    /* unselected */
    else{
      this.alertForm.value.selectedAds.splice(this.alertForm.value.selectedAds.indexOf(+event.target.value), 1);
    }
  }

  /**
   * Allows search to work on all items rather than just the partial array shown.
   *
   * @param term
   * @memberof AlertSetupComponent
   */
  searchCommodities(term) {
    let searchTerm: string = term.term;
    // To return to the buffered scroll
    if(searchTerm === ''){
      this.catalogSearching = false;
      this.catalogNumberBuffer = this.catalogNumberSelect.slice(0, this.initialBuffer);
      return;
    }
    this.catalogSearching = true;
    // Filter catalog numbers and show ALL matches
    searchTerm = searchTerm.toLowerCase();
    const filteredArray = this.catalogNumberSelect.filter(
      (it) => {
        const commodity = it.commodity ? it.commodity : '';
        return commodity.toLowerCase().indexOf(searchTerm) > -1
      || it.catalogKey.toLowerCase().indexOf(searchTerm) > -1;
    }
    );
    this.catalogNumberBuffer = filteredArray;
  }

  searchFn(term: string, item: CatalogViewModel){
    // This only applies to the filtered array but is needed for ng-select
    term = term.toLowerCase();
    const commodity = item.commodity ? item.commodity : '';
    return commodity.toLowerCase().indexOf(term) > -1
    || item.catalogKey.toLowerCase().indexOf(term) > -1;
  }

  searchStates(term: string, item: RegionalFiltersViewModel){
    term = term.toLowerCase();
    const code = item.stateCode.toLowerCase();
    const name = item.stateName.toLowerCase();
    return code.indexOf(term) > - 1 ||
      name.indexOf(term) > -1;
  }

  searchCounties(term: string, item: CountyViewModel){
    term = term.toLowerCase();
    const code = item.countyId.toString();
    const name = item.countyName.toLowerCase();
    const state = item.stateName.toLowerCase() + ' ' +
    item.stateCode.toLowerCase();
    return (code.indexOf(term) > - 1
    || name.indexOf(term) > -1
    || state.indexOf(term) > -1);
  }

  checkCompanies() {
    if(!this.searchSelection){
      return null;
    }
    else{
      return [];
    }
  }

  /**
   * Format selected items into list of catalogs.
   *
   * @memberof AlertSetupComponent
   */
  sortCommodities() {
    if(!this.searchSelection){
      return null;
    }
    // All selected to empty array
    const selectedCatalogs: CatalogViewModel[] = this.alertForm.value.catalogNumbers;
    if(selectedCatalogs.length === this.catalogNumberSelect.length || this.largeSelectAll){
      return [];
    }
    // Catalog numbers into lists under commodity names
    return selectedCatalogs.map(x => x.catalogKey);
  }

  /**
   * Format selected counties into lists of counties under single state names.
   * When a whole state is selected, its entire object (state with list of counties)
   * is in the counties form field. This means it is a mixed array of state objects
   * and county objects.
   *
   * @memberof AlertSetupComponent
   */
  sortRegions(){
    if(!this.searchSelection){
      return null;
    }
    if(this.regionalSelectAll){
      return [];
    }
    const regionsCreate = [];
    const counties = this.alertForm.value.counties;

    this.selectedStates.forEach(element => {
      // find the selected counties for the state
      const stateCounties = counties.filter(county => county.stateName === element.stateName);
      const currentCounty= {
        state: element.stateCode,
        counties: []
      } as RegionalViewModel;

      stateCounties.forEach(county => {
        //if a whole state is selected, map its county list into just uds
        if(county.counties !== undefined){
          currentCounty.counties = county.counties.map(x => x.countyId);
        }
        // or push ids back one by one if individual counties are selected.
        else{
          currentCounty.counties.push(county.countyId);
        }
      });
      regionsCreate.push(currentCounty);
    });
    return regionsCreate;
  }

  selectAllCatalogs() {
    const largeListThreshhold = 100000;
    // Showing large selections in the dropdown causes lag and crashes.
    if(this.catalogNumberSelect.length > largeListThreshhold){
      this.largeSelectAll = true;
      this.catalogNumberBuffer = [];
      this.catalogNumberSelect = [];
      // setting to empty strings allows "All Selected" card to show without any selection.
      this.alertForm.patchValue({
        catalogNumbers: ['']
      });
    }
    else{
      console.log(this.catalogNumberSelect);
      this.alertForm.patchValue({
          catalogNumbers: this.catalogNumberSelect
        });
    }
  }

  removeCatalog() {
    this.maxedCatalogKeys();

    if(this.largeSelectAll){
      // setting to empty strings allows "All Selected" card to show without any selection.
      this.alertForm.patchValue({
        catalogNumbers: ['']
      });
    }
  }

  clearAllCatalogs() {
    this.largeSelectAll = false;
    this.catalogNumberSelect = this.hideCatalogs;
    this.loadInitialCatalogBuffer();
  }

  /**
   * Make state selections available in the county dropdown.
   *
   * @param selection
   * @memberof AlertSetupComponent
   */
  selectState(selection: RegionalFiltersViewModel) {
    // need to clear away the empty strings used for the "select all" label
    if(this.regionalSelectAll){
      this.alertForm.patchValue(
        {
          states: [selection],
          counties: []
        }
      );
    }
    // Populate state name and code  to each county for searching
    selection.counties.forEach(element => {
      element.stateName = selection.stateName;
      element.stateCode = selection.stateCode;
    });
    // add state to the list
    this.selectedStates = [...this.selectedStates, selection];
    this.alertForm.patchValue({
      counties: [...this.alertForm.value.counties,selection]
    });
   this.regionalSelectAll = false;
   return selection;
  }

  selectAllStates(){
    this.regionalSelectAll = true;
    this.selectedStates = [];
    // setting to empty strings allows "All Selected" card to show without any selection.
    this.alertForm.patchValue(
      {
        states: [''],
        counties: ['']
      }
    );
  }

  selectAllCounties(){
    this.alertForm.patchValue({
      counties: this.selectedStates
    });
    this.missingCountyMessage = false;
  }

  removeState(selection){
    this.selectedStates = this.selectedStates.filter(
      s => s.stateCode !== selection.value.stateCode
    );
    const remainingCounties = this.alertForm.value.counties.filter(
      s => s.stateCode !== selection.value.stateCode
    );
    this.alertForm.patchValue({
      counties: remainingCounties
    });
  }

  onScrollToEnd(){
    this.loadMore();
  }

  /**
   * ng-select virtual scroll function
   *
   * @param end
   * @memberof AlertSetupComponent
   */
  onScroll({ end }) {
    if(this.catalogNumberSelect.length <= this.catalogNumberBuffer.length){
      return;
    }
    if(end + this.fetchMore >= this.catalogNumberBuffer.length) {
      this.loadMore();
    }
  }

  /**
   * Extend selection list for virtual scrolling on catalogs.
   *
   * @memberof AlertSetupComponent
   */
  loadMore(){
    if(this.catalogSearching) {
      return;
    }
    const next = this.catalogNumberSelect[this.catalogNumberBuffer.length];
    const more = this.catalogNumberSelect.slice(this.catalogNumberBuffer.length)
      .filter((x) => x.commodity === next.commodity);
    this.catalogNumberBuffer = this.catalogNumberBuffer.concat(more);
  }

}
