<div id="forecast-component-container">
    <div class="modus-toolbar">
        <h4>Forecast - Beta</h4>
    </div>

    <div class="content" *ngIf="isElligibleForecastCustomer">
        <div id="forecast-selection-content">
            <app-forecast-selection (embedReport)="refreshReport($event)"></app-forecast-selection>
        </div>

        <div id="forecast-report-content">
            <app-forecast-report #forecast></app-forecast-report>
        </div>
    </div>
</div>
