/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../src/environments/environment';
import { CustomerViewModel } from '../models/customer-view-model';
import { ErrorService } from './error.service';
import { CustomerRole } from '../models/customer-role';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  public getAllCustomersByRole(role: string): Observable<CustomerViewModel[]> {
    const customersUrl = environment.userServiceUrl + 'Customer/Roles/' + role + '/AllCustomers';
    return this.http.get<CustomerViewModel[]>(customersUrl)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public getAllChildrenForCustomer(id: number): Observable<CustomerViewModel[]> {
    const customersUrl = environment.userServiceUrl + 'Customer/' + id + '/AllChildren';
    return this.http.get<CustomerViewModel[]>(customersUrl)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public getCustomerRoleFromString(role: string): string {
    let result: string = null;
    for (const key in CustomerRole) {
      if (role.toLowerCase() === CustomerRole[key].toLowerCase()) {
        result = CustomerRole[key];
        break;
      }
    }
    return result;
  }
}
