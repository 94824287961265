/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

export const environment = {
  // General App Settings
  appName: 'Construction Analytics',
  clientId: '89128a2d-18ed-44eb-a39d-b6a64d6b4e96',
  appId: '5efa5b67-64b4-46c7-bbed-d9680ddbe12a',
  production: false,
  debug: false,
  newRelicScriptPath: 'assets/scripts/newRelicDevScript.js',
  gainsightScriptPath: 'assets/scripts/gainsightDevScript.js',
  showAuthDebug: false,
  tosVersion: '2',
  warningBeforeLogoutLengthInMilliseconds: 300000,
  defaultRouterPath: '/reports',

  // Authentication
  // eslint-disable-next-line max-len
  authenticationUrl: 'https://stg-services.trimbleplatform.ninja',
  tidUrl: 'https://stage.id.trimblecloud.com',
  oidcIssuer: 'https://identity-stg.trimble.com',
  productId: '84e44fb8-77d1-4cbd-8899-8311a4d14ddd',
  tidAppName: 'Construction_Analytics_Portal_Dev',
  postLogoutRedirectUri: 'https://dev-analyticsportal.mepdevelopment.net/',
  oidcRedirectUri: 'https://dev-analyticsportal.mepdevelopment.net/session',
  oidcClientId: 'mdVInGpPjji3d_gAQ1EQaANA78Ya',
  oidcScope: 'openid',
  sessionUrl: 'https://api-stg-usw2.trimblepaas.com/mep_authorization_stg-v1.0',
  logoutUrl:
    // eslint-disable-next-line max-len
    'https://identity-stg.trimble.com/i/commonauth?commonAuthLogout=true&type=samlsso&sessionDataKey=E294FEF4A64BF7E14940E2964F78E351&commonAuthCallerPath=https%3A%2F%2Fdev-analyticsportal.mepdevelopment.net%2Flogin',

  // Feature override flags
  showContactsFeature: true,

  // Web Service Urls
  // change to API Gateway
  reportServiceUrl: 'https://cloud.dev.api.trimblecloud.com/constructionanalytics/ReportService/v1/',
  notificationServiceUrl: 'https://cloud.dev.api.trimblecloud.com/constructionanalytics/NotificationService/v1/',
  userServiceUrl: 'https://cloud.dev.api.trimblecloud.com/constructionanalytics/UserService/v1/',
  customerListUrl: 'https://stg-services.trimbleplatform.ninja/licenses/Customer/list',
  profilesBaseUrl: 'https://cloud.stage.api.trimblecloud.com/tcloud-profiles-stage/1.0',

  // Reports
  workspaceId: '68fef881-5c13-4cd3-b9fc-42f651515f9a',

  // Version URLs
  portalVersionUrl: 'https://dev-analyticsportal.mepdevelopment.net/version.json',
  notificationVersionUrl: 'https://dev-analyticsnotificationservice.mepdevelopment.net/Service/Health',
  userVersionUrl: 'https://dev-analyticsuserservice.mepdevelopment.net/Service/Health',
  reportsVersionUrl: 'https://dev-analyticsreportservice.mepdevelopment.net/connected/ping',

  //Profile Service Trns
  acceptedConsentTrn: 'trn:profiles:consents:c4aca45e-38f6-422a-adf0-06b92b384741',
  rejectedConsentTrn: 'trn:profiles:consents:afc35286-9053-4edf-9eb5-678bfd9ec52e',

  // Email Settings
  emailApiUrl: 'https://dev-analyticscoreservice.mepdevelopment.net/Email',
  emailFromAddress: 'noreply@trimble.com',
  emailToAddress: 'analytics-testing-ug@trimble.com',
  supportEmailAddress: 'analytics@trimble.com',

  // 3rd Party Keys
  gainsightTagKey: 'AP-M7YDAYZDRIWI-2-4',
  googleAnalyticsKey: 'G-ZV86L8TLC2',
  logRocketKey: '60xrnx/trimble-analytics',

  // Report IDs for Home Components

  homeReportIds: {
    manufacturer: {
      engagementTrends: '491b292c-dfcc-4450-9195-c28e5b0012d9',
      ads: '427f0abf-9fed-4bab-81af-de208703ca79',
      downloads: '966fa176-7b2b-45a1-b44f-e219ca3c31d0',
      quotes: 'ad868bc2-4465-455a-b89d-30564c64bb04',
      search: 'baa38f06-e148-4293-9d7d-fd2360e4c4e4',
      models: '73a653f9-53bd-4e9c-9f02-53547db09479'
    }
  },

  reportIds: {
    manufacturer: {
      contentHealth: '646356fb-9af8-4006-81f7-1afe5c337a85',
      ads: 'ec6c80b0-2485-4efc-8349-4af41533c32f',
      itemSearch: 'c1f8a74c-6e1f-4ec0-8ee6-91b05b1b7995',
      downloads: '3b77eb2a-dd05-4068-a315-7aa55c693de3',
      estimates: 'dbc3899e-18c7-4222-a11b-6a2996b5fa05',
      quotes: 'c503fad1-c5be-49f3-b277-cf4b2410bbe7',
      purchaseRequests: 'b393bbe7-33a1-482a-81b3-b7b468d5dbce',
      models: '4f8e966a-d743-430d-831d-5e3e9cafcff9'
    },
    contractor: {
      sysque: '865a9159-dab8-43cb-8124-69c27e44a402',
      fabShop: '3d22e6f2-0111-44a6-8fc7-d751927f1004',
      accubid: '3ee03f46-24de-4c67-b140-8ea9e349f72b',
      connect2fab: 'a4af4423-2a04-4d53-89c7-bea669df7df8',
      mepcontent: '443247a1-8107-41e6-91b7-4a9df494c4b7'
    },
    internal: {
      trimbleConstructionContent: '4027e2cf-d057-4274-b90f-1448c1e1453b',
      trimbleComponents: 'f9699c1f-025b-4e43-b654-1fe9a7e26308',
      priceHistory: 'af19fd59-6c4f-43eb-bb5c-8c72de2434ec',
      accountUtility: 'bf149c41-482e-4de6-89aa-315b7040b3d8',
      siteAnalytics: 'c322a132-9682-440d-8c39-bb20254aa902',
      loginData: 'aab5237a-103f-4373-ad44-2c3fe5bbe6e9',
      sysqueActivity: '76d8d394-474d-455b-8c60-e8eac0134da3',
      rawContribution: '6b17438a-d868-4041-b0e2-5f3e8e5f94bb',
      manufacturersGap: '5dbe19d1-76bd-43e7-a266-638c8466b1e9',
      manufacturerSegmentation: 'fd39d9ce-8c89-4c52-8d7d-b3357659cd3f',
      qbr: 'b07f5360-253c-4ce7-a9cf-bde129a0e190',
      sellerMap: 'f2b11782-d022-4c3b-8ffc-50a1f717d06d',
      contractorPrioritization: '3eac81b8-130c-4752-a482-06668ddc9264'
    }
  }
};
