<div class="modal-header" id="confirmDeleteModal">
  <h5 class="modal-title" id="aboutModalLabel">Delete Alert</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <p>Do you want to delete this alert?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" id="closeDeleteButton" (click)="close()">Close</button>
  <button type="button" class="btn btn-primary" id="confirmDeleteButton" (click)="confirm()">Delete Alert</button>
</div>
