import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Subscription, lastValueFrom } from 'rxjs';
import { DataSharingConfig } from '../../config/data-sharing-config';
import { ProfilesService } from '../../services/profiles.service';

@Component({
  selector: 'mep-data-sharing',
  templateUrl: './data-sharing.component.html',
  styleUrls: ['./data-sharing.component.scss'],
})
export class DataSharingComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(DataSharingConfig) public dataSharingConfig: DataSharingConfig,
    public profilesService: ProfilesService
  ) {}

  @Input() imagePath = '';
  @Input() logoPath = '';
  @Input() title = '';
  @Input() text = '';
  @Input() buttonText = '';
  @Input() userUuid = '';
  @Input() closeButton = false;
  @Input() consentRelationUuid: string | null = null;
  @Input() originalAccepted: boolean | null = null;
  @Input() accepted: boolean | null = null;
  @Input() getUserRelations = false;
  @Output() acceptSubmitted = new EventEmitter();
  @Output() rejectSubmitted = new EventEmitter();
  @Output() errorResponse = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  public disableSubmit = true;
  public subscriptions: Subscription[] = [];

  async ngOnInit(): Promise<void> {
    if (this.getUserRelations) {
      try {
        const userRelationsList = (await lastValueFrom(this.profilesService.getUserRelations(this.userUuid))).data;
        this.originalAccepted = this.profilesService.accepted(userRelationsList);
        this.consentRelationUuid = this.profilesService.getConsentRelationUuid(userRelationsList);
      } catch {
        this.errorResponse.emit();
      }
    }
    this.accepted = this.originalAccepted === null ? false : this.originalAccepted;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public submitNewUserRelation() {
    if (this.originalAccepted === this.accepted) {
      this.close();
      return;
    }
    if (this.consentRelationUuid !== null) {
      this.profilesService
        .deleteUserRelation(this.consentRelationUuid)
        .pipe(
          switchMap(() => {
            return this.profilesService.postUserRelation(this.userUuid, this.accepted);
          })
        )
        .subscribe(
          () => {
            this.accepted ? this.acceptSubmitted.emit() : this.rejectSubmitted.emit();
          },
          () => {
            this.errorResponse.emit();
          }
        );
    } else {
      this.profilesService.postUserRelation(this.userUuid, this.accepted).subscribe(
        () => {
          this.accepted ? this.acceptSubmitted.emit() : this.rejectSubmitted.emit();
        },
        () => {
          this.errorResponse.emit();
        }
      );
    }
  }

  public close() {
    this.closeEvent.emit();
  }
}
