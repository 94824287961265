<div class="modus-content-rows">
  <div class="modus-content">
    <div class="cards-container">
      <!--Manufacturer user guide -->
      <div
        class="card border-dark shadow"
        *ngIf="showManfUserGuide"
        id="helpCardManufguide">
        <img
          class="card-img-top"
          src="../../assets/images/CardHelp.svg"
          alt="Card image cap"/>
        <div class="card-body">
          <h3 class="card-title-3" id="helpTitleManufguide">
            User Guide<span *ngIf="isInternal"> - Manufacturer</span>
          </h3>
          <p class="card-text" id="helpParaManufguide">
            Learn about our reports page by page!
          </p>
        </div>
        <div class="card-footer text-right">
          <a
            href="../../assets/pdf/need-help/Construction Analytics User Guide for Manufacturers July 2023.pdf"
            id="helpButtonManufguide"
            rel="noopener noreferrer"
            target="_blank"
            class="card-link btn btn-primary">
            Open User Guide PDF
          </a>
        </div>
      </div>
      <!--Contractor User Guide -->
      <div
        class="card border-dark shadow"
        *ngIf="showContUserGuide"
        id="helpCardContractorguide">
        <img
          class="card-img-top"
          src="../../assets/images/CardHelp.svg"
          alt="Card image cap"/>
        <div class="card-body">
          <h3 class="card-title-3" id="helpTitleContractorguide">
            Construction Analytics Overview<span *ngIf="isInternal">  - Contractor</span>
          </h3>
          <p class="card-text" id="helpParaContractorguide">
            Learn about our reports page by page!
          </p>
        </div>
        <div class="card-footer text-right">
          <a
            href="../../assets/pdf/need-help/Construction-Analytics-Contractors-OVERVIEW.pdf"
            id="helpButtonContractorguide"
            rel="noopener noreferrer"
            target="_blank"
            class="card-link btn btn-primary">
            Open Overview PDF
          </a>
        </div>
      </div>
      <!-- Manufacturer Release Notes -->
      <div
        class="card border-dark shadow"
        *ngIf="showManfReleaseNotes"
        id="helpCardManufrelease">
        <img
          class="card-img-top"
          src="../../assets/images/CardHelp.svg"
          alt="Card image cap"/>
        <div class="card-body">
          <h3 class="card-title-3" id="helpTitleManufrelease">
            Release Notes<span *ngIf="isInternal"> - Manufacturer</span>
          </h3>
          <p class="card-text" id="helpParaManufrelease">
            See our recent and historical release notes. We are releasing new
            insights every 2 weeks.
          </p>
        </div>
        <div class="card-footer text-right">
          <a
            (click)="openModalReleaseNotes('Manufacturer')"
            id="helpButtonManufrelease"
            rel="noopener noreferrer"
            class="card-link btn btn-primary">
            Open Release Notes
            </a>
        </div>
      </div>
      <!-- Contractor Release Notes -->
      <div
        class="card border-dark shadow"
        *ngIf="showContReleaseNotes"
        id="helpCardContractorrelease">
        <img
          class="card-img-top"
          src="../../assets/images/CardHelp.svg"
          alt="Card image cap"/>
        <div class="card-body">
          <h3 class="card-title-3" id="helpTitleContractorrelease">
            Release Notes<span *ngIf="isInternal"> - Contractor</span>
          </h3>
          <p class="card-text" id="helpParaContractorrelease">
            See our recent and historical release notes. We are releasing new
            insights every 2 weeks.
          </p>
        </div>
        <div class="card-footer text-right">
          <a
            (click)="openModalReleaseNotes('Contractor')"
            id="helpButtonContractorrelease"
            rel="noopener noreferrer"
            class="card-link btn btn-primary">
            Open Release Notes
          </a>
        </div>
      </div>
      <!-- Contacts  -->
      <div class="card border-dark shadow"
      id="helpCardContact">
        <img
          class="card-img-top"
          src="../../assets/images/CardHelp.svg"
          alt="Card image cap"/>
        <div class="card-body">
          <h3 class="card-title-3" id="helpTitleContact">
            Contact Information
          </h3>
          <p class="card-text" id="helpParaContact">
            Reach out to our team if you have any questions or new requests.
          </p>
        </div>
        <div class="card-footer text-right">
          <a
            (click)="openModalContactInfo()"
            id="helpButtonContact"
            rel="noopener noreferrer"
            target="_blank"
            class="card-link btn btn-primary">
              Open Contact Information
          </a>
        </div>
      </div>
      <!-- Profile -->
      <div class="card border-dark shadow"
      *ngIf="showProfile"
      id="helpCardProfile">
        <img
          class="card-img-top"
          src="../../assets/images/CardProfile.svg"
          alt="My Profile"/>
        <div class="card-body">
          <h3 class="card-title-3" id="helpTitleProfile">
            My Profile
          </h3>
          <p class="card-text" id="helpParaProfile">
            My user information
          </p>
        </div>
        <div class="card-footer text-right">
          <a class="card-link btn btn-primary"
          (click)="openModalProfile()"
          id="helpButtonProfile">
            Show My Profile
          </a>
        </div>
      </div>
      <!-- About  -->
      <div class="card border-dark shadow"
      *ngIf="showAbout"
      id="helpCardAbout">
        <img
          class="card-img-top"
          src="../../assets/images/CardAbout.svg"
          alt="About"/>
        <div class="card-body">
          <h3 class="card-title-3" id="helpTitleAbout">
            About
          </h3>
          <p class="card-text" id="helpParaAbout">
            Version information about Construction Analytics
          </p>
        </div>
        <div class="card-footer text-right">
          <a class="card-link btn btn-primary"
          (click)="openModalAbout()"
          id="helpButtonAbout">
            Show About Info
          </a>
        </div>
      </div>
      <!-- Terms of Service -->
      <div class="card border-dark shadow"
      *ngIf="showTerms"
      id="helpCardTos">
        <img
          class="card-img-top"
          src="../../assets/images/CardTerms.svg"
          alt="Terms of Service"/>
        <div class="card-body">
          <h3 class="card-title-3" id="helpTitleTos">
            Terms of Service
          </h3>
          <p class="card-text" id="helpParaTos">
            Terms of Service for Construction Analytics
          </p>
        </div>
        <div class="card-footer text-right">
          <a class="card-link btn btn-primary"
          (click)="openModalTerms()"
          id="helpButtonTos">
            Show Terms
          </a>
        </div>
      </div>
      <!-- Licensing -->
      <div
      class="card border-dark shadow"
      id="helpCardLicensing">
        <img
          class="card-img-top"
          src="../../assets/images/CardProfile.svg"
          alt="Terms of Service"/>
        <div class="card-body">
          <h3 class="card-title-3" id="helpTitleLicensing">
            Licensing
          </h3>
          <p class="card-text" id="helpParaLicensing">
            Manage your product licensing
          </p>
        </div>
        <div class="card-footer text-right">
          <a class="card-link btn btn-primary"
          href="https://meplicensing.trimble.com" target="_blank"
          id="helpButtonLicensing">
            Go to Portal
          </a>
        </div>
      </div>
      <!-- Data Sharing -->
      <div
        class="card border-dark shadow"
        *ngIf="showDataSharing"
        id="help-card-datasharing">
        <img
          class="card-img-top"
          src="../../assets/images/CardDataSharing.svg"
          alt="Card image cap"/>
        <div class="card-body">
          <h3 class="card-title-3" id="contractorGuideTitle">
            Data Sharing
          </h3>
          <p class="card-text"
          id="help-para-datasharing">
            Review and modify data sharing preferences.
          </p>
        </div>
        <div class="card-footer text-right">
          <a
            (click)="openModalDataSharing()"
            id="help-button-datasharing"
            rel="noopener noreferrer"
            target="_blank"
            class="card-link btn btn-primary">
            Review
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
