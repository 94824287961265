<div class="modal-header" id="success-message-header">
  <h5 class="modal-title" id="success-message-title">{{header}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div>
  <p id="success-message-body" class="p-4">
    {{message}}
  </p>
</div>
