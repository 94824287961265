import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomerRole } from '../../../../../src/app/models/customer-role';
import { CustomerViewModel } from '../../../../../src/app/models/customer-view-model';
import { UserViewModel } from '../../../../../src/app/models/user-view-model';
import { CustomerService } from '../../../../../src/app/services/customer.service';
import { ErrorService } from '../../../../../src/app/services/error.service';
import { ReportsService } from '../../../../../src/app/services/reports.service';
import { StateService } from '../../../../../src/app/services/state.service';
import { ChooseCompanyViewModel } from '../../../../../src/app/models/choose-company-view-model';
import { CustomerModel } from '../../../models/customer-model';
import { StorageService } from '../../../services/storage.service';
import { IdentityService } from '../../../services/identity.service';

@Component({
  selector: 'app-choose-company',
  templateUrl: './choose-company.component.html',
  styleUrls: ['./choose-company.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChooseCompanyComponent implements OnInit, OnDestroy {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();

  public formGroup: FormGroup;
  public isLoadingRole = false;
  public isLoadingParent = false;
  public isLoadingChild = false;
  public viewModel: ChooseCompanyViewModel;
  public user: UserViewModel;
  public parentCustomer: CustomerViewModel;
  public selectedNameWithCount = '';

  public childCustomers: CustomerViewModel[] = [];
  public roles: string[] = [];
  public contractorList: CustomerViewModel[] = [];
  public internalList: CustomerViewModel[] = [];
  public manufacturerList: CustomerViewModel[] = [];
  public parentCustomers: CustomerViewModel[] = [];
  public subscriptions: Subscription[] = [];

  constructor(public reportsService: ReportsService,
    public customerService: CustomerService,
    public stateService: StateService,
    public storageService: StorageService,
    public errorService: ErrorService,
    public identityService: IdentityService) {
  }

  public get isUserExternal(): boolean {
    return this.user?.customerRole.toLowerCase() !== CustomerRole.internal.toLowerCase();
  }

  public get isLoading(): boolean {
    return this.isLoadingChild || this.isLoadingParent || this.isLoadingRole;
  }

  ngOnInit(): void {
    this.user = this.stateService.currentUser;

    // initial state of form is currently selected customer
    this.viewModel = {
      roleFriendlyName: this.customerService.getCustomerRoleFromString(this.stateService.effectiveRole),
      parentCustomerId: this.stateService.effectiveParentCustomer.id,
      childCustomerId: this.stateService.effectiveChildCustomer.id
    } as ChooseCompanyViewModel;

    this.formGroup = new FormGroup({
      roleFriendlyName: new FormControl({value: this.viewModel.roleFriendlyName, disabled:true}, [Validators.required]),
      parentCustomerId: new FormControl(this.viewModel.parentCustomerId, [Validators.required]),
      childCustomerId: new FormControl(this.viewModel.childCustomerId, [Validators.required]),
    });

    if (!this.isUserExternal) {
      this.initFormInternal();
    }
    else{
      this.initFormExternal();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public initFormInternal(): void {
    this.isLoadingChild = true;
    this.isLoadingParent = true;

    // Role options
    this.roles = [
      CustomerRole.internal,
      CustomerRole.manufacturer,
      CustomerRole.contractor
    ];

    // only one option
    this.internalList = [{
      id: +this.user.customerId,
      name: 'TRIMBLE INTERNAL',
      nameWithCount: 'TRIMBLE INTERNAL (0)',
      role: CustomerRole.internal,
      count: 0
    }] as CustomerViewModel[];

    // get the list for each role
    const contractorsObservable = this.customerService.getAllCustomersByRole(CustomerRole.contractor);
    const manufacturersObservable = this.customerService.getAllCustomersByRole(CustomerRole.manufacturer);
    const joinObservable = forkJoin([contractorsObservable, manufacturersObservable])
      .pipe(catchError(ErrorService.handleHttpErrorResponse));

    this.subscriptions.push(
      joinObservable.subscribe(result => {
        this.initInternalUser(result[0], result[1]);
      },
        async () => {
          this.errorService.navigateToErrorPage('An error was encountered while loading Customers');
        })
    );
  }

/**
 *Populate the form with all customer options
 *
 * @param contractorList List of all contractors.
 * @param manufacturerList List of all manufacturers.
 * @memberof ChooseCompanyComponent
 */
public initInternalUser(contractorList: CustomerViewModel[], manufacturerList: CustomerViewModel[]): void {
  this.formGroup.get('roleFriendlyName')?.enable();

    this.contractorList = contractorList;
    this.manufacturerList = manufacturerList;

    this.sortCustomerList(this.contractorList);
    this.sortCustomerList(this.manufacturerList);

    // Populate nameWithCount
    this.childCustomers.forEach(x => {
      x.nameWithCount = this.getNameWithCount(x);
    });
    this.contractorList.forEach(x => {
      x.nameWithCount = this.getNameWithCount(x);
    });
    this.manufacturerList.forEach(x => {
      x.nameWithCount = this.getNameWithCount(x);
    });

    // "Select" currently set role
    this.handleRoleChange();
    // "Select" currently set parent
    this.parentCustomer = this.getCustomerForId(this.viewModel.parentCustomerId, this.parentCustomers);

    this.parentCustomer.nameWithCount = this.getNameWithCount(this.parentCustomer);
    this.selectedNameWithCount = this.parentCustomer.nameWithCount;
    this.handleParentChange(this.parentCustomer);

    // Stop the spinner
    this.isLoadingParent = false;


  }

public initFormExternal(): void {
    const childObservable = this.customerService.getAllChildrenForCustomer(this.stateService.effectiveParentCustomer.id);

    this.subscriptions.push(
      childObservable.subscribe(result => {
        this.initExternalUser(result);
      },
        async () => {
          this.errorService.navigateToErrorPage('An error was encountered while fetching the report list');
        })
    );
  }

/**
 * Populate form with list of all child cutomers for the user.
 *
 * @param childCustomers
 * @memberof ChooseCompanyComponent
 */
public initExternalUser(childCustomers: CustomerViewModel[]) {
    this.childCustomers = childCustomers;
    this.sortCustomerList(this.childCustomers);

    //set up current parent for validation - count not required atm
    this.parentCustomer = {
      id: this.stateService.currentUser.customerId,
      name: this.stateService.currentUser.customerCName,
      nameWithCount: this.stateService.currentUser.customerCName,
      count: 0,
      role: this.stateService.effectiveParentCustomer.role
    };

    this.childCustomers.forEach(x => {
      x.nameWithCount = x.name;
    });
  }

  public getCustomerForId(customerId: number, customerArray: CustomerViewModel[]): CustomerViewModel {
    const filtered = customerArray.filter(s => s.id === customerId);
    return filtered.length > 0 ? filtered[0] : new CustomerViewModel();
  }

 /**
  *Clear parent/child selections if role is changed
  *and populate parent customers with the correct list.
  *Internal only.
  *
  * @memberof ChooseCompanyComponent
  */
  public handleRoleChange(): void {
    const selectedRole = this.formGroup.value.roleFriendlyName;
    this.selectedNameWithCount = '';

    switch (selectedRole) {
      case CustomerRole.contractor:
        this.parentCustomers = this.contractorList;
        break;

      case CustomerRole.internal:
        this.parentCustomers = this.internalList;
        break;

      case CustomerRole.manufacturer:
        this.parentCustomers = this.manufacturerList;
        break;
    }

    if (this.parentCustomers.length === 1) {
      this.handleParentChange(this.parentCustomers[0]);
      this.selectedNameWithCount = this.parentCustomer.nameWithCount;
    }
    else {
      this.parentCustomer = new CustomerViewModel();
      this.childCustomers = [];
      this.updateValidState();
    }
  }
 /**
  *Clear child selection if Parent customer is changed,
  *and populate child customers with the correct list.
  *Internal only.
  *
  * @param parent
  * @memberof ChooseCompanyComponent
  */
  public handleParentChange(parent: CustomerViewModel): void {
    this.parentCustomer = parent;
    this.isLoadingChild = true;

    this.formGroup.patchValue({
      parentCustomerId: parent.id,
    });

    this.childCustomers = [];
    this.updateValidState();

    this.subscriptions.push(
      this.customerService.getAllChildrenForCustomer(parent.id)
        .subscribe((children: CustomerViewModel[]) => {
          this.setChildCustomers(children);
        })
    );
  }

  public sortCustomerList(list: CustomerViewModel[]): void {
    list.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }

  public getNameWithCount(customerViewModel: CustomerViewModel): string {
    return customerViewModel.count ?
      `${customerViewModel.name} (${customerViewModel.count})` :
      `${customerViewModel.name} (0)`;
  }

  public clearParent() {
    this.handleRoleChange();
  }
/**
 *Set a new list of child customers in the form
 *
 * @param children the new list of child customers
 * @memberof ChooseCompanyComponent
 */
public setChildCustomers(children: CustomerViewModel[]) {
    this.childCustomers = [...children];
    this.sortCustomerList(this.childCustomers);
    this.childCustomers.forEach(x => {
      x.nameWithCount = this.getNameWithCount(x);
    });

    // if the currently selected child is not in the list, patch with the first
    const isChildCompanyIdInChildCustomers =
      this.childCustomers.filter(c => c.id === this.viewModel.childCustomerId).length > 0;
    if (this.childCustomers.length > 0 && !isChildCompanyIdInChildCustomers) {
      this.formGroup.patchValue({
        childCustomerId: this.childCustomers[0].id
      });
    }

    this.isLoadingChild = false;
  }

  public isDirty(): boolean {
    return this.stateService.isDirty(this.viewModel, this.formGroup.value);
  }

  public isValid(): boolean {
    return (this.formGroup.valid && this.parentCustomer.id !== null)
    || this.formGroup.value.roleFriendlyName === CustomerRole.internal;
  }

  public updateValidState(): void {
    for (const key in this.formGroup.controls) {
      if (key) {
        this.formGroup.controls[key].updateValueAndValidity();
      }
    }
  }

/**
 * Save the selection of a new customer in state and storage
 *
 * @param roleFriendlyName
 * @param parentCustomerId
 * @param childCustomerId
 * @memberof ChooseCompanyComponent
 */
public changeCustomer(roleFriendlyName: string, parentCustomerId: number, childCustomerId: number) {
  //convert form selections to CustomerModel
  const newParentCustomer = {
      id: parentCustomerId,
      name: this.parentCustomer.name,
      role: roleFriendlyName as CustomerRole
    } as CustomerModel;
    const childView = this.getCustomerForId(childCustomerId, this.childCustomers);
    const newChildCustomer = {
      id: childCustomerId,
      name: childView.name,
      role: roleFriendlyName as CustomerRole
    } as CustomerModel;
  // Get Customer Id Claims for the selected customer
    this.stateService.setSettingClaims(true);
    this.identityService.addCustomerIdClaims(newParentCustomer, newChildCustomer);
  };

  public close(goBack: boolean): void {
    this.closeEvent.emit();
  }

  public save(): void {
    this.changeCustomer(this.formGroup.value.roleFriendlyName,
      this.formGroup.value.parentCustomerId,
      this.formGroup.value.childCustomerId);
    this.saveEvent.emit();
  }
}
