import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertViewModel } from 'apps/analytics-portal/src/app/models/alert-view-model';
import { environment } from 'apps/analytics-portal/src/environments/environment';
import { CreateAlertViewModel } from '../models/create-alert-view-model';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(public http: HttpClient) { }

  public getAlerts() {
    return this.http.get(`${environment.notificationServiceUrl}Alerts/AlertDefinitions`);
  }

  public createAlert(alert: CreateAlertViewModel) {
    return this.http.post(`${environment.notificationServiceUrl}Alerts/AlertDefinitions`, alert);
  }

  public editAlert(alert: AlertViewModel) {
    const id = alert.alertDefinitionId;
    delete alert.alertDefinitionId;
    return this.http.put(`${environment.notificationServiceUrl}Alerts/AlertDefinitions/${id}`, alert);
  }

  public deleteAlert(alertId: string) {
    return this.http.delete(`${environment.notificationServiceUrl}Alerts/AlertDefinitions/${alertId}`);
  }
}
