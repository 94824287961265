<app-tracking></app-tracking>
<div
  [ngClass]="{
    'modus-layout': isAuthenticated
  }"
>
  <app-authenticated-header
    *ngIf="showAuthenticatedHeader"
    [isSidebarOpen]="isSidebarOpen"
    (sidebarOpenEvent)="setIsSidebarOpen($event)"
  ></app-authenticated-header>
  <app-unauthenticated-header
    *ngIf="showUnauthenticatedHeader"
  ></app-unauthenticated-header>
  <div
    class="modus-body sidebar-closed"
    data-modus-item="body"
    id="modusBody"
    [ngClass]="{
      'sidebar-collapse': !showAuthenticatedHeader,
      'sidebar-open': isSidebarOpen,
      'sidebar-closed': !isSidebarOpen
    }"
  >
    <nav id="mainSidebar" class="nav flex-column modus-sidebar">
      <app-authenticated-nav
        *ngIf="showAuthenticatedNavigation"
        (sidebarOpenEvent)="setIsSidebarOpen($event)"
      ></app-authenticated-nav>
    </nav>
    <router-outlet></router-outlet>
  </div>
  <app-authenticated-footer
    *ngIf="showAuthenticatedFooter"
  ></app-authenticated-footer>
  <app-unauthenticated-footer
    *ngIf="showUnauthenticatedFooter"
  ></app-unauthenticated-footer>
</div>
