/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  public defaultLoadingText = 'Loading...';
  public loadingText = '';

  constructor() {
    this.resetLoadingText();
  }

  ngOnInit(): void {
  }

  public setLoadingText(newLoadingText: string): void {
    this.loadingText = newLoadingText;
  }

  public resetLoadingText(): void {
    this.loadingText = this.defaultLoadingText;
  }
}
