<div class="tos" id="termsOfServiceModal" *ngIf="!busy">
  <div class="modal-header">
    <em class="modus-icon material-icons">privacy_tip</em>
    <h5 class="modal-title" id="termsOfServiceTitle">Terms of Service</h5>
    <button type="button" class="close" id="releaseNotesButton" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>You must agree to the Terms of Service to use this product.</p>
    <div class="tos-content" id="termsOfServiceScrollModal"
      appCustomScroll
      #appScrollElement="appCustomScroll"
      #scroll
    >
      <!-- Begin TOS Text -->
      <p class="title">
        TRIMBLE GENERAL PRODUCT TERMS<br />
        (For Cloud and Software Products)<br />
        Version 2.0
      </p>

      <p>
        These Trimble General Product Terms (this “<strong>Agreement</strong>”)
        are entered into by and between Trimble and the entity or person placing
        an order for or accessing any Product(s) specified in the applicable
        Order (“<strong>Customer</strong>” or “<strong>you</strong>”). Certain
        capitalized terms are defined in Section 17 (Definitions), and others
        are defined contextually in this Agreement.
      </p>

      <p>
        This Agreement consists of the terms and conditions set forth below, any
        applicable Product-Specific Terms, any applicable Support Terms, and the
        Order. If you are accessing or using the Product(s) on behalf of your
        company, you represent that you are authorized to accept this Agreement
        on behalf of your company, and all references to “you” reference your
        company.
      </p>

      <p>
        <span class="underline">Effective Date</span>. The “Effective Date” of
        this Agreement means the earlier of (a) the effective date of the Order,
        or (b) the date Trimble has first made access to a Product available to
        Customer, which could be by physical delivery of media (e.g., CD,
        dongle, etc.) or electronic or access delivery by means of an online
        provisioning, registration, download or other similar process (“Initial
        Product Availability Date”). This Agreement will govern Customer’s
        initial purchase(s) on the Effective Date as well as any renewals
        thereof (unless different terms are specified upon renewal).
      </p>

      <p>
        <span class="underline">Product-Specific Terms</span>. Customer’s use of
        each Product is also subject to any additional product-specific terms
        and conditions set forth in Exhibit A or separately referenced in the
        applicable Order (“Product-Specific Terms”). Any conflict or
        inconsistency will be resolved in the following order of precedence: (1)
        the applicable Order, (2) the Product-Specific Terms, (3) the Support
        Terms, and (3) the body of this Agreement.
      </p>

      <p>
        <strong
          >BY INDICATING YOUR ACCEPTANCE OF THIS AGREEMENT OR INSTALLING,
          ACCESSING OR USING ANY PRODUCT(S), YOU AGREE TO BE BOUND BY THE TERMS
          AND CONDITIONS OF THIS AGREEMENT. EACH PARTY EXPRESSLY AGREES THAT
          THIS AGREEMENT IS LEGALLY BINDING UPON IT. IF YOU HAVE PAID A FEE FOR
          USE OF THE PRODUCT(S) AND DO NOT AGREE TO THESE TERMS AND CONDITIONS,
          YOU MAY RETURN THE PRODUCT(S) FOR A FULL REFUND, PROVIDED YOU (A) HAVE
          NOT INSTALLED, ACCESSED, OR USED THE PRODUCT(S) AND (B) RETURN THE
          PRODUCT(S) WITHIN 14 DAYS OF YOUR INITIAL PURCHASE.</strong
        >
      </p>

      <p><strong>1. Products.</strong></p>

      <p>
        <strong>1.1.</strong>&nbsp;<span class="underline">Product Types</span>.
        The following provisions apply to the applicable Product type (Service
        or Software) in the Order.
      </p>

      <p>
        a)&nbsp;<span class="underline">Service</span>. For Product(s) that are
        a Service, Customer may access and use the Service during the
        Utilization Term only for its internal business purposes in accordance
        with the Documentation, Usage Limitations, any applicable
        Product-Specific Terms and this Agreement. Unless otherwise specified by
        Trimble, any Software provided with a Service is subject to the terms
        applicable to Software under this Agreement.
      </p>

      <p>
        b)&nbsp;<span class="underline">Software</span>. For Product(s) that are
        Software, subject to the terms of this Agreement, Trimble hereby grants
        Customer a non-transferable, non-sublicensable, non-exclusive license
        solely during the Utilization Term to install, copy and use the Software
        on systems under Customer’s control only for its internal business
        purposes in accordance with the Documentation, Usage Limitations, any
        applicable Product-Specific Terms and this Agreement. Software is
        licensed not sold.
      </p>

      <p>
        <strong>1.2.</strong>&nbsp;
        <span class="underline">Authorized Users; Administrators</span>.
      </p>

      <p>
        a)&nbsp;Only Authorized Users may access or use any (i) Service or (ii)
        Software licensed on an Authorized User basis. User IDs are granted to
        individual, named persons, and each Authorized User will keep its login
        credentials confidential and not share them with anyone else. Customer
        is responsible for its Authorized Users’ compliance with this Agreement
        and actions taken through their accounts. In the event an Authorized
        User is no longer a Customer employee or contractor, Customer will
        promptly de-activate such Authorized User’s access. Only if expressly
        permitted under the applicable Order, Customer may transfer Authorized
        User status from one individual to another at any time, provided that
        use of the Product(s) by its Authorized Users in the aggregate remains
        within the Usage Limitations. Customer will promptly notify Trimble if
        it becomes aware of any compromise of its Authorized User login
        credentials.
      </p>

      <p>
        b)&nbsp;If the Product permits administrator access, as described in the
        Documentation, Customer may designate one or more Authorized Users to be
        administrators (each an “<strong>Administrator</strong>”) with control
        over Customer’s Service account, including management of Authorized
        Users and Customer Data, as described in the Documentation. Customer is
        fully responsible for its choice of Administrators and any actions they
        take with respect to the Service. Trimble’s responsibilities do not
        extend to the internal management or administration of the Service for
        Customer.
      </p>

      <p>
        <strong>1.3.</strong>&nbsp;
        <span class="underline">API Access and Customer Applications</span>.
      </p>

      <p>
        a)&nbsp;API. The Product(s) may include one or more application program
        interfaces (“API(s)”) that allow Customer to develop applications, code
        or services that communicate with the Product (collectively, “Customer
        Applications”). Such APIs, if any, may be available upon request.
        Customer may use an API only if such use is authorized in the
        Documentation or otherwise in writing by Trimble. Use of APIs may be
        subject to additional terms and conditions. Trimble may modify APIs from
        time to time, and Trimble is not responsible for the compatibility of
        any such modifications with Customer Applications.
      </p>

      <p>
        b)&nbsp;Use of Customer Applications. If use of an API is authorized,
        subject to the terms of this Agreement and in compliance with the
        applicable Documentation, Customer may develop Customer Applications for
        use solely by Customer’s Authorized Users. Customer will not develop
        Customer Applications for the benefit of, or distribute Customer
        Applications to, any third party. Customer assumes all risk and
        liability regarding the development or use of any Customer Applications.
        Other customers or Trimble itself may independently develop applications
        similar to Customer Applications.
      </p>

      <p>
        <strong>1.4.</strong>&nbsp;<span class="underline">Restrictions</span>.
        Customer will not (and will not permit, encourage, or assist anyone else
        to) do any of the following: (a) provide access to, distribute, sell or
        sublicense the Product(s) to a third party, (b) use the Product(s) on
        behalf of, or to provide any product or service to, third parties, (c)
        use the Product(s) to develop a similar or competing product or service,
        (d) reverse engineer, decompile, disassemble or seek to access the
        source code or non-public APIs to any element of the Product(s), except
        to the extent expressly permitted by Law (and then only after providing
        prior written notice to Trimble), (e) modify or create derivative works
        of the Product(s) or copy any element of the Product(s) (other than in
        connection with making copies of Software authorized under this
        Agreement), (f) remove or obscure any proprietary notices in the
        Product(s), (g) publish benchmarks or performance information about the
        Product(s), except to the extent expressly permitted by Law, (h)
        interfere with the Product(s)’ operation or its use by others,
        circumvent its access restrictions or, without the prior written
        permission of Trimble, conduct any security or vulnerability test of the
        Product(s), (i) transmit any viruses or other harmful materials to the
        Product(s), (j) submit to the Product(s) any information that is
        inappropriate, defamatory, obscene, salacious or unlawful, or use the
        Product(s) to defame, harass, stalk, threaten or otherwise violate the
        rights of others or (k) use the Product(s) to advertise, offer to sell
        or buy goods, or otherwise for business promotional purposes, or (i) for
        Software, unless expressly permitted in the Order, Product-Specific
        Terms or the Documentation, use or host any Software in a virtual server
        environment.
      </p>

      <p>
        <strong>1.5.</strong>&nbsp;<span class="underline"
          >Trials and Betas</span
        >. If Customer receives access to the Product(s) or any features thereof
        on a free or trial basis or as an alpha, beta or early access offering
        (“Trials and Betas”), use is permitted only for Customer’s internal
        evaluation to determine whether to purchase a full license or
        subscription to the Product(s) during the period designated by Trimble
        (or if not designated, 30 days). If Customer purchases a full license or
        subscription to the Product(s), this Agreement will apply to Customer’s
        use unless otherwise specified in the applicable Order. Trials and Betas
        are optional and Trimble may cease offering Trials and Betas at any time
        for any reason. Trials and Betas may be inoperable, incomplete or
        include features that Trimble may never release, and their features and
        performance information are Trimble’s Confidential Information. If the
        Product(s) includes a mechanism that limits access to Trials and Betas,
        Customer will not attempt to circumvent any such mechanism or
        restriction. Notwithstanding anything else in this Agreement: (a)
        Trimble has no obligation to retain Customer Data used with Trials and
        Betas, (b) Trimble provides the Trial and Betas “AS-IS”with no warranty,
        indemnity, service levels or support and (c) Trimble’s liability for
        Trials and Betas will not exceed US$50.
      </p>

      <p>
        <strong>1.6.</strong>&nbsp;
        <span class="underline">Educational Versions</span>. Notwithstanding the
        foregoing, for any version of the Product(s) designated as
        “educational,” or a similar term, Customer may use the Product(s) solely
        for educational purposes (i.e., by an instructor or a student at an
        educational institution and while engaged in educational work). Such
        educational versions may not be used (a) by any other person, (b) by any
        educational institution for any non-educational purposes, or (c) for any
        for-profit purpose, including professional work or training offered for
        a fee, or by commercial entities.
      </p>

      <p>
        <strong>1.7.</strong>&nbsp;
        <span class="underline">Internet Connection</span>. The Product(s) may
        require an active Internet connection or other means of electronic
        communications to operate, which are not the responsibility of Trimble.
      </p>

      <p>
        <strong>1.8.</strong>&nbsp;
        <span class="underline">Software Delivery and Deployment</span>. Any
        Product(s) that are Software, and any applicable Documentation and any
        applicable License Keys, will be delivered by electronic means unless
        otherwise specified on the applicable Order. Delivery is deemed to occur
        on the date on which the Software and License Keys, if any, are first
        made available to Customer. The Software may gather and transmit to
        Trimble license compliance and activation data. Customer will not
        disable, modify or interfere with the operation of any such
        functionality of the Software. Trimble may use the foregoing information
        to validate the authenticity of Customer’s license to the Software, to
        register Customer’s Software, for license metering and to protect
        Trimble against unlicensed or illegal use of the Software.
      </p>

      <p><strong>2. Data Rights.</strong></p>

      <p>
        <strong>2.1.</strong>&nbsp;
        <span class="underline">Data Usage and Ownership</span>.
      </p>

      <p>
        a)&nbsp;Customer hereby grants to Trimble and its affiliates the
        non-exclusive, worldwide, irrevocable, royalty-free right: (i) to use
        Customer Data during the Utilization Term to provide the Products and
        Professional Services to Customer; (ii) to use and disclose Customer
        Data as otherwise permitted pursuant to this Agreement or any written
        consent and/or instructions of the Customer; and, (iii) on a perpetual
        basis: (A) to create, use and disclose Anonymized Data for any purpose;
        and (B) subject to Trimble’s confidentiality obligations in Section 13
        (Confidentiality) and all applicable Data Protection Legislation, to use
        Customer Data to develop, maintain and improve the Products(s) and any
        other products, software, and services of Trimble and/or its affiliates.
      </p>

      <p>
        b)&nbsp;Except for Trimble’s use rights set forth in this Agreement, as
        between the parties, Customer retains all intellectual property and
        other rights in Customer Data provided to Trimble. Trimble owns all
        right, title and interest in Anonymized Data (including, without
        limitation, any and all intellectual property rights).
      </p>

      <p>
        c)&nbsp;Customer will not have access to Customer Data after termination
        or expiration of the Utilization Term, unless otherwise indicated in the
        application Documentation, Order, Product-Specific Terms, or the parties
        agree otherwise in writing.
      </p>

      <p>
        d)&nbsp;In the event of any conflict between the terms of Section 13
        (Confidentiality) and this Section 2.1 (Data Usage and Ownership), the
        terms of this Section 2.1 (Data Usage and Ownership) will control.
      </p>

      <p>
        <strong>2.2</strong>&nbsp;
        <span class="underline">Personal Information; Data Protection</span>.
        The following section applies if Customer is an entity.
      </p>

      <p>
        a)&nbsp;All applicable laws, rules, and regulations relating to the
        protection of privacy and data protection are referred to as “<strong
          >Data Protection Legislation</strong
        >”. “<strong>Personal Information</strong>” is defined as in the
        applicable Data Protection Legislation, or if no definition is provided,
        any personally identifiable information which is either (i) provided by
        Customer or on its behalf, or (ii) automatically collected through the
        Service on Customer’s behalf. “<strong>Applicable</strong>”, in this
        context, means the Data Protection Legislation applicable to Customer at
        Customer’s principal place of business or to Trimble at Trimble’s
        principal place of business, and such laws that Customer notifies
        Trimble in writing of that apply to the parties.
      </p>

      <p>
        b)&nbsp;Each party will comply with all applicable requirements of the
        Data Protection Legislation that applies to it. This Section 2.2(b) is
        in addition to, and does not relieve, remove or replace, a party's
        obligations or rights under the applicable Data Protection Legislation.
      </p>

      <p>
        c)&nbsp;The parties acknowledge that: (i) if Trimble processes any
        Personal Information hereunder, it is on the Customer's behalf when
        performing its obligations under this Agreement and (ii) the Personal
        Information may be transferred or stored, and/or accessed from outside
        of the country where the Customer’s principal place of business is
        located in order to provide the Service and Trimble's other obligations
        under this Agreement.
      </p>

      <p>
        d)&nbsp;Without prejudice to the generality of Section 2.2(b), Customer
        will ensure that it has all necessary appropriate consents and notices
        in place (i) to enable lawful transfer of the personal information to
        Trimble for the duration and purposes of the Agreement and (ii) to
        enable Trimble to lawfully use, process and transfer the Personal
        Information in accordance with this Agreement, including on the
        Customer's behalf.
      </p>

      <p>
        e)&nbsp;If the processing of Personal Information by Trimble is subject
        to the General Data Protection Regulation ((EU) 2016/679) or the Data
        Protection Act 2018 of the United Kingdom, then, in addition, at the
        request of Customer, then the parties will execute an applicable data
        processing addendum.
      </p>

      <p>
        f)&nbsp;If the processing of Personal Information by Trimble is subject
        to the California Consumer Privacy Act of 2018 (Title 1.81.5, §1798.100
        et. seq.) (“CCPA”), then in addition, in connection with a verified
        request by a data subject pursuant to an exercise of rights under CCPA
        related to Personal Information, Trimble is Customer’s service provider,
        that Customer (and not Trimble) will respond to such request, and that
        if necessary in connection with such verified request Customer will
        utilize the tools and information provided or made generally available
        by Trimble, such as Trimble’s online portals or APIs and standard
        documentation regarding Trimble’s products, software and services. To
        the extent such tools do not enable Customer to respond to a verified
        request, upon Customer’s request Trimble will provide reasonable
        assistance with respect to Personal information in Trimble’s systems
        that is required for Customer’s response to such request. A consumer
        request to delete Personal Information will not require Trimble to
        delete Personal Information required to provide Customer with the
        Product(s), which includes any of Trimble’s service provider(s) acting
        on Trimble’s behalf to provide the Product(s); provided, however, that
        such service provider(s) do not have a separate right to sell or use
        Customer’s Personal Information other than as required for Trimble’s
        business purposes.
      </p>

      <p><strong>3. Customer Obligations.</strong></p>

      <p>
        <strong>3.1.</strong>&nbsp;
        <span class="underline">Compliance with Laws</span>. Customer is
        responsible for complying with all applicable Laws in its use of the
        Product(s) and any results derived from the Product(s)
      </p>

      <p>
        <strong>3.2.</strong>&nbsp;
        <span class="underline">No High Risk Activities</span>. Customer will
        not use the Product(s) for High Risk Activities. Customer acknowledges
        that the Product(s) are not intended to meet any legal obligations for
        High Risk Activities.
      </p>

      <p>
        <strong>3.3.</strong>&nbsp;<span class="underline"
          >No Prohibited Data</span
        >. Customer will not use the Product(s) with Prohibited Data. Customer
        acknowledges that the Product(s) are not intended to meet any legal
        obligations for these uses, including HIPAA requirements, that Trimble
        is not a Business Associate as defined under HIPAA.
      </p>

      <p>
        <strong>3.4.</strong>&nbsp;<span class="underline">Customer Data</span>.
        Customer is responsible for its Customer Data, including its content and
        accuracy and compliance with Laws. Customer represents and warrants that
        it has made all disclosures and has all rights, consents and permissions
        necessary to use its Customer Data with the Product(s) and grant Trimble
        the rights in Section 2.1 (Data Use and Ownership), all without
        violating or infringing Laws, third-party rights (including intellectual
        property, publicity or privacy rights) or any terms or privacy policies
        that apply to its Customer Data.
      </p>

      <p>
        <strong>4. Suspension of Access to Product(s).</strong> Trimble may
        suspend Customer’s access to the Product(s) and/or Support, without
        liability, and in whole or in part, if Customer breaches Section 1.4
        (Restrictions) or Section 3 (Customer Obligations), if Customer’s
        account is 30 days or more overdue or if Customer’s actions risk harm to
        other customers or the security, availability or integrity of the
        Product(s). Where practicable, Trimble will use reasonable efforts to
        provide Customer with prior notice of the suspension. Once Customer
        resolves the issue requiring suspension, Trimble will promptly restore
        Customer’s access to the Product(s) in accordance with this Agreement.
      </p>

      <p>
        <strong>5. Certain Product Features.</strong> The following provisions
        apply to the extent applicable to the Product(s).
      </p>

      <p>
        <strong>5.1.</strong>&nbsp;<span class="underline">Devices</span>. The
        Product(s) may be compatible with or require use of a device (“Device”).
        Compatible Devices are specified in the applicable Documentation.
        Trimble makes no warranties regarding the operation of any Device or
        continued compatibility of the Product(s) with any such Device. You are
        solely responsible for the configuration and operation of your Device.
        The results obtained through a Product may be affected by, and Trimble
        will have no liability for, the compatibility, placement, configuration
        or operation of your Device, weather or other environmental conditions,
        color or composition of materials being scanned, or other factors
        outside of Trimble’s control.
      </p>

      <p>
        <strong>5.2.</strong>&nbsp;
        <span class="underline">Use with other Trimble Products</span>. The
        Product(s) may allow Customer to connect with other Trimble products or
        services. Use of such other products or services that are not part of
        the Product(s) may require payment of a separate fee and are governed by
        those products or services’ respective terms of service, end user
        license agreement, or other agreement, and not by this Agreement.
      </p>

      <p>
        <strong>5.3.</strong>&nbsp;<span class="underline">Scripts</span>. The
        Product(s) may allow Customer to input and/or develop custom scripts,
        macros and commands (collectively, “Scripts”) that control the operation
        of the Product(s). Scripts may be available for download or purchase
        from Trimble or third parties, or created by Customer. Unless otherwise
        specified by Trimble in writing, Scripts are not part of the Product.
        Customer’s development and/or use of any Scripts are solely at its own
        risk. To the extent any Scripts are provided by a third party, such
        Scripts will be deemed to be Third-Party Materials, and may be subject
        to Third-Party Terms.
      </p>

      <p>
        <strong>5.4.</strong>&nbsp;
        <span class="underline">Third-Party Materials</span>.
      </p>

      <p>
        a)&nbsp;<span class="underline">Generally</span>. The Product(s) may
        provide Customer with access to Third-Party Materials. Third-Party
        Materials are not deemed to be part of the Product(s). To the extent
        specified by Trimble (including in any Product-Specific Terms or
        Documentation), use of the Third-Party Materials may be subject to
        additional terms or restrictions (“Third-Party Terms”). Customer is
        solely responsible for its compliance with any Third-Party Terms, and
        failure to comply with such terms may result in termination of
        Customer’s right to access any features of the Product(s) that utilize
        such Third-Party Materials. If no Third-Party Terms are specified,
        Customer may use Third-Party Materials solely in support of Customer’s
        authorized use of the Product(s) in accordance with this Agreement.
      </p>

      <p>
        b)&nbsp;<span class="underline">Open Source</span>. The Software may
        incorporate third-party open source software (“Open Source”), as listed
        in the Documentation or Product-Specific Terms, or otherwise made
        available by Trimble. To the extent the terms of the Open Source license
        prohibit the terms of this Agreement from applying to the Open Source,
        the terms of the Open Source license will apply to the Open Source on a
        stand-alone basis instead of this Agreement.
      </p>

      <p>
        c)&nbsp;<span class="underline">Content Subscriptions</span>. This
        Section 5.4(c))Content Subscriptions) applies if the Product makes
        available Third-Party Materials as a data or content subscription
        (“Subscription Content”). If you have a separate agreement with Trimble
        in place regarding the use of Subscription Content (the "Subscription
        Content Agreement"), then such Subscription Content Agreement governs
        the use of Subscription Content accessed through the Product, but not
        the use of the Product itself, which will be governed by this Agreement.
        If no Subscription Content Agreement is in place, then, unless otherwise
        authorized by Trimble in writing, such Subscription Content may only be
        used solely for Customer’s internal purposes during the applicable
        Utilization Term and only when accessed pursuant to a manual end user
        request. Customer will not: (i) access, extract or download any
        Subscription Content, or portions thereof, in batch or mass by any
        means, (ii) sell, offer to sell, rent, sublicense or transfer any copies
        of the Subscription Content, or portions thereof, to a third party or
        allow a third party to use the Subscription Content; (iii) use the
        Subscription Content to develop services or products for sale or include
        any portion of the Subscription Content in any product or service; (iv)
        use any portion of the Subscription Content to create a competitive
        service, product or technology; (v) recreate the Subscription Content or
        create otherwise a separate database or other repository of Subscription
        Content, (vi) use Subscription Content to train, augment, or correct
        another database or information repository, or (vii) make any portion of
        the Subscription Content available to the public in any manner. Upon
        notice from Trimble and/or any termination or expiration of the
        Utilization Term, Customer will immediately cease using and
        delete/destroy all electronic and physical copies of Subscription
        Content.
      </p>

      <p>
        <strong>5.5.</strong>&nbsp;
        <span class="underline">Third-Party Platforms</span>. Customer may
        choose to use the Product(s) with Third-Party Platforms. Third-Party
        Platforms are not deemed to be part of the Product(s). Use of
        Third-Party Platforms is subject to Customer’s agreement with the
        relevant provider and not this Agreement, and may enable data exchange
        between the Product(s) and Third-Party Platform. Trimble does not
        control and has no liability for Third-Party Platforms, including their
        security, functionality, operation, availability or interoperability or
        how the Third-Party Platforms or their providers use Customer Data. If
        Customer enables a Third-Party Platform with a Product(s), Trimble may
        access and exchange Customer Data with the Third-Party Platform on
        Customer’s behalf.
      </p>

      <p>
        <strong>5.6.</strong>&nbsp;
        <span class="underline">Third-Party Application Stores</span>.
      </p>

      <p>
        a)&nbsp;<span class="underline">Purchase from Application Store</span>.
        If Customer obtained Software through a third-party application store,
        marketplace or other site or service (each, an “Application Store”),
        such Application Store is considered a “Reseller” under this Agreement,
        and Customer’s use of the Software is subject to Section 7.3 (Purchase
        from Reseller) of this Agreement. Except as expressly set forth in
        Sections 7.3 (Purchase from Reseller) and 5.6(c) (Apple-Specific Terms),
        all fees are non-refundable once paid. Customer’s download of the
        Software may be subject to other terms as specified by the operator of
        the Application Store from which Customer downloaded the Software.
      </p>

      <p>
        b)&nbsp;<span class="underline">In App Purchases</span>. The Software
        may offer Customer the opportunity to purchase additional functions
        and/or features from within the application (an “In App Purchase”). All
        billing and transaction processes are handled by the provider of the
        Application Store (the “App Store Provider”) from whose platform
        Customer downloaded the Software and are governed by the App Store
        Provider’s terms and conditions. If Customer has any payment related
        issues with In-App Purchases, then Customer must contact the App Store
        Provider directly.
      </p>

      <p>
        c)&nbsp;<span class="underline">Apple-Specific Terms</span>. If Customer
        downloaded the Software from Apple Inc.’s (“Apple’s”) Application Store,
        the following terms are part of this Agreement:
      </p>

      <p>
        i.&nbsp;This Agreement is between Customer and Trimble, and not with
        Apple. However, as required by Apple, Apple and its subsidiaries will be
        third party beneficiaries of this Agreement and will have the right (and
        will be deemed to have accepted the right) to enforce this Agreement
        against Customer as a third-party beneficiary.
      </p>

      <p>
        ii.&nbsp;As set forth in Section 8 (Warranties and Disclaimers) of this
        Agreement, Trimble offers a refund in certain circumstances following a
        breach of Trimble’s limited warranty for the Software. If Customer is
        entitled to such a refund, Customer may notify Apple, and Apple will
        refund the purchase price (if any) for the Software to Customer. To the
        maximum extent permitted by Law, Apple will have no other warranty
        obligation with respect to the Software, and, as between Apple and
        Trimble, any other claims, losses, liabilities, damages, costs or
        expenses attributable to a failure to conform to a warranty will be
        Trimble’s responsibility. Apple has no obligation whatsoever to furnish
        any maintenance or support services with respect to the Software.
      </p>

      <p>
        iii.&nbsp;As between Trimble and Apple, Trimble is solely responsible
        for the Software and for addressing any claims Customer or any third
        parties have about the Software or your possession or use of the
        Software, including without limitation (A) product liability claims, (B)
        any claim that the Software fails to conform to any applicable legal or
        regulatory requirement and (C) claims arising under consumer protection
        or similar legislation. In the event of any third-party claim that the
        Software or your possession or use of the Software infringes that third
        party’s intellectual property rights, Apple will not be responsible for
        the investigation, defense, settlement or discharge of such claim.
      </p>

      <p><strong>6. Support and Professional Services.</strong></p>

      <p>
        <strong>6.1.</strong>&nbsp;<span class="underline">Service Support</span
        >. During the Utilization Term, Trimble will provide the support for the
        Service (“Service Support”) and service level commitments specified on
        the applicable Order and/or the Product-Specific Terms, if any (“Service
        Support Terms”).
      </p>

      <p>
        <strong>6.2.</strong>&nbsp;<span class="underline"
          >Software Support</span
        >. Trimble will provide the support and maintenance services for the
        Software (“Software Support”) specified on the applicable Order and/or
        the Product-Specific Terms, if any, during such period as Customer has
        paid the applicable fee. Unless (a) Trimble and Customer have entered
        into a separate written agreement for Support or (b) different terms and
        conditions are set forth in the Order or Product-Specific Terms, Support
        will be provided pursuant to Trimble’s then-current Software Support and
        Maintenance Terms, available at
        <a
          href="https://www.trimble.com/support/SoftwareSSMTerms"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.trimble.com/support/SoftwareSSMTerms</a
        >
        or a successor URL (any such terms and conditions from (a) or (b),
        “<strong>Software Support Terms</strong>”).
      </p>

      <p>
        <strong>6.3.</strong>&nbsp;
        <span class="underline">Professional Services</span>. Professional
        Services are not covered by this Agreement. If the applicable Order
        indicates that Trimble will provide any Professional Services to
        Customer, then Trimble’s provision of and Customer’s receipt of such
        Professional Services will be governed by Trimble’s then-current
        standard Professional Services terms, available at
        <a
          href="http://www.trimble.com/legal/tandcforservices"
          target="_blank"
          rel="noopener noreferrer"
          >http://www.trimble.com/legal/tandcforservices</a
        >
        or a successor URL, unless otherwise mutually agreed by the parties in
        writing.
      </p>

      <p><strong>7. Commercial Terms.</strong></p>

      <p>
        <strong>7.1.</strong>&nbsp;
        <span class="underline"
          >Utilization Term; Utilization Commencement Date; Renewal Opt Out
          Deadline.</span
        >
        Unless otherwise set forth in the Product-Specific Terms or Order,
      </p>

      <p>
        (1)&nbsp;the initial Utilization Term for each Product is 12 months from
        the Utilization Commencement Date, and each Utilization Term will renew
        for successive 12-month periods unless either party gives the other
        party notice of non-renewal by the Renewal Opt Out Deadline; and
      </p>

      <p>
        (2)&nbsp;the “<strong>Utilization Commencement Date</strong>” for each
        Product means:
      </p>

      <p class="indent-1">
        (a)&nbsp;if Customer ordered such Product directly from Trimble, the
        later of (i)&nbsp;applicable Initial Product Availability Date, or (ii)
        the start date of the term indicated in the Order; or
      </p>

      <p class="indent-1">
        (b)&nbsp;if Customer ordered such Product through a Reseller, the
        Initial Product Availability Date.
      </p>

      <p>
        (3)&nbsp;the “<strong>Renewal Opt Out Deadline</strong>” is the date
        that is 30 days before the expiration of the then-current Utilization
        Term.
      </p>

      <p>
        <strong>7.2.</strong>&nbsp;<span class="underline">Fees and Taxes</span
        >. Fees are as described in each Order. Fees are invoiced on the
        schedule in the Order and reimbursable expenses are invoiced in arrears.
        Unless the Order provides otherwise, Custom will pay all fees and
        expenses within 30 days of the invoice date. Fees for renewal
        Utilization Terms are at Trimble’s then-current rates, regardless of any
        discounted pricing in a prior Order. Any amount due under this Agreement
        that remains unpaid after its due date will bear interest at the lower
        of 1.5% per month or the maximum rate permitted by Law, calculated from
        the data such amount was due until the date that payment is received.
        Customer will pay all costs and expenses of collection (including
        attorneys’ fees) incurred by Trimble collecting any amounts past due
        under this Agreement. Subject to any mandatory Laws to the contrary, all
        fees and expenses are non-refundable except as set out in Section 8.2
        (Warranty Remedy). Customer will pay any sales, use, GST, value-added,
        withholding or similar taxes or levies that apply to its Orders, whether
        domestic or foreign (“Taxes”), other than Trimble’s income tax. Fees and
        expenses are exclusive of Taxes. Customer will pay any foreign exchange
        transaction fees and any foreign exchange profits or losses incurred on
        such transactions.
      </p>

      <p>
        <strong>7.3.</strong>&nbsp;
        <span class="underline">Purchase from Reseller</span>. If Customer
        obtained the Product(s) through an authorized dealer, distributor or
        reseller of Trimble (“Reseller”), the following terms are applicable and
        will prevail in event of any conflict with any other provisions of this
        Agreement:
      </p>

      <p class="indent-1">
        a)&nbsp;This Agreement is between Trimble and Customer and governs all
        access and use of the Product(s) by Customer. Resellers are not
        authorized to modify this Agreement or make any promises or commitments
        on Trimble’s behalf, and Trimble is not bound by any obligations to
        Customer other than as set forth in this Agreement. Trimble is not party
        to (or responsible under) any separate agreement between Customer and
        Reseller and is not responsible for the Reseller’s acts, omissions,
        products or services. The applicable Product(s) list price for the
        applicable Utilization Term will be deemed the amount paid or payable by
        Customer to Trimble under this Agreement for purposes of Section 11
        (Limitations of Liability).
      </p>

      <p class="indent-1">
        b)&nbsp;Instead of paying Trimble, Customer will pay the applicable
        amounts to the Reseller, as agreed between Customer and the Reseller. If
        the Reseller fails to pay Trimble the applicable fees for Customer’s use
        of the Product(s), Trimble reserves the right to terminate the
        applicable Utilization Term for such Product(s) and all related rights
        granted hereunder. Customer may purchase renewal Utilization Terms for
        the Product(s) under this Agreement directly from Trimble pursuant to an
        Order.
      </p>

      <p class="indent-1">
        c)&nbsp;Customer’s order details (e.g., the applicable Product(s), the
        Utilization Term, Usage Limitations, Authorized Users, and any
        additional scope of use restrictions) will be as stated in the Order
        issued by Trimble (i.e., entitlement confirmation), and the Reseller is
        responsible for the accuracy of any such information as communicated to
        Trimble. Unless otherwise designated by Trimble, the Reseller is solely
        responsible for delivering to Customer any Product(s), and Trimble has
        no liability for the Reseller’s failure to deliver such materials.
      </p>

      <p class="indent-1">
        d)&nbsp;The Reseller may fulfill Trimble’s warranty obligations under
        Section 8.1 (Limited Warranty) on behalf of Trimble, to the extent
        authorized by Trimble in writing. Notwithstanding the foregoing, the
        Reseller has no authority to make any statements, representations,
        warranties or commitments on Trimble’s behalf and any such statements,
        representations, warranties or commitments are null and void. If the
        Reseller agrees to provide front-line support or professional services
        to Customer, Trimble has no responsibility for such Reseller-provided
        support or professional services.
      </p>

      <p class="indent-1">
        e)&nbsp;In the event Customer is entitled to a refund under this
        Agreement, Customer must request such refund through the Reseller. Any
        request sent directly to Trimble may be redirected to the Reseller.
        Trimble will refund any applicable fees to the Reseller and the Reseller
        will be solely responsible for refunding such fees to Customer, unless
        otherwise specified by Trimble. Trimble will have no further liability
        to Customer in the event the Reseller fails to refund such fees to
        Customer.
      </p>

      <p><strong>8. Warranties and Disclaimers.</strong></p>

      <p>
        <strong>8.1.</strong>&nbsp;<span class="underline"
          >Limited Warranty</span
        >. Unless otherwise specified in the Product-Specific Terms, and subject
        to any mandatory Laws to the contrary, Trimble warrants to Customer that
        during the Warranty Period the Product(s) will perform materially as
        described in the Documentation. The “<strong>Warranty Period</strong>”
        is (1) for Software that is licensed on a perpetual basis, 90 days from
        the Subscription Commencement Date, and (2) for any Service and/or
        Software that is licensed for a limited Utilization Term, the applicable
        Utilization Term.
      </p>

      <p>
        <strong>8.2.</strong>&nbsp;<span class="underline">Warranty Remedy</span
        >. If Trimble breaches Section 8.1 (Limited Warranty) during the
        Warranty Period and Customer makes a reasonably detailed warranty claim
        within 30 days of discovering the issue, then Trimble will use
        reasonable efforts to correct the non-conformity or provide a
        work-around. If Trimble determines such remedy to be impracticable,
        either party may terminate the affected Order to the extent that it
        relates to the non-conforming Product(s). Trimble will then refund to
        Customer any pre-paid, unused fees for the terminated portion of the
        Utilization Term. Subject to any mandatory Laws to the contrary, these
        procedures are Customer’s exclusive remedy and Trimble’s entire
        liability for breach of the warranty in Section 8.1 (Limited Warranty).
        These warranties do not apply to (a) issues caused by misuse or
        unauthorized modifications, (b) unsupported versions of Software, (c)
        issues in or caused by Third-Party Platforms or other third-party
        systems or (d) Trials and Betas or other free educational or evaluation
        use.
      </p>

      <p>
        <strong>8.3.</strong>&nbsp;<span class="underline">Disclaimers</span>.
      </p>

      <p class="indent-1">
        a)&nbsp;
        <strong
          ><span class="underline">General</span>: EXCEPT AS EXPRESSLY PROVIDED
          IN SECTION 8.1 (LIMITED WARRANTY), PRODUCT(S) AND SUPPORT ARE PROVIDED
          “AS IS”. TRIMBLE AND ITS SUPPLIERS MAKE NO (AND HEREBY DISCLAIM ALL)
          OTHER WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
          INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, NONINFRINGEMENT OR ANY WARRANTIES ARISING FROM A
          COURSE OF DEALING OR USAGE OF TRADE. WITHOUT LIMITING ITS EXPRESS
          OBLIGATIONS IN SECTION 6 (SUPPORT AND PROFESSIONAL SERVICES), TRIMBLE
          DOES NOT WARRANT THAT CUSTOMER’S USE OF THE PRODUCT(S) WILL BE
          UNINTERRUPTED OR ERROR-FREE, THAT TRIMBLE WILL REVIEW CUSTOMER DATA
          FOR ACCURACY OR THAT IT WILL MAINTAIN CUSTOMER DATA OR OTHER DATA
          WITHOUT LOSS. TRIMBLE IS NOT LIABLE FOR DELAYS, FAILURES OR PROBLEMS
          INHERENT IN USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER
          SYSTEMS OUTSIDE TRIMBLE’S CONTROL. TRIMBLE WILL NOT BE LIABLE IN ANY
          MANNER FOR THE OUTPUT OBTAINED THROUGH USE OF THE PRODUCT(S) OR
          CUSTOMER’S RELIANCE ON SUCH OUTPUT. CUSTOMER IS RESPONSIBLE FOR THE
          SUPERVISION, MANAGEMENT AND CONTROL OF CUSTOMER’S USE OF THE
          PRODUCT(S). THIS RESPONSIBILITY INCLUDES THE DETERMINATION OF
          APPROPRIATE USES FOR THE PRODUCT(S) AND THE SELECTION OF THE
          PRODUCT(S) AND OTHER PROGRAMS TO ACHIEVE INTENDED RESULTS. CUSTOMER IS
          ALSO RESPONSIBLE FOR ESTABLISHING THE ADEQUACY OF INDEPENDENT
          PROCEDURES FOR TESTING THE RELIABILITY AND ACCURACY OF ANY OUTPUT OF
          THE PRODUCT(S). CUSTOMER MAY HAVE OTHER STATUTORY RIGHTS, BUT ANY
          STATUTORILY REQUIRED WARRANTIES WILL BE LIMITED TO THE SHORTEST
          LEGALLY PERMITTED PERIOD.</strong
        >
      </p>

      <p class="indent-1">
        b)&nbsp;
        <strong
          ><span class="underline">Customer Applications</span>. Trimble hereby
          disclaims any warranty, support or other obligations with respect to
          any Customer Applications.</strong
        >
      </p>

      <p class="indent-1">
        c)&nbsp;
        <strong
          ><span class="underline">Scripts</span>. Subject to mandatory Laws to
          the contrary, Scripts are provided “AS IS” and Trimble hereby
          disclaims any warranty, support, or other obligations with respect to
          any Scripts, including, without limitation, any Scripts provided by
          Trimble.</strong
        >
      </p>

      <p class="indent-1">
        d)&nbsp;
        <strong
          ><span class="underline"
            >Third-Party Materials and Third-Party Platforms</span
          >. Third-Party Materials and Third-Party Platforms are provided “AS
          IS” and Customer assumes all risk and liability regarding any use of
          (or results obtained through) Third-Party Materials or Third-Party
          Platforms. Trimble and its suppliers make no warranty or guarantee
          regarding any Third-Party Materials or Third-Party Platforms,
          including regarding their accuracy or continued availability or
          compatability.</strong
        >
      </p>

      <p class="indent-1">
        e)&nbsp;
        <strong
          ><span class="underline"
            >High Risk Activities and Prohibited Data</span
          >. Trimble and its suppliers specifically disclaim any responsibility
          for, and will not be liable in any manner arising from, any use of the
          Product(s) in connection with High Risk Activities or with any
          Prohibited Data.</strong
        >
      </p>

      <p class="indent-1">
        f)&nbsp;
        <strong
          ><span class="underline">Global Positioning Data</span>. Trimble and
          its suppliers are not responsible for the operation or failure of
          operation of any Global Positioning System (“GPS”) or Global
          Navigation Satellite System (“GNSS”) satellites or base stations, or
          the availability of GPS or GNSS satellite signals. You acknowledge
          that use of the Product(s) is subject to transmission limitations
          caused by a variety of factors such as atmospheric conditions,
          topographical obstructions, limitations or lack of coverage of the
          underlying carrier service, and other natural or manmade conditions.
          Additionally, motor and ignition noise, metal shielding, and
          interference by users of the same or adjacent radio channels may limit
          or interfere with coverage.</strong
        >
      </p>

      <p><strong>9. Term and Termination.</strong></p>

      <p>
        <strong>9.1.</strong>&nbsp;<span class="underline">Term</span>. This
        Agreement starts on the Effective Date and continues until expiration or
        termination of all Utilization Terms for all Product(s).
      </p>

      <p>
        <strong>9.2.</strong>&nbsp;<span class="underline">Termination</span>.
        Either party may terminate this Agreement (including all Orders) if the
        other party (a) fails to cure a material breach of this Agreement
        (including a failure to pay fees) within 30 days after notice, (b)
        ceases operation without a successor or (c) seeks protection under a
        bankruptcy, receivership, trust deed, creditors’ arrangement,
        composition or comparable proceeding, or if such a proceeding is
        instituted against that party and not dismissed within 60 days.
      </p>

      <p>
        <strong>9.3.</strong>&nbsp;
        <span class="underline">Effect of Termination</span>. Upon expiration or
        termination of this Agreement or an Order, Customer’s right to use the
        Product(s) (including its license to any Software) will cease and
        Customer will immediately cease any and all use of and access to the
        Product(s) and will delete (or, upon request, return) all copies of any
        Software. At the disclosing party’s request upon expiration or
        termination of this Agreement, the receiving party will delete all of
        the disclosing party’s Confidential Information (excluding Customer
        Data, which is addressed in Section 2.1 (Date Usage and Ownership).
        Customer Data and other Confidential Information may be retained in the
        receiving party’s standard backups after deletion but will remain
        subject to this Agreement’s confidentiality restrictions.
      </p>

      <p>
        <strong>9.4.</strong>&nbsp;<span class="underline">Survival</span>.
        These Sections survive expiration or termination of this Agreement: 1.4
        (Restrictions), 2.1 (Data Usage and Ownership), 3 (Customer
        Obligations), 7.2 (Fees and Taxes), 8.3 (Disclaimers), 9.3 (Effect of
        Termination), 9.4 (Survival), 10 (Ownership), 11 (Limitations of
        Liability), 12 (Indemnification), 13 (Confidentiality), 14 (Required
        Disclosures), 16 (General Terms) and 17 (Definitions). Except where an
        exclusive remedy is provided, exercising a remedy under this Agreement,
        including termination, does not limit other remedies a party may have.
      </p>

      <p>
        <strong>10. Ownership.</strong> Neither party grants the other any
        rights or licenses not expressly set out in this Agreement. Except for
        Customer’s use rights in this Agreement, Trimble and its licensors
        retain all intellectual property and other rights in the Product(s),
        Documentation, other deliverables and related Trimble technology,
        templates, formats and dashboards, including any modifications or
        improvements to these items made by Trimble. If Customer provides
        Trimble with any suggestions, ideas, enhancement requests, feedback,
        recommendations or other information relating to the Products
        (“Feedback”), Customer hereby grants to Trimble and its Affiliates a
        nonexclusive, worldwide, perpetual, irrevocable, transferable,
        sublicensable, royalty-free, fully paid up license to use and otherwise
        exploit the Feedback.
      </p>

      <p><strong>11. Limitations of Liability.</strong></p>

      <p>
        <strong>11.1.</strong>&nbsp;
        <span class="underline"
          >Consequential Damages Waiver; Liability Cap</span
        >.
        <strong
          >EXCEPT FOR EXCLUDED CLAIMS (AS DEFINED BELOW), TO THE GREATEST EXTENT
          PERMITTED BY APPLICABLE LAW, EVEN IF SUCH DAMAGES COULD HAVE BEEN
          FORESEEN OR IF A PARTY HAS BEEN APPRAISED OF THE POSSIBILITY OF SUCH
          DAMAGES, AND REGARDLESS OF WHETHER SUCH DAMAGES ARE ARISING IN
          CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, BREACH OF ANY STATUTORY
          DUTY OR OTHERWISE, IN NO EVENT WILL (A) EITHER PARTY (OR ITS
          SUPPLIERS) BE LIABLE FOR DAMAGES FOR LOSS OF PROFIT OR REVENUE, DATA
          THAT IS LOST OR CORRUPTED, FAILURE OF SECURITY MECHANISMS,
          INTERRUPTION OF BUSINESS, LOSS OF GOODWILL, OR ANY SPECIAL,
          INCIDENTAL, RELIANCE, INDIRECT, PUNITIVE OR CONSEQUENTIAL DAMAGES OF
          ANY KIND OR (B) EACH PARTY’S (AND ITS SUPPLIERS) ENTIRE LIABILITY
          ARISING OUT OF OR RELATED TO THIS AGREEMENT WILL NOT EXCEED IN
          AGGREGATE THE AMOUNTS PAID OR PAYABLE BY CUSTOMER TO TRIMBLE DURING
          THE PRIOR 12 MONTHS UNDER THIS AGREEMENT FOR THE APPLICABLE PRODUCT(S)
          GIVING RISE TO THE LIABILITY. SOME JURISDICTIONS DO NOT ALLOW A
          LIMITATION OF LIABILITY FOR DEATH, PERSONAL INJURY, FRAUDULENT
          MISREPRESENTATIONS OR CERTAIN INTENTIONAL OR NEGLIGENT ACTS, OR
          VIOLATION OF SPECIFIC STATUTES, OR THE LIMITATION OF INCIDENTAL OR
          CONSEQUENTIAL DAMAGES. IN SUCH AN EVENT THE FOREGOING LIMITATION(S)
          WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.</strong
        >
      </p>

      <p>
        <strong>11.2.</strong>&nbsp;<span class="underline"
          >Excluded Claims</span
        >. “Excluded Claims” means: (a) Customer’s breach of Section 1.3 (API
        Access and Customer Applications), 1.4 (Restrictions), 3 (Customer
        Obligations), or 5.4 (Third-Party Materials), (b) either party’s breach
        of Section 13 (Confidentiality) (but excluding claims relating to
        Customer Data); (c) amounts payable to third parties under Customer’s
        obligations in Section 12 (Indemnification) or (d) any liabilities that
        cannot be excluded or limited by Laws.
      </p>

      <p>
        <strong>11.3.</strong>&nbsp;
        <span class="underline"
          >Nature of Claims and Failure of Essential Purpose</span
        >. The waivers and limitations in this Section 11 (Limitations of
        Liability) apply regardless of the form of action, whether in contract,
        tort (including negligence), strict liability or otherwise and will
        survive and apply even if any limited remedy in this Agreement fails of
        its essential purpose.
      </p>

      <p><strong>12. Indemnification.</strong></p>

      <p>
        <strong>12.1.</strong>&nbsp;
        <span class="underline">Indemnification by Customer</span>. Customer
        will defend, indemnify and hold harmless Trimble from and against any
        and all third-party claims, costs, damages, losses, liabilities and
        expenses (including reasonable attorneys’ fees and costs) arising out of
        or in connection with (a) any Customer Data, Third-Party Platforms, or
        third-party Devices, (b) Customer’s breach or alleged breach of 3
        (Customer Obligations) or 5.4 (Third-Party Materials) or (c) any service
        or product offered by Customer (including any Customer Application) in
        connection with or related to the Product(s) (each, a
        “<strong>Claim</strong>”).
      </p>

      <p>
        <strong>12.2.</strong>&nbsp;<span class="underline">Procedures</span>.
        Trimble will give Customer prompt written notice of any Claim and will
        cooperate in relation to the Claim at Customer’s expense. Customer will
        have the exclusive right to control and settle any Claim, except that
        Customer may not settle a Claim without Trimble’s prior written consent
        (not to be unreasonably withheld) if the settlement requires Trimble to
        admit any liability or take any action or refrain from taking any action
        (other than ceasing use of infringing materials). Trimble may
        participate in the defense of any Claim at its expense.
      </p>

      <p><strong>13. Confidentiality.</strong></p>

      <p>
        <strong>13.1.</strong>&nbsp;<span class="underline">Definition</span>.
        “<strong>Confidential Information</strong>” means information disclosed
        to the receiving party under this Agreement that is designated by the
        disclosing party as proprietary or confidential or that should be
        reasonably understood to be proprietary or confidential due to its
        nature and the circumstances of its disclosure. Trimble’s Confidential
        Information includes the terms and conditions of this Agreement and any
        technical or performance information about the Product(s). Customer’s
        Confidential Information includes Customer Data.
      </p>

      <p>
        <strong>13.2.</strong>&nbsp;<span class="underline">Obligations</span>.
        As a receiving party, each party will use reasonable care to protect the
        disclosing Party’s Confidential Information from being disclosed third
        parties except as permitted in this Agreement, including, without
        limitation, in Section 2.1 (Data Usage and Ownership), and (b) only use
        Confidential Information to fulfill its obligations and exercise its
        rights in this Agreement. The receiving party may disclose Confidential
        Information to its employees, agents, Affiliates, contractors and other
        representatives having a legitimate need to know (including, for
        Trimble, the subcontractors referenced in Section 16.9
        (Subcontractors)), provided it remains responsible for their compliance
        with this Section 13 (Confidentiality) and they are bound to
        confidentiality obligations no less protective than this Section 13
        (Confidentiality).
      </p>

      <p>
        <strong>13.3.</strong>&nbsp;<span class="underline">Exclusions</span>.
        These confidentiality obligations do not apply to information that the
        receiving party can document (a) is or becomes public knowledge through
        no fault of the receiving party, (b) it rightfully knew or possessed
        prior to receipt under this Agreement, (c) it rightfully received from a
        third party without breach of confidentiality obligations or (d) it
        independently developed without using the disclosing party’s
        Confidential Information.
      </p>

      <p>
        <strong>13.4.</strong>&nbsp;<span class="underline">Remedies</span>.
        Unauthorized use or disclosure of Confidential Information may cause
        substantial harm for which damages alone are an insufficient remedy.
        Each party may seek appropriate equitable relief, in addition to other
        available remedies, for breach or threatened breach of this Section 13
        (Confidentiality).
      </p>

      <p>
        <strong>14. Required Disclosures.</strong> Nothing in this Agreement
        prohibits either party from making disclosures, including of Customer
        Data and other Confidential Information, if required by Law, subpoena or
        court order, provided (if permitted by Law) it notifies the other party
        in advance and reasonably cooperates in any effort to obtain
        confidential treatment.
      </p>

      <p>
        <strong>15. Publicity.</strong> Neither party may publicly announce this
        Agreement except with the other party’s prior consent or as required by
        Law. However, Trimble may include Customer and its trademarks in
        Trimble’s customer lists and promotional materials but will cease this
        use at Customer’s written request.
      </p>

      <p><strong>16. General Terms.</strong></p>

      <p>
        <strong>16.1.</strong>&nbsp;<span class="underline">Assignment</span>.
        Trimble may assign this Agreement upon notice to Customer. Customer may
        not assign or transfer this Agreement (by operation of law or otherwise)
        without the prior consent of Trimble. Any non-permitted assignment is
        void. This Agreement will bind and inure to the benefit of each party’s
        permitted successors and assigns.
      </p>

      <p>
        <strong>16.2.</strong>&nbsp;<span class="underline">Notices</span>.
        Except as set out in this Agreement, any notice or consent under this
        Agreement must be in writing and will be deemed given: (a) upon receipt
        if by personal delivery, (b) upon receipt if by certified or registered
        mail (return receipt requested) or (c) one day after dispatch if by an
        internationally reputable commercial overnight delivery service. If to
        Trimble, notice must be provided to the address in Section 16.15
        (Trimble Entity; Governing Law; Jurisdiction) below, with a copy to
        Trimble Inc., Attn: General Counsel – Important Notice, 935 Stewart
        Drive, Sunnyvale, CA 94085, USA. If to Customer, Trimble may provide
        notice to the address Customer provided at registration or on the Order.
        Either party may update its address with notice to the other party.
        Trimble may also send general and operational notices to Customer by
        email or through the Product(s).
      </p>

      <p>
        <strong>16.3.</strong>&nbsp;<span class="underline"
          >Entire Agreement</span
        >. This Agreement (which includes the Order and all applicable, the
        Product-Specific Terms, Support Terms, Product Policies) is the parties’
        entire agreement regarding its subject matter and supersedes any prior
        or contemporaneous agreements regarding its subject matter. In this
        Agreement, headings are for convenience only and “including” and similar
        terms are to be construed without limitation. The terms in any Customer
        purchase order, business form, or other similar documents will not amend
        or modify this Agreement and are expressly rejected by Trimble; any of
        these Customer documents are for administrative purposes only and have
        no legal effect.
      </p>

      <p>
        <strong>16.4.</strong>&nbsp;
        <span class="underline">Modifications to Agreement</span>. Subject to
        Section 16.5 (Modifications to Product Policies) below, Trimble may
        modify this Agreement from time to time with notice to Customer.
        Modifications take effect at Customer’s next Utilization Term, if any,
        for an existing Order, or from the start of a new Order, unless Trimble
        indicates an earlier effective date. If Trimble requires modifications
        with an earlier effective date and Customer objects in writing, then
        Trimble may permit such modifications to take effect at the date
        indicated above. If Trimble declines to do so, Customer’s exclusive
        remedy is to terminate this Agreement with notice to Trimble, in which
        case Trimble will provide Customer a refund of any applicable pre-paid
        Product fees for the terminated portion of the current Utilization Term.
        To exercise this termination right, Customer must notify Trimble of its
        objections within 30 days after Trimble’s notice of the modified
        Agreement. Once the modified Agreement takes effect Customer’s continued
        use of the Product(s) constitutes its acceptance of the modifications.
        Trimble may require Customer to click to accept the modified Agreement.
      </p>

      <p>
        <strong>16.5.</strong>&nbsp;
        <span class="underline">Modifications to Product Policies</span>.
        Product Policies are not subject to Section 16.4 (Modifications to
        Agreement). With notice to Customer, Trimble may modify the Policies to
        reflect new features or changing practices, but the modifications will
        not materially decrease Trimble’s overall obligations during a
        Utilization Term.
      </p>

      <p>
        <strong>16.6.</strong>&nbsp;<span class="underline">Amendments</span>.
        Except as otherwise provided herein, any amendments, modifications or
        supplements to this Agreement must be in writing and signed by each
        party’s authorized representatives or, as appropriate, agreed through
        electronic means provided by Trimble.
      </p>

      <p>
        <strong>16.7.</strong>&nbsp;
        <span class="underline">Waivers and Severability</span>. Waivers must be
        in writing signed by the waiving party’s authorized representative and
        cannot be implied from conduct. Each provision contained in this
        Agreement constitutes a separate and distinct provision severable from
        all other provisions. If any provision (or any part thereof) is
        unenforceable under or prohibited by any present or future law or is
        held by a court of competent jurisdiction or arbitrator to be invalid,
        void or unenforceable, then such provision (or part thereof) will be
        amended, and is hereby amended, so as to be in compliance with such law,
        while preserving to the maximum extent possible the intent of the
        original provision. Any provision (or part thereof) that cannot be so
        amended will be severed from this Agreement; and, all the remaining
        provisions of this Agreement will remain unimpaired.
      </p>

      <p>
        <strong>16.8.</strong>&nbsp;<span class="underline">Force Majeure</span
        >. Neither party is liable for any delay or failure to perform any
        obligation under this Agreement (except for a failure to pay fees) due
        to events beyond its reasonable control, such as a strike, blockade,
        war, act of terrorism, riot, Internet or utility failures, refusal of
        government license, pandemics or natural disaster.
      </p>

      <p>
        <strong>16.9.</strong>&nbsp;<span class="underline">Subcontractors</span
        >. Trimble may use subcontractors and permit them to exercise Trimble’s
        rights in connection with this Agreement, including for hosting
        purposes. Trimble remains responsible for compliance of any such
        subcontractors with this Agreement and for its overall performance under
        this Agreement.
      </p>

      <p>
        <strong>16.10.</strong>&nbsp;
        <span class="underline">Independent Contractors</span>. The parties are
        independent contractors, not agents, partners or joint venturers.
      </p>

      <p>
        <strong>16.11.</strong>&nbsp;
        <span class="underline">Compliance; Export Control</span>.
      </p>

      <p class="indent-1">
        a)&nbsp;<span class="underline">Compliance with Laws</span>. Customer is
        responsible for complying with all applicable Laws in its use of the
        Product(s).
      </p>

      <p class="indent-1">
        b)&nbsp;<span class="underline">Export Restrictions</span>. Customer
        acknowledges that the Product(s) are subject to export restrictions by
        the United States government and import restrictions by certain foreign
        governments. Customer will not, and will not allow any third-party to,
        remove or export from the United States or allow the export or re-export
        of any part of the Product(s) or any direct product thereof: (i) into
        (or to a national or resident of) any embargoed or terrorist-supporting
        country; (ii) to anyone on the U.S. Commerce Department’s Table of
        Denial Orders or U.S. Treasury Department’s list of Specially Designated
        Nationals; (iii) to any country to which such export or re-export is
        restricted or prohibited, or as to which the United States government or
        any agency thereof requires an export license or other governmental
        approval at the time of export or re-export without first obtaining such
        license or approval; or (iv) otherwise in violation of any export or
        import restrictions, Laws s of any United States or foreign agency or
        authority. Customer warrants that it is not located in, under the
        control of, or a national or resident of any such prohibited country or
        on any such prohibited party list. The Product(s) are further restricted
        from being used for the design or development of nuclear, chemical, or
        biological weapons or missile technology, or for terrorist activity,
        without the prior permission of the United States government. Customer
        will defend, indemnify and hold Trimble harmless against any liability
        (including attorneys’ fees) arising out of Customer’s failure to comply
        with the terms of this provision. Customer’s obligations under this
        paragraph will survive the termination of this Agreement for any reason
        whatsoever.
      </p>

      <p>
        <strong>16.12.</strong>&nbsp;
        <span class="underline">Government End-Users</span>. Elements of the
        Product(s) are commercial computer software. If the user or licensee of
        the Product(s) is an agency, department, or other entity of the United
        States Government, the use, duplication, reproduction, release,
        modification, disclosure, or transfer of the Product(s) or any related
        documentation of any kind, including technical data and manuals, is
        restricted by the terms of this Agreement in accordance with Federal
        Acquisition Regulation 12.212 for civilian purposes and Defense Federal
        Acquisition Regulation Supplement 227.7202 for military purposes. The
        Product(s) were developed fully at private expense. All other use is
        prohibited.
      </p>

      <p>
        <strong>16.13.</strong>&nbsp;
        <span class="underline">No Third Party Beneficiaries</span>. This
        Agreement does not confer any rights or remedies upon any third party
        except to the extent expressly set forth in this Agreement. The parties
        to this Agreement may rescind or vary this Agreement without the consent
        of any such third party beneficiaries.
      </p>

      <p>
        <strong>16.14.</strong>&nbsp;
        <span class="underline">Official Language</span>. The official language
        of this Agreement is English. If there is a conflict between versions of
        this Agreement in any other language, the English language version
        controls.
      </p>

      <p>
        <strong>16.15.</strong>&nbsp;
        <span class="underline">Trimble Entity; Governing Law and Venue</span>.
        Unless a different entity is specified in the applicable Order or the
        Product-Specific Terms or in this Agreement, “Trimble” for purposes of
        this Agreement will mean the “Trimble Entity” set forth below. The
        Agreement is governed exclusively by, and construed and enforced
        exclusively in accordance with, the laws of the applicable jurisdiction
        set forth below under “Governing Law”, without regard to or application
        of its conflicts of laws provisions and without regard to or application
        of the United Nations Convention on the International Sale of Goods. The
        parties agree that any legal proceeding arising out of or related to
        this Agreement will be subject to the sole and exclusive jurisdiction
        and venue set forth below under “Exclusive Venue/Jurisdiction,” to the
        exclusion of all others. Each party irrevocably consents and hereby
        submits to the personal jurisdiction thereof.
      </p>

      <table
        style="width: 100%; margin-bottom: 10px; border-collapse: collapse"
        cellpadding="0"
        cellspacing="0"
        aria-describedby="Locations and Jurisdictions"
      >
        <tr>
          <th scope="col">Customer Location*</th>
          <th scope="col">Trimble Entity and Notice Address</th>
          <th scope="col">Governing Law</th>
          <th scope="col">Exclusive Venue/Jurisdiction</th>
        </tr>
        <tr>
          <td>United States</td>
          <td style="text-align: left">
            Trimble Inc.<br />
            935 Stewart Drive,<br />
            Sunnyvale, CA 94085 USA
          </td>
          <td>California</td>
          <td style="text-align: left">
            State of California and United States federal courts located in
            Sunnyvale, California
          </td>
        </tr>
        <tr>
          <td>Australia</td>
          <td style="text-align: left">
            Trimble Europe B.V.<br />
            Industrieweg 187a, 5683 CC,<br />
            Best, The Netherlands
          </td>
          <td style="text-align: left">New South Wales</td>
          <td style="text-align: left">
            Courts of New South Wales and the courts having appeal from them
            Courts of New South Wales and the courts having appeal from them
          </td>
        </tr>
        <tr>
          <td style="text-align: left">
            Any other country or geography not specified above
          </td>
          <td style="text-align: left">
            Trimble Europe B.V.<br />
            Industrieweg 187a, 5683 CC,<br />
            Best, The Netherlands
          </td>
          <td>The Netherlands</td>
          <td style="text-align: left">
            An arbitrator located in Eindhoven, The Netherlands, selected in
            accordance with the International Chamber of Commerce (“ICC”)***
          </td>
        </tr>
      </table>

      <p class="notes-title underline">Notes:</p>
      <p class="notes">
        * Customer location determined by Customer’s billing address specified
        on the Order, or if none, then the address provided by Customer to
        Trimble when registering its online account.
      </p>
      <p class="notes">
        ** See additional required notice address for Trimble in Section 16.2
        (Notices).
      </p>
      <p class="notes" style="margin-bottom: 15px">
        *** In the event arbitration under an ICC process is specified above,
        except as provided in this paragraph with respect to injunctive relief,
        all disputes regarding the Product(s) or this Agreement will be finally
        resolved by binding and final arbitration before a single arbitrator,
        selected in accordance with the rules of the ICC. Any arbitration will
        be conducted at the indicated location in the English language. After
        each party has been afforded a reasonable opportunity to present written
        and testimonial evidence in support of its position in any such
        arbitration proceeding, the arbitrator will issue his/her decision and
        award, which will (i) be in writing, stating the reasons therefore, (ii)
        be based solely on the terms and conditions of the Agreement, and (iii)
        except as provided in this paragraph regarding injunctive relief, be
        final and binding upon the parties. The arbitrator will not award
        punitive or exemplary damages. The parties, their representatives, and
        any other participants will hold the existence, content, and result of
        arbitration in confidence. The provisions of this paragraph may be
        enforced by any court of competent jurisdiction. Notwithstanding the
        foregoing, (A) either party may, at its sole discretion, seek injunctive
        relief in any court of competent jurisdiction (including, but not
        limited to, preliminary injunctive relief), and (B) Trimble may bring
        suit against Customer in the courts at Customer's place of business for
        infringement of intellectual property rights, over usage of Product(s)
        and breach of restrictions of license or usage limitations and
        misappropriations of confidential information and trade secrets or other
        intellectual property rights.
      </p>

      <p>
        <strong>16.16.</strong>&nbsp;
        <span class="underline">Australia-Specific Terms</span>. For Customer
        who purchase Product(s) in Australia, the following provisions apply:
      </p>

      <p class="indent-1">
        a)&nbsp;For the purposes of this Section 16.16 (Australia-Specific
        Terms), “Australian Consumer Law” means the Competition and Consumer Act
        2010 (Cth) and “Non-excludable Condition” means certain consumer
        guarantees, warranties, rights, or remedies under the Australian
        Consumer Law that cannot be limited, excluded, restricted, or modified,
        and to which Customer may be entitled. For purposes of the following
        limitation of liability statement, the Parties intend for the following:
        “service” means a Service and “goods” means Software.
      </p>

      <p class="indent-1">
        b)&nbsp;To the extent permitted by law, Trimble’s liability in relation
        to breach of any such Non-excludable Condition shall be limited as
        follows (and in which “our” “or “us” means Trimble, and “you” means
        Customer):
      </p>

      <p class="indent-2">
        i)&nbsp;in the case of the goods, to repairing or replacing the goods,
        supplying equivalent goods, or paying the costs of repairing or
        replacing the goods or acquiring equivalent goods; and
      </p>

      <p class="indent-2">
        ii)&nbsp;in the case of the services, to re-supplying the services or
        paying the cost of re-supplying the services.
      </p>

      <p class="indent-1">
        c)&nbsp;Nothing in these terms excludes, restricts or modifies any
        condition, warranty, right or remedy implied or imposed by any statute
        or regulation which cannot lawfully be excluded, restricted or modified.
      </p>

      <p class="indent-1">
        d)&nbsp;Nothing in these terms is intended to derogate from Trimble’s
        obligations under the Privacy Act 1988 (Cth) as amended from time to
        time.
      </p>

      <p><strong>17. Definitions.</strong></p>

      <p>
        “<strong>Affiliate</strong>” means an entity that, directly or
        indirectly, owns or controls, is owned or controlled by or is under
        common ownership or control with a party, where “ownership” means the
        beneficial ownership of fifty percent (50%) or more of an entity’s
        voting equity securities or other equivalent voting interests, and
        “control” means the power to direct the management or affairs of an
        entity.
      </p>

      <p>
        “<strong>Anonymized Data</strong>” means any data collected in
        connection with the Product(s) (including Customer Data) that has been
        aggregated and/or de-identified in such a manner that neither Customer
        nor any of its Authorized Users or any other individual can be
        identified from the data when it is shared outside of Trimble or its
        Affiliates.
      </p>

      <p>
        “<strong>Authorized User</strong>” means (a) any employee or contractor
        of Customer that Customer allows to use the Product(s) for its sole
        benefit, and/or (b) any other party(ies) expressly permitted to be
        “Authorized Users” in this Agreement (including the Order or any
        Product-Specific Terms), if any.
      </p>

      <p>
        “<strong>Customer Data</strong>” means any information, documents,
        materials, or other data of any type that is input by or on behalf of
        Customer into the Product(s), including without limitation information
        or data that is submitted manually by Authorized Users or through a
        Third-Party Platform, or that Customer may provide to Trimble in
        connection with receipt of Support.
      </p>

      <p>
        “<strong>Documentation</strong>” means Trimble’s then-current usage
        guidelines and standard technical documentation applicable to the
        Product(s).
      </p>

      <p>
        “<strong>High Risk Activities</strong>” means any mission critical,
        hazardous, strict liability or other activity(ies) where use or failure
        of the Product(s) could lead to death, personal injury or physical or
        environmental damage. Examples of High Risk Activities include, but are
        not limited to: aircraft or other modes of human mass transportation,
        nuclear or chemical facilities, life support systems, implantable
        medical equipment, motor vehicles, autonomous vehicles, air traffic
        control, emergency services, weaponry systems. High Risk Use does not
        include utilization of Cloud Service for administrative purposes, to
        store configuration data, engineering and/or configuration tools, or
        other non-control applications, the failure of which would not result in
        death, personal injury, or physical or environmental damage. These
        non-controlling applications may communicate with the applications that
        perform the control, but must not be directly or indirectly responsible
        for the control function.
      </p>

      <p>
        “<strong>Law(s)</strong>” means all applicable local, state, federal and
        international laws, regulations and conventions, including those related
        to data privacy and data transfer, international communications and
        export of technical or personal data.
      </p>

      <p>
        “<strong>License Keys</strong>” means electronic passwords or other
        enabling mechanisms provided for use with Software.
      </p>

      <p>
        “<strong>Order</strong>” means (a) any ordering documents, proposals,
        quotations, sales agreement or similar documents issued by Trimble or
        executed by both parties or, or (b) any Trimble-issued entitlement
        confirmation or online order acknowledgement, in each case of (a) or (b)
        for the Product(s) or Support for Software.
      </p>

      <p>
        “<strong>Product(s)</strong>” means the applicable Software or Service.
      </p>

      <p>
        “<strong>Product Policies</strong>” means the applicable Support Terms,
        acceptable use policies, service level commitments, or other policies
        referenced in this Agreement (including, without limitation, the Order
        or Product-Specific Terms) for a Product.
      </p>

      <p>
        “<strong>Professional Services</strong>” means any training, enablement,
        configuration or other professional consulting services provided by
        Trimble related to the Product(s), as identified in the Order.
      </p>

      <p>
        “<strong>Prohibited Data</strong>” means any (a) special categories of
        data enumerated in European Union Regulation 2016/679, Article 9(1) or
        any successor legislation, (b) patient, medical or other protected
        health information regulated by the Health Insurance Portability and
        Accountability Act (as amended and supplemented) (“HIPAA”), (c) credit,
        debit or other payment card data subject to the Payment Card Industry
        Data Security Standards (PCI DSS), (d) other information subject to
        regulation or protection under specific Laws such as the Children’s
        Online Privacy Protection Act or Gramm-Leach-Bliley Act (or related
        rules or regulations), (e) social security numbers, driver’s license
        numbers or other government ID numbers or (f) any data similar to the
        above protected under foreign or domestic Laws.
      </p>

      <p>
        “<strong>Service</strong>” means a Trimble proprietary cloud service, as
        identified in the relevant Order and as modified from time to time. The
        Service includes Documentation but does not include Third-Party
        Materials or Third Party Platforms.
      </p>

      <p>
        “<strong>Software</strong>” means the object code form of Trimble’s
        proprietary installed software product, as identified in the relevant
        Order. The Software includes the Documentation, and any maintenance
        releases of the same Software product provided by Trimble (or a
        Reseller) to Customer under this Agreement, and optional software
        component module(s) that provides specific features and functionality
        enhancements for the Software not available in the standard
        configuration of the Software. Software does not include Third-Party
        Materials or Third Party Platforms.
      </p>

      <p>
        “<strong>Support</strong>” means the Service Support or Software
        Support, as applicable.
      </p>

      <p>
        “<strong>Support Terms</strong>” means the Service Support Terms or
        Software Support Terms, as applicable.
      </p>

      <p>
        “<strong>Third-Party Materials</strong>” means any third-party data,
        content or proprietary software.
      </p>

      <p>
        “<strong>Third-Party Platform</strong>” means any platform, add-on,
        service or product not provided by Trimble that Customer elects to
        integrate or enable for use with the Product(s).
      </p>

      <p>
        “<strong>Trimble</strong>” means the Trimble entity identified in
        Section 16.15 (Trimble Entity; Governing Law and Venue); provided that
        (ii) for purchases made through a Reseller located in the United States,
        “Trimble” means Trimble Inc., and (iii) for purchases made through a
        Reseller located outside the United States, “Trimble” means Trimble
        Europe B.V.
      </p>

      <p>
        “<strong>Usage Limitations</strong>” means Customer’s authorized scope
        of use for the Product(s) as specified in the applicable Order or
        Product-Specific Terms, which may include any user, seat, copy,
        instance, CPU, computer, field of use, location or other restrictions.
      </p>

      <p>
        “<strong>Utilization Term(s)</strong>” means the period of time, as
        identified in an Order, for which Customer is at any point in time
        currently authorized to use a Service or licensed to use a Software
        (including if perpetual).
      </p>

      <p class="title">
        Exhibit A<br />
        Product-Specific Terms<br />
        (Construction Analytics)
      </p>

      <p>
        <strong>1. Contributor Data Sources.</strong> The Service includes data
        collected from users of certain Trimble offerings (“Contributor(s)”) for
        estimation, quoting, modeling, procurement, and other buildings and/or
        construction related products (“Contributor Products”). A list of
        then-current Contributor Products is available in the Documentation,
        which may be updated from time to time. Data collected from Contributor
        Products is referred to herein as “Contributor Data” and, in accordance
        with the end user agreement for the applicable Contributor Product, may
        be disclosed in connection with other Trimble offerings, in aggregated
        and/or anonymized form, or with the consent and/or instruction of a
        Contributor, in identifiable form. Contributors could be contractors,
        distributors, and/or manufacturers.
      </p>

      <p><strong>2. Additional Contributor Data Use Rights</strong></p>

      <p>
        <strong>2.1</strong>&nbsp;
        <span class="underline"
          >Contributor Data for Customer’s Own Use in the Service</span
        >. Customer hereby consents to Trimble and its Affiliates providing, via
        the Service, Customer’s Contributor Data to Customer and any of
        Customer’s Affiliates that are designated by Customer in writing to
        receive such Contributor Data.
      </p>

      <p>
        <strong>2.2</strong>&nbsp;
        <span class="underline"
          >Contributor Data Sharing in the Business Group</span
        >.
      </p>

      <p>
        <strong>a)</strong>&nbsp;<em>Business Group Definition</em>. A “Business
        Group” means users of the Service who are typically a contractor,
        manufacturer, and/or distributor and who are connected through
        Contributor Data associated with an item or component used on a
        particular project. By way of illustration and not limitation, a
        contractor may (i) use a Trimble estimating Contributor Product for a
        project, (ii) select a manufacturer’s component for that project, and
        (iii) solicit a price quotation from a distributor for that component.
        The Contributor Data related to that component on that project is
        connected to that particular contractor, manufacturer, and distributor,
        and those three parties will comprise a Business Group for that
        component on that project. Each user of the Service can be associated
        with more than one Business Group.
      </p>

      <p>
        <strong>b)</strong>&nbsp;<em>Consent to Data Sharing</em>. The Service
        permits Customer to set its level of data sharing with the Business
        Group, as described in the Documentation. Customer hereby consents to
        Trimble and its Affiliates providing, during the Utilization Term and
        via the Service, Customer’s Contributor Data that is applicable to the
        other Customers of the Service who comprise part of the applicable
        Business Group in accordance with Customer’s data sharing settings in
        the Service.
      </p>

      <p>
        <strong>c)</strong>&nbsp;<em>Confidentiality Obligations</em>. To the
        extent Customer has access to the Contributor Data of a third-party due
        to being part of a Business Group, Customer covenants to Trimble and to
        each Contributor (solely with respect to any Contributor Data it
        receives via the Service from that Contributor) that it will: (i) use
        such Contributor Data solely for its internal business purposes in
        accordance with all applicable laws and regulations, including, without
        limitation, with respect to data privacy and personal information; (ii)
        not disclose any such data to anyone, except to its employees or
        contractors in connection with its rights use of the Service in
        accordance with this Agreement; (iii) treat as confidential and preserve
        the confidentiality of all such Contributor Data; and (iv) use no less
        than a reasonable standard of care to protect such Contributor Data from
        unauthorized access, use or disclosure, except that none of the
        foregoing obligations will apply to the extent that Customer has a
        separate agreement with the applicable Contributor that allows for
        broader use of such Contributor’s Contributor Data. Nothing in this
        Agreement prohibits Customer from making disclosures of third-party
        Contributor Data, if required by Law, subpoena or court order, provided
        (if permitted by Law) it notifies the applicable Contributor in advance
        and reasonably cooperates in any effort to obtain confidential
        treatment. The applicable Contributor is an intended third-party
        beneficiary of this Section 2.2(Contributor Data Sharing in the Business
        Group).
      </p>
      <!-- End TOS Text -->
    </div>
    <div class="tos-buttons">
      <button
        id="disagreeButton"
        class="btn btn-outline-dark"
        [disabled]="appScrollElement.disableBtn"
        (click)="decline()"
        [title]="disagreeToolTip"
      >
        Disagree</button
      >&nbsp;
      <button
        id="agreeButton"
        class="btn btn-primary"
        [disabled]="appScrollElement.disableBtn"
        (click)="accept()"
        [title]="agreeToolTip"
      >
        Agree
      </button>
    </div>
  </div>
</div>
<div class="loader" *ngIf="busy">
  <img
    src="/assets/images/loader2.gif"
    width="90"
    height="75"
    alt="Loading Animation"
  />
  <p class="card-caption">Updating user profile...</p>
</div>
