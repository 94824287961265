<div class="ter-navbar is-fixed js-auto-hide shadow-sm">
  <div class="ter-navbar__universal-header">
    <div class="container-fluid wrap">
      <div class="trimble-logo">
        <svg
          id="Layer_1"
          viewBox="0 0 149.5 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="st0"
            d="M65.6 12.7h6.2V32h-6.2zM73.9 12.7h6.2v2.6h.1c1.3-2 3.3-3 5.5-3 1.9 0 3.8 1.2 3.9 3h.1c1.3-2 3.3-3 5.5-3 2.5 0 4.3 1.3 4.3 4.1V32h-6.2V18.3c0-1-.3-1.7-1.5-1.7-.7 0-1.2.3-1.6.8l-.4.5V32h-6.2V18.3c0-1-.3-1.7-1.5-1.7-.7 0-1.2.3-1.6.8l-.3.5V32H74V12.7h-.1zM101.6 4.6h6.2v9.2h.1c.9-.9 2.5-1.6 3.8-1.6 4.3 0 6.6 4.3 6.6 9.9 0 4.4-1.8 10.1-7.1 10.1-2.4 0-4-1.1-5.2-3l-1.2 2.8h-3.2V4.6zm6.2 21.2c-.1 1.6.8 2.5 2 2.5 1.7 0 2-1.7 2-5.3v-1.5c0-3.8 0-5.8-1.9-5.8-.8 0-1.3.5-1.7.8l-.3.3-.1 9zM119.8 4.6h6.2v27.3h-6.2zM133.9 23.1c0 1.7.3 4.6 2.8 4.6 1.5 0 2.8-1.1 3.1-2.6l4.2 1.5c-.3.9-2.2 5.3-7.7 5.3-5.9 0-8.6-4.8-8.6-10.1 0-5.5 2.8-10.1 8.6-10.1 6 0 8.1 5.1 8.1 10.2v1.3h-10.5v-.1zm4.6-3.6c0-1.8-.2-3.9-2.3-3.9-1.8 0-2.3 2.1-2.3 3.9h4.6zM65.6 4.5h6.2V10h-6.2zM145 29.9c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2m.4 0c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6s-.7-1.6-1.6-1.6c-.9 0-1.6.7-1.6 1.6m2.7 1.1h-.4l-.7-1h-.4v1h-.3v-2.2h1c.2 0 .4 0 .6.1.2.1.3.3.3.5 0 .4-.3.6-.7.6l.6 1zm-1.1-1.3c.3 0 .8.1.8-.3 0-.3-.3-.3-.5-.3h-.6v.7h.3v-.1zM35.4 4.4h19.3v5.3h-6.2v22.1h-6.9V9.7h-6.2z"
          ></path>
          <path
            class="st0"
            d="M51.9 12.5H58v2.9h.1c.8-2.4 3.5-3.4 6-3.4v6.3c-.8-.3-1.6-.5-2.5-.5-2.1 0-2.7.5-3.4 1.5v12.5H52l-.1-19.3zM23 25c-.1.4-.2.8-.4 1.1-.1.1-.1.2-.1.4-.3.3-.5.4-.5.5-1.4 1.2-2.6 1.8-4.3 2.4-.6.2-2 .6-3.7.5l-2.7 1.4c3.2.7 5.9.1 6.9-.3 2-.6 3.3-1.4 4.9-2.7.3-.3 2-1.7 3.2-4.1.2-.4.4-.9.6-1.4L23 25zM13.6 6.2h.8l-.9.3 2.4 1.3c1.9-.1 3.7.8 5 2.1-.3 0-.6.1-.9.1l4 2.2v-.1c.2.1.3.2.4.4l2.7 1.4c0-.1-.1-.2-.1-.3s0-.1-.1-.2c0-.1-.1-.2-.1-.3-.8-2.2-2.3-4.1-4.1-5.6l-.2-.2c-2.9-2.2-5.8-2.7-7.2-2.8h-.2c-.9-.1-1.8 0-2.7.1h-.2c-.4.1-.7.1-1 .2-.2.1-.5.1-.7.2L13 6.3c.3-.1.4-.1.6-.1M3.2 22.5c-.4-.9-.7-2.1-.7-2.6-.1-1.1-.1-1.1-.1-2.2 0-.3 0-.8.1-1.2.1-.8.3-1.8.8-3.1V9.9c-.4.6-.8 1.2-1.2 1.9-1 2-1.2 3.4-1.4 4.4-.1.5-.1 1.1-.1 1.5 0 1.2 0 1.2.1 2.4.1.7.5 2.3 1 3.4.4.9.8 1.6 1.2 2.2 0 .1.1.1.1.2.1.1.1.2.2.2v-3.9c.1.1 0 .2 0 .3"
          ></path>
          <path
            class="st0"
            d="M23.4 16.1c-.2.3-.4.5-.6.8.7 1.2 1.2 2.4 1.5 3.6.1-.2.3-.4.4-.6 1.1-1.8 1.3-3.9.5-6.1 0-.1-.1-.1-.1-.2-.1-.2-.1-.4-.2-.5s-.2-.2-.2-.3c-.1-.2-.2-.3-.3-.4l-.4-.2c.3 1.4.1 2.7-.6 3.9M12.7 6.3h-.1c-.6.1-1.1.2-1.7.4.5.1.9.3 1.4.4.4-.3.8-.5 1.2-.6l-.4-.2h-.4M26.9 22.9l8.4-4.6-8.2-4.4c1.2 3.6.7 6.7-.2 9M15.3 7.8c-.3 0-.7.1-1 .2 1.1.6 2.3 1.5 3.4 2.5.8-.2 1.5-.4 2.3-.5l-4.1-2.2h-.6M19.3 25.1c-4.7.7-9.6-1.5-12.5-5.2-1.1 1.3-1.8 2.6-2.1 3.8-.1.5-.1.9-.1 1.2.2.3.4.5.6.8.7.8 2.6 2.8 5.7 3.7 1.1.3 2.1.4 3.1.5l9-4.9c.1-.4.1-.7.1-1.1-1.1.6-2.4 1-3.8 1.2M7 17.1c1.1-1 2.3-2.1 3.6-3-.1-.2-.2-.4-.2-.6-.6-1.7-.4-3.4.4-4.8.1-.1.1-.2.2-.2-1-.3-1.9-.4-2.7-.3-.5.5-.9 1-1.3 1.6-.6 1.1-1 2.3-1 3.5 0 .9.2 1.8.5 2.7.1.3.3.7.5 1.1M12 12.9c0 .1.1.1.1.2 1.2-.7 2.5-1.4 3.8-1.9-1.1-.9-2.2-1.6-3.2-2.1-.1.2-.3.3-.4.5-.6 1-.7 2.2-.3 3.3zM18.9 17c.6-.1 1.2-.3 1.6-.5-.9-1.4-1.9-2.7-3.2-3.9-.2.1-.4.2-.6.2-1.3.5-2.5 1.1-3.7 1.9 1.4 1.6 3.7 2.6 5.9 2.3M7.2 6.4c.9-.5 1.8-1 3.4-1.4L3.2 1v9c1.8-2.3 3.7-3.5 4-3.6M21.9 15.2c.6-.9.7-2.1.3-3.3 0-.1-.1-.2-.1-.3-.8 0-1.8.1-2.8.4.9 1 1.8 2.1 2.6 3.2"
          ></path>
          <path
            class="st0"
            d="M19 23.4c1.5-.2 2.8-.7 3.8-1.5-.3-1.2-.8-2.6-1.5-3.9-.7.4-1.4.6-2.3.8-2.8.4-5.8-.9-7.6-3.1-1.3.9-2.6 2-3.6 3 2.6 3.2 7.1 5.3 11.2 4.7M5.7 18.4c-.3-.6-.6-1.2-.9-1.9-.6-1.8-.7-3.7-.3-5.4-.3.4-.6.9-.9 1.4-.1.3-.3.6-.4.8v8.8c.6-1.1 1.4-2.4 2.5-3.7M25.3 22l-.6.6c0 .2 0 .4.1.7.2-.4.4-.8.5-1.3M3.9 26.9c-.2-.3-.4-.5-.6-.7v9.5l8-4.3c-.3-.1-.6-.1-.9-.2-3.6-1.1-5.8-3.4-6.5-4.3M27.8 29.7c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2m.4 0c0 .9.7 1.6 1.6 1.6s1.6-.7 1.6-1.6-.7-1.6-1.6-1.6c-.9 0-1.6.7-1.6 1.6m2.7 1.1h-.4l-.7-1h-.4v1h-.3v-2.2h1c.2 0 .4 0 .6.1s.3.3.3.5c0 .4-.3.6-.7.6l.6 1zm-1.1-1.3c.3 0 .8.1.8-.3 0-.3-.3-.3-.5-.3h-.6v.7h.3v-.1z"
          ></path>
        </svg>
      </div>
      <a (click)="login()">Sign In</a>
    </div>
  </div>
  <div class="container-fluid wrap" id="ter-main-navbar">
    <div class="ter-navbar__brand">
      <a (click)="showVideoComponent()" title="Home" rel="home">
        <img
          src="../../../assets/images/MEP-Trimble-Analytics-Logo-RGB-Horizontal-v01-r00.svg"
          alt="Construction Analytics Home"
        />
      </a>
    </div>
    <div class="hamburger">
      <div class="btn-group" dropdown>
        <button
          id="button-basic"
          dropdownToggle
          type="button"
          class="btn btn-lg btn-icon-only btn-text-dark"
          aria-controls="dropdown-basic"
        >
          <em class="modus-icon material-icons">menu</em>
        </button>
        <ul
          id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu dropdown-menu-right"
          role="menu"
          aria-labelledby="button-basic"
        >
          <li role="menuitem">
            <a
              class="dropdown-item"
              routerLink="/features/contractor"
              title="Learn about construction analytics solutions for Contractors"
              >Contractor</a
            >
          </li>
          <li role="menuitem">
            <a
              class="dropdown-item"
              routerLink="/features/bpm"
              title="Learn about construction analytics solutions for Manufacturers"
              >Manufacturer</a
            >
          </li>
          <li role="menuitem">
            <a
              class="dropdown-item"
              routerLink="/features/distributor"
              title="Learn about construction analytics solutions for Distributors"
              >Distributor</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="ter-navbar__nav" style="background: transparent">
      <ul class="menu nav ter-navbar__menu" style="float: right">
        <li class="ter-navbar__menu-item">
          <a
            (click)="showManufacturerFeature()"
            routerLinkActive="active"
            class="nav-nopad"
            title="Learn about construction analytics solutions for Manufacturers"
            >Manufacturer</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div
    id="ter-titlebar"
    class="ter-titlebar"
    style="margin: 0 auto; width: 100%"
  >
    <button class="btn btn-primary account-button">
      <a
        href="https://go.trimble.com/construction-analytics-general-contact-us.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        Activate Your Free Account
      </a>
    </button>
  </div>
</div>
