/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { StateService } from '../../../../src/app/services/state.service';
import { IdentityService } from '../../../../src/app/services/identity.service';
import { Subscription } from 'rxjs';
import { ProfilesService } from '@hcworkspace/analytics-portal/data-sharing';
import { StorageService } from 'apps/analytics-portal/src/app/services/storage.service';
import { LifeCycleService } from 'apps/analytics-portal/src/app/services/life-cycle.service';
import { CustomerRole } from '../../models/customer-role';
import { CustomerService } from '../../services/customer.service';


@Injectable()
export class AuthenticationGuard  implements OnDestroy{
  public subscriptions: Subscription[] = [];

  constructor(
    public stateService: StateService,
    private identityService: IdentityService,
    public profilesService: ProfilesService,
    private router: Router,
    public storageService: StorageService,
    public lifeCycleService: LifeCycleService,
    public customerService: CustomerService) { }

  ngOnDestroy() {
    this.lifeCycleService.unsubscribeSubscriptions(this.subscriptions);
  }
  async canActivate(): Promise<boolean>  {
    // Unauthenticated users
    if (!this.stateService.hasValidToken) {
      this.stateService.setHideHeaderFooterAndNav(true);
      this.identityService.navigateToTIDLogin(location.pathname);
      return false;
    }
    // Users without license
    if(this.stateService.currentUser.customerId < 0 -1){
      return false;
    }
    // // Contractor users should only access the contractor page
    // if(this.customerService.getCustomerRoleFromString(
    //  this.stateService.currentUser.customerRole)
    //  === CustomerRole.contractor &&
    //   !this.storageService.getRedirectUrl().includes('contractor/')) {
    //     // set before redirect so the guard knows they're going to the right place
    //     this.storageService.setRedirectUrl('/contractor/redirected');
    //     this.router.navigateByUrl('/contractor/redirected');
    //     return false;
    // }
    // Users who haven't accepted TOS
    else if(!this.stateService.currentUser.tosAccepted &&
      //unless they are just here for the data sharing component
      !this.storageService.getRedirectUrl().includes('data-sharing')) {
      this.router.navigateByUrl('/terms');
      return false;
    }
    return true;
  }
}
