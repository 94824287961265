/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { ForecastRequestModel } from '../../models/requests/forecast-request';
import { ErrorService } from '../../services/error.service';
import { ReportsService } from '../../services/reports.service';
import { StateService } from '../../services/state.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-forecast-selection',
    templateUrl: './forecast-selection.component.html',
    styleUrls: ['./forecast-selection.component.scss']
})

export class ForecastSelectionComponent implements OnInit, OnDestroy {
    @Output() embedReport: EventEmitter<ForecastRequestModel> = new EventEmitter();

    public forecastReq: ForecastRequestModel = new ForecastRequestModel();
    public forecastForm: FormGroup;

    public itemList: string[] = [];
    public totalItems = 0;
    public maxItems = 101; /* REPLACE WITH TOTAL ITEMS FROM DB */
    public currentPage = 1;
    public maxPage = 2; /* REPLACE WITH TOTAL PAGES FROM API */
    public loading$ = new BehaviorSubject<boolean>(false);
    public lastSearch$ = new BehaviorSubject<string>(null);

    constructor(
        public stateService: StateService,
        public errorService: ErrorService,
        public reportService: ReportsService
    ) { }

    ngOnInit(): void {
        // Load search results on a debounce,
        // lastSearch is the most recently entered text.
        // Load search results on a debounce
        this.lastSearch$.pipe(
          debounceTime(500),
          distinctUntilChanged()
        ).subscribe(((text) => {
          this.itemList = [];
          return this.getCommoditiesForDropdown(1);
         }));

        this.forecastForm = new FormGroup({
            // forecastAggregation: new FormControl(this.forecastReq.userInputType, [Validators.required]),
            forecastProductOrCommodity: new FormControl(null, [Validators.required]),
            forecastDateRange: new FormControl(
                [new Date('2016-06-02'), new Date(moment().format('YYYY-MM-DD'))],
                [Validators.required]),
            forecastPredictionRange: new FormControl(null, [Validators.required])
        });
    }

    ngOnDestroy(): void {
        this.forecastForm = null;
    }

    submit(): void {
        this.forecastReq.forecastType = 'COMMODITY';
        this.forecastReq.forecastValue = this.forecastForm.value.forecastProductOrCommodity;
        this.forecastReq.startDate = this.forecastForm.value.forecastDateRange[0];
        this.forecastReq.endDate = this.forecastForm.value.forecastDateRange[1];
        this.forecastReq.predictionRange = this.forecastForm.value.forecastPredictionRange;

        this.embedReport.emit(this.forecastReq);
    }

    /**
     * Allows filtering of the commodities call.
     *
     * @param e
     * @memberof ForecastSelectionComponent
     */
    addFilter(e) {
        this.resetDropdown();
        this.lastSearch$.next(e.term);
    }

    clearSearch() {
      // clear the search text and load the first page of all commodities
      this.resetDropdown();
      this.lastSearch$.next(null);
      this.getCommoditiesForDropdown(1);
    }

    resetDropdown(){
      this.itemList = [];
      this.totalItems = 0;
      this.maxItems = 101;
      this.currentPage = 0;
      this.maxPage = 2;
    }

    onScrollToEnd(){
        this.loadMore();
    }

    /**
     * ng-select virtual scroll function
     *
     * @param end
     * @memberof ForecastSelectionComponent
     */
    onScroll({ end }) {
      // if we are 20 items from the bottom of the page, load more
        if(end + 20 >= this.totalItems && this.currentPage !== 0) {
            this.loadMore();
        }
    }

    loadMore() {
        if (this.loading$.value || this.maxItems <= this.totalItems) {
          //don't load more if we're at the bottom or if a call is currently being made
          return;
        }
        this.getCommoditiesForDropdown(this.currentPage + 1);
    }

    getCommoditiesForDropdown(page: number) {
      this.loading$.next(true); // indicates endpoint call is in progress
        return this.reportService.getCommodities(page, 100, this.lastSearch$.value).subscribe(x => {
            if(!x){
              // if the endpoint returns null, the dropdown is empty
              this.totalItems = 0;
              this.maxItems = 0;
              this.currentPage = 0;
              this.maxPage = 2;
              this.itemList = [];
            }
            else {
              this.totalItems += x.itemCount;
              this.maxItems = x.totalItems;
              this.currentPage = x.pageNumber;
              this.maxPage = x.totalPages;

              if (this.itemList.length > 0) {
              // if there are items loaded, add the result to the end of the list
                  this.itemList = [...this.itemList, ...x.items];
              }
              else {
                  this.itemList = x.items;
              }
            }

            this.loading$.next(false); //finished
        });
    }
}
