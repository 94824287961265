import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public hotToastService: HotToastService) { }

  public success(message: string) {
    this.hotToastService.success(message, {
      className: 'alert alert-success fade show',
      iconTheme: {
        primary: '#4A821F',
        secondary: '#FFFAEE',
      },
      style: {
        color: '#4A821F'
      },
      duration: 7000
    });
  }

  public error(message: string, autoClose: boolean = false) {
    this.hotToastService.warning(message, {
      className: 'alert alert-danger fade show',
      iconTheme: {
        primary: '#C81922',
        secondary: '#FFFAEE',
      },
      style: {
        color: '#C81922'
      },
      autoClose: autoClose
    });
  }
}
