import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { models } from 'powerbi-client';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CustomerRole } from '../models/customer-role';
import { CustomerViewModel } from '../models/customer-view-model';
import { EmbedReportViewModel } from '../models/embed-report-view-model';
import { EmbedTokenViewModel } from '../models/embed-token-view-model';
import { ReportViewModel } from '../models/report-view-model';
import { UserViewModel } from '../models/user-view-model';
import { CustomerService } from '../services/customer.service';
import { CustomerFeatureType } from 'apps/analytics-portal/src/app/models/customer-feature-type';
import { ErrorService } from '../services/error.service';
import { InternalNotificationService } from '../services/internal-notification.service';
import { LogService } from '../services/log.service';
import { ModalService } from '../services/modal.service';
import { ReportsService } from '../services/reports.service';
import { StateService } from '../services/state.service';
import { ReportsBaseComponent } from './reports-base.component';

@Component({
  selector: 'app-report-tabs',
  templateUrl: './tabbed-reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class TabbedReportsComponent extends ReportsBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('reportViews', { read: ElementRef }) reportViews: QueryList<ElementRef>;
  @HostBinding('class') class = 'modus-content-rows';

  public reports: ReportViewModel[] = [];

  public showInvalidCustomerError = false;
  public supportEmailAddress = environment.supportEmailAddress;
  public bsModalRef: BsModalRef;
  public reportBookmarkName = '';
  public reportTabs: {
    title: string;
    name: string;
    disabled: string;
    report: EmbedReportViewModel;
    element: ElementRef;
    tooltip: string;
    dormant: string;
  }[];
  public activeTab: any;
  public storedTab: TabDirective;
  public resizeTimer: any;
  private enabledReportSetup =[
    {
      title: 'Ads',
      name: 'ads',
      disabled: null,
      report: null,
      element: null,
      tooltip: 'View the performance and engagement of your ads',
      dormant: null,
      featuretypes: ['premium','advertisement'],
    },
    {
      title: 'Item Search',
      name: 'itemSearch',
      disabled: null,
      report: null,
      element: null,
      tooltip: 'See who and what type of content your customers are searching',
      dormant: null,
      featuretypes: ['premium', 'advertisement'],
    },
    {
      title: 'Downloads',
      name: 'downloads',
      disabled: null,
      report: null,
      element: null,
      tooltip: 'See who is downloading your content and where they are doing it from',
      dormant: null,
      featuretypes: ['premium'],
    },
    /*
    {
      title: 'Estimates',
      name: 'estimates',
      disabled: null,
      report: null,
      element: null,
      tooltip: 'See where and when your customers are using your content prior to winning their jobs',
      dormant:
        'The next version of Construction Analytics is under development. ' +
        'The tool will show Contractor content usage through the Trimble ecosystem, including our Estimation products.  ' +
        'Please contact your account representative if you are interested in providing guidance on how this tool can ' +
        'provide the maximum value to your business.',
        featuretypes:['premium'],
    },
    */
    {
      title: 'Quotes',
      name: 'quotes',
      disabled: null,
      report: null,
      element: null,
      tooltip: 'Understand who is quoting your content and what they are quoting',
      dormant: null,
      featuretypes: ['premium'],
    },
    {
      title: 'Purchase Requests',
      name: 'purchaseRequests',
      disabled: null,
      report: null,
      element: null,
      tooltip: 'View what content your customers are requesting to purchase',
      dormant: null,
      featuretypes: ['premium'],
    },
    {
      title: 'Models',
      name: 'models',
      disabled: null,
      report: null,
      element: null,
      tooltip:
        'View what content your customers are using to create their 3D models and where they are doing it from',
      dormant: null,
      featuretypes: ['premium'],
    },
  ];
  private disabledReportSetup = [
    {
      title: 'Ads',
      name: 'ads',
      disabled:
        'This report tracks the performance of ads you can purchase on our Trade Service' +
        ' content pricing platform. Purchasing advertisements is available to premium users.',
      report: null,
      element: null,
      tooltip: 'Requires Premium',
      dormant: null,
      featuretypes: ['basic'],
    },
    {
      title: 'Item Search',
      name: 'itemSearch',
      disabled:
        'This report shows how, when, and where contractors are searching for your content ' +
        'data in the Trade Services database to implement in pre-construction.',
      report: null,
      element: null,
      tooltip: 'Requires Premium',
      dormant: null,
      featuretypes: ['basic'],
    },
    {
      title: 'Downloads',
      name: 'downloads',
      disabled:
        'This report shows how, when, and where contractors are downloading your content data' +
        ' into their estimation products for estimation.',
      report: null,
      element: null,
      tooltip: 'Requires Premium',
      dormant: null,
      featuretypes: ['basic', 'advertisement'],
    },
    /*
    {
      title: 'Estimates',
      name: 'estimates',
      disabled:
        'This report shows how, when, and where contractors are using your content' +
        ' data in their estimation software.',
      report: null,
      element: null,
      tooltip: 'Requires Premium',
      dormant: null,
      featuretypes: ['basic', 'advertisement'],
    },
    */
    {
      title: 'Quotes',
      name: 'quotes',
      disabled:
        'This report shows how, when, and where contractors are requesting updated ' +
        'pricing from distributors to use in their estimation and bidding process.',
      report: null,
      element: null,
      tooltip: 'Requires Premium',
      dormant: null,
      featuretypes: ['basic', 'advertisement'],
    },
    {
      title: 'Purchase Requests',
      name: 'purchaseRequests',
      disabled: 'This report shows how, when, and where contractors are requesting ' + 'to purchase your content.',
      report: null,
      element: null,
      tooltip: 'Requires Premium',
      dormant: null,
      featuretypes: ['basic', 'advertisement'],
    },
    {
      title: 'Models',
      name: 'models',
      disabled:
        'This report shows how, when, and where' +
        ' contractors are using your content data in their modeling software.',
      report: null,
      element: null,
      tooltip: 'Requires Premium',
      dormant: null,
      featuretypes: ['basic', 'advertisement'],
    },
  ];

  constructor(
    public reportsService: ReportsService,
    public customerService: CustomerService,
    public logService: LogService,
    public stateService: StateService,
    public modalService: ModalService,
    public errorService: ErrorService,
    public internalNotificationService: InternalNotificationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(reportsService, logService, stateService, modalService, errorService);
  }

  public get reportIdForTab() {
    return environment.reportIds[this.stateService.effectiveRole.toLowerCase()][this.activeTab.name];
  }

  public get isUserInternal(): boolean {
    return this.user.customerRole.toLowerCase() === CustomerRole.internal.toLowerCase();
  }

  public get isChildCustomerSelected(): boolean {
    return this.stateService.effectiveChildCustomer.id !== this.stateService.effectiveParentCustomer.id;
  }

  public cap(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public handleWindowResize(): void {
    const newLayoutType = this.getReportLayoutType(window.innerWidth);
    const changeReport = newLayoutType !== this.reportLayoutType;
    this.reportLayoutType = newLayoutType;

    if (changeReport) {
      this.displayReport();
    }
  }

  @HostListener('window:resize', ['$event']) onResize() {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.handleWindowResize();
    }, 500);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    if (this.user.customerId < 0) {
      this.showInvalidCustomerError = true;
    } else {
      this.createTabs();
      //sets page to ads to start
      this.activeTab = this.reportTabs.find((obj) => obj.name === this.reportTabs[0].name);

      this.subscriptions.push(
        this.reportLoaded.subscribe((loaded) => {
          if (loaded) {
            this.setTokenExpirationListener(-1) /* minutes before expiration */;
          }
        })
      );
    }
  }

  ngAfterViewInit(): void {
    this.addElementsToTabs();
    let validName = false;
    for (const link of this.reportTabs) {
      if (location.pathname === '/reports/' + link.name) {
        validName = true;
      }
    }
    if (location.pathname === '/reports' || !validName) {
      this.routeChange(this.reportTabs[0].name);
    }
  }

  public createTabs() {
    const customerRole = this.customerService.getCustomerRoleFromString(this.stateService.effectiveRole);
    switch (customerRole) {
      case CustomerRole.manufacturer:
        this.createManufacturerTabs();
        break;
      case CustomerRole.contractor:
        this.createContractorTabs();
        break;
      case CustomerRole.internal:
        this.createInternalTabs();
        break;
      default:
        this.showError = true;
    }
  }

  /**
   * To add a new report, add it to the array for its role here, and match the "name" to the name for the report id in environment.ts
   * disabled: Displays the tab as greyed out, and displays the text rather than a report.
   * dormant: Tab appears normal but displays "under construction" symbol along with the text.
   * report: Power BI embedReport details.
   * element: The html element where the tab's report will be displayed.
   */
  public createManufacturerTabs() {
    //Content Health is always enabled for all user types
    this.reportTabs = [
      {
        title: 'Content Health',
        name: 'contentHealth',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Check the quality of your content data and its performance in searches',
        dormant: null,
      },
    ];

    var enabled = this.enabledReportSetup.filter((x) => x.featuretypes.includes(this.user.customerFeatureType.toLowerCase()));
    var disabled = this.disabledReportSetup.filter((x) => x.featuretypes.includes(this.user.customerFeatureType.toLowerCase()))

    if (this.user.customerFeatureType === CustomerFeatureType.premium) {
      this.reportTabs = this.reportTabs.concat(enabled);
    } else if (this.user.customerFeatureType === CustomerFeatureType.advertisement) {
      this.reportTabs = enabled;
    } else {
      this.reportTabs = this.reportTabs.concat(enabled).concat(disabled);
    }
  }

  public createContractorTabs() {
    this.reportTabs = [
      {
        title: 'SysQue',
        name: 'sysque',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to SysQue Data',
        dormant: null,
      },
      {
        title: 'FabShop',
        name: 'fabShop',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to FabShop Data',
        dormant: null,
      }
    ];
    //temporary for product evaluation
    if(!environment.production){
      this.reportTabs.push(
        {
          title: 'MEPcontent',
          name: 'mepcontent',
          disabled: null,
          report: null,
          element: null,
          tooltip: '',
          dormant: null,
        })
    }
  }

  public createInternalTabs() {
    this.reportTabs = [
      {
        title: 'Trimble Construction Content',
        name: 'trimbleConstructionContent',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Trimble Construction Content',
        dormant: null,
      },
      {
        title: 'Trimble Components',
        name: 'trimbleComponents',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Trimble Components',
        dormant: null,
      },
      {
        title: 'Price History',
        name: 'priceHistory',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Price History',
        dormant: null,
      },
      {
        title: 'Account Utility',
        name: 'accountUtility',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Account Utility',
        dormant: null,
      },
      {
        title: 'Site Analytics',
        name: 'siteAnalytics',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Site Analytics',
        dormant: null,
      },
      {
        title: 'Login Data',
        name: 'loginData',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Login Data',
        dormant: null,
      },
      {
        title: 'SysQue Activity',
        name: 'sysqueActivity',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to SysQue Activity',
        dormant: null,
      },
      {
        title: 'Raw Contribution',
        name: 'rawContribution',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Raw Contribution',
        dormant: null,
      },
      {
        title: 'Manufacturer Gap Analysis',
        name: 'manufacturersGap',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Manufacturer Gap Analysis',
        dormant: null,
      },
      {
        title: 'Manufacturer Segmentation',
        name: 'manufacturerSegmentation',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Manufacturer Segmentation',
        dormant: null,
      },
      {
        title: 'QBR',
        name: 'qbr',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to QBR',
        dormant: null,
      },
      {
        title: 'TC1 Seller Map',
        name: 'sellerMap',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to TC1 Seller Map',
        dormant: null,
      },
      {
        title: 'Contractor Prioritization',
        name: 'contractorPrioritization',
        disabled: null,
        report: null,
        element: null,
        tooltip: 'Click to go to Contractor Prioritization',
        dormant: null,
      },
    ];
  }

  public addElementsToTabs() {
    this.reportViews.forEach((reportElement, index) => {
      this.reportTabs[index].element = reportElement;
    });
  }

  public displayReport(callback: any = null): void {
    if (this.activeTab.dormant !== null || this.activeTab.disabled !== null) {
      return;
    }
    this.reportLayoutType = this.getReportLayoutType(window.innerWidth);
    if (!this.loading && !this.activeTab.report) {
      this.loading = true;
      this.stateService.showModalProgress('Loading data...');
    }

    if (callback) {
      callback();
    }
    this.reportLoaded.next(false);
    this.subscriptions.push(
      this.stateService.settingClaims.subscribe((setting) => {
        if (!setting) {
          if (this.activeTab.report !== null) {
            return;
          }
          this.activeTab.report = true;
          if (this.isUserInternal) {
            this.embedPowerBIReportInternal(this.reportIdForTab, this.activeTab.element.nativeElement);
          } else if (this.isChildCustomerSelected) {
            this.embedPowerBIReportChild(this.reportIdForTab, this.activeTab.element.nativeElement);
          } else {
            this.embedPowerBIReport(this.reportIdForTab, this.activeTab.element.nativeElement);
          }
        }
      })
    );
  }

  public setTokenExpirationListener(minutesToRefresh = -1): void {
    const currentTime = Date.now();
    const expiration = Date.parse(this.embedToken.expiration);
    const safetyInterval = minutesToRefresh * 60 * 1000;

    // How long until refresh
    const timeout = expiration - currentTime - safetyInterval;

    // if token already expired, generate new token and set the access token

    if (timeout <= 0) {
      this.activeTab.report = null;
      this.displayReport(() => {
        this.logService.writeLine('displayReport() called to update the expired embed token');
      });
    } else {
      this.logService.writeLine(
        'Report Embed Token will be updated in ' +
          timeout +
          ' milliseconds. (' +
          Math.round(timeout / 60000) +
          ' minutes)'
      );

      this.expirationTimers.push(
        setTimeout(() => {
          // whichever timer runs out first will reset everything
          this.expirationTimers.forEach((e) => clearInterval(e));
          this.reportTabs.forEach((t) => {
            if (t.report) {
              this.reportsService.resetHtmlElement(t.element.nativeElement);
            }
            t.report = null;
          });

          this.displayReport(() => {
            this.logService.writeLine(['displayReport() called at the requested interval', timeout]);
          });
        }, timeout)
      );
    }
  }

  public sendMarketingEmail(tabName: string) {
    this.subscriptions.push(
      this.internalNotificationService.sendRequestPremium(tabName).subscribe(
        () => {},
        (error) => {
          this.errorService.navigateToErrorPage('Error sending premium account request');
        }
      )
    );
  }

  public async routeChange(tabName: string): Promise<any> {
    this.activeTab = this.reportTabs.find((obj) => obj.name === tabName);
    this.router.navigate([tabName], { relativeTo: this.route });

    // displays report on first time
    if (!this.activeTab.report) {
      this.displayReport();
    }
  }
}
