/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { CustomerRole } from "../models/customer-role";
import { ForecastRequestModel } from "../models/requests/forecast-request";
import { CustomerService } from "../services/customer.service";
import { StateService } from "../services/state.service";
import { ToastService } from "../services/toast.service";
import { ForecastReportComponent } from "./report/forecast-report.component";

@Component({
    selector: 'app-forecast',
    templateUrl: './forecast.component.html',
    styleUrls: ['./forecast.component.scss']
})

export class ForecastComponent implements OnInit {
    @ViewChild('forecast') forecast: ForecastReportComponent;
    public forecastReq: ForecastRequestModel = new ForecastRequestModel();

    public isElligibleForecastCustomer = false;

    constructor(
        public customerService: CustomerService,
        public stateService: StateService,
        public toastService: ToastService
    ) { }

    ngOnInit(): void {
        const customerRole = this.customerService.getCustomerRoleFromString(this.stateService.currentUser.customerRole);
        
        if (customerRole === CustomerRole.internal) {
            this.isElligibleForecastCustomer = true;
        }
    }

    public refreshReport(event) {
        // Refresh report in <app-forecast-report>
        if (this.forecastReq == null || this.forecastReq.forecastValue != event.forecastValue
            || this.forecastReq.startDate != event.startDate || this.forecastReq.endDate != event.endDate
            || this.forecastReq.predictionRange != event.predictionRange)
        {
            this.forecastReq = Object.assign(this.forecastReq, event);

            this.forecast.updateForecastRequest = Object.assign(this.forecastReq, event);
        }
    }
}