import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { IdentityService } from '../../../../src/app/services/identity.service';
import { ModalService } from '../../../../src/app/services/modal.service';
import { StateService } from '../../../../src/app/services/state.service';

@Component({
  selector: 'app-refresh-token',
  templateUrl: './refresh-token.component.html',
  styleUrls: ['./refresh-token.component.scss']
})
export class RefreshTokenComponent implements OnInit {
  @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();

  public time: number;
  public error = null;
  public buttonText = 'Continue Session';
  public buttonEnabled = true;
  public timer;

  constructor(
    public modalService: ModalService,
    public identityService: IdentityService,
    public stateService: StateService) {}

  public millisToMinutesAndSeconds(millis: number) {
    const stringMinutes = Math.floor(moment.duration(millis).asMinutes()).toString();
    const seconds = (Math.floor(moment.duration(millis).asSeconds()) - moment.duration(stringMinutes, 'minutes').asSeconds());
    let stringSeconds = seconds.toString();
    if (seconds < 10) {
      stringSeconds = '0' + stringSeconds;
    }

    return stringMinutes+':'+stringSeconds;
  }

  public async refreshToken() {
    this.buttonText = 'Continuing...';
    this.buttonEnabled = false;
    clearInterval(this.timer);
    this.refreshEvent.emit();
  }

  public handleInterval(): void {
    this.time -= 1000;
    if (this.time <= 0) {
      this.modalService.hideAll();
      this.identityService.logout();
    }
  }

  ngOnInit(): void {
    this.time = moment(this.stateService.tokenTimeout).diff(moment());
    this.timer = setInterval(() => {
      this.handleInterval();
    }, 1000);
  }
}
