<div class="modus-content-columns">
  <div class="modus-content">
    <div class="cards-container">
      <div class="card border-dark shadow">
        <img
          class="card-img-top"
          src="../../assets/images/CardReports.svg"
          alt="Reports"
        />
        <div class="card-body">
          <h3 class="card-title">Reports</h3>
          <p class="card-text">View Analytics Insights for your portfolio</p>
        </div>
        <div class="card-footer text-right">
          <a
            routerLink="/reports"
            id="reportsLink"
            class="card-link btn btn-primary"
            >Go to Reports</a
          >
        </div>
      </div>
      <div class="card border-dark shadow" *ngIf="showMessagesFeature">
        <img
          class="card-img-top"
          src="../../assets/images/CardMessages.svg"
          alt="Messages"
        />
        <div class="card-body">
          <h3 class="card-title">Messages</h3>
          <p class="card-text">
            Communicate with your current and future partners.
          </p>
        </div>
        <div class="card-footer text-right">
          <a routerLink="/messages" class="card-link btn btn-primary"
            >Go to Messages</a
          >
        </div>
      </div>

      <div class="card border-dark shadow">
        <img
          class="card-img-top"
          src="../../assets/images/CardHelp.svg"
          alt="Help"
        />
        <div class="card-body">
          <h3 class="card-title">Help</h3>
          <p class="card-text">See our recent release notes and user manual</p>
        </div>
        <div class="card-footer text-right">
          <a routerLink="/help" class="card-link btn btn-primary">Go to Help</a>
        </div>
      </div>

      <div class="card border-dark shadow">
        <img
          class="card-img-top"
          src="../../assets/images/CardProfile.svg"
          alt="My Profile"
        />
        <div class="card-body">
          <h3 class="card-title">My Profile</h3>
          <p class="card-text">My user information</p>
        </div>
        <div class="card-footer text-right">
          <a class="card-link btn btn-primary" (click)="openModalProfile()"
            >Show My Profile</a
          >
        </div>
      </div>
      <div class="card border-dark shadow">
        <img
          class="card-img-top"
          src="../../assets/images/CardAbout.svg"
          alt="About"
        />
        <div class="card-body">
          <h3 class="card-title">About</h3>
          <p class="card-text">
            Version information about Construction Analytics
          </p>
        </div>
        <div class="card-footer text-right">
          <a class="card-link btn btn-primary" (click)="openModalAbout()"
            >Show About Info</a
          >
        </div>
      </div>
      <div class="card border-dark shadow">
        <img
          class="card-img-top"
          src="../../assets/images/CardTerms.svg"
          alt="Terms of Service"
        />
        <div class="card-body">
          <h3 class="card-title">Terms of Service</h3>
          <p class="card-text">Terms of Service for Construction Analytics</p>
        </div>
        <div class="card-footer text-right">
          <a class="card-link btn btn-primary" (click)="openModalTerms()"
            >Show Terms</a
          >
        </div>
      </div>
      <div class="card border-dark shadow">
        <img
          class="card-img-top"
          src="../../assets/images/CardProfile.svg"
          alt="Terms of Service"
        />
        <div class="card-body">
          <h3 class="card-title">Licensing</h3>
          <p class="card-text">Manage your product licensing</p>
        </div>
        <div class="card-footer text-right">
          <a class="card-link btn btn-primary" href="https://meplicensing.trimble.com" target="_blank"
            >Go to Portal</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
