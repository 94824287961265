<div class="container" id="refreshTokenModal">
  <h3>
    Your user session is about to expire! Would you like to continue your
    session?
  </h3>
  <p>
    (if no action is taken, you will be logged out automatically in
    {{ millisToMinutesAndSeconds(time) }})
  </p>
  <button
    type="button"
    class="btn btn-primary"
    id="continueSessionButton"
    (click)="refreshToken()"
    [disabled]="!buttonEnabled"
  >
    {{ buttonText }}
  </button>
</div>
