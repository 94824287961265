


  <div class="modus-toolbar">
    <h4 id="reportsTitle">Reports</h4>
    </div>

  <div id="tab-wrapper" *ngIf="!showInvalidCustomerError && !showError">
    <tabset>
      <ng-container *ngFor="let template of reportTabs">
        <tab
        #tab="routerLinkActive"
        tooltip-placement="top"
        (selectTab)="routeChange(template.name)"
        [active]="tab.isActive"
        [routerLink]="[template.name]"
        routerLinkActive="active"
        customClass="{{template.disabled? 'premium-only' : ''}}">
        <ng-template tabHeading>
          <span class="tab-title"
            [id]="'reportsTab' + cap(template.name)"
            tooltip="{{template.tooltip}}">
              {{template.title}}
            </span>
        </ng-template>
          <div #reportViews id="{{template.name}}" class="reportView">
            <div *ngIf="template.dormant !== null" class="modus-content p-4">
              <em class="modus-icon material-icons md-48">construction</em>
              <p></p>
              {{template.dormant}}
            </div>
            <div *ngIf="template.disabled !== null" class="modus-content p-4">
              <p></p>
              {{template.disabled}}
              <p></p>
              <p>Click <a target="_blank" (click)="sendMarketingEmail(template.name)">here</a> to be contacted by an account representative to gain access to the full power of Construction Analytics.</p>
            </div>
          </div>
        </tab>
      </ng-container>
    </tabset>
    </div>


  <div *ngIf="showInvalidCustomerError || showError" class="reportView">
    <div *ngIf="showError" class="error-container">
      <div class="error">
        <div class="error-title">
          <span class="whoops">Whoops!</span>&nbsp;
          <span class="error-title-text">Something Went Wrong.</span>
        </div>
        <div class="error-desc">
          Please refresh your browser or contact our friendly
          <a href="mailto:{{ supportEmailAddress }}">customer support</a>.
        </div>
      </div>
    </div>
    <div *ngIf="showInvalidCustomerError" class="error-container">
      <div class="error">
        <div class="error-title">
          <span class="whoops">Whoops!</span>&nbsp;
          <span class="error-title-text">Looks like your account still needs to be set up!</span>
        </div>
        <div class="error-desc">
          Someone will reach out to you within 2 business days to get you started!
        </div>
      </div>
    </div>
  </div>

