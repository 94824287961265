<div class="modus-content p-4">
  <em (click)="closeAlert()" class="modus-icon material-icons" id="alertButtonClose">close</em>
  <h1 class="alert-name" id="alertName">{{alert.name}}</h1>
  <div class="header-body">
    <p id="alertTextSetup">Create and manage alerts to receive an email notification for product-related activity.
    </p>
    <div>
      <em class="modus-icon material-icons" (click)="editAlert()" id="alertButtonEdit">edit</em>
      <em class="modus-icon material-icons" (click)="deleteAlert()" id="alertButtonDelete">delete</em>
    </div>
  </div>
  <hr>
  <strong id="alertHeaderDetails">Alert Details</strong>
  <p *ngIf="alert.emailSharing !== null">You will receive an email if this alert's <strong>{{triggerType}}</strong> was reached.</p>
  <table>
    <tr class="row-one">
      <th class="card-subtitle-2" id="alertDetailsLabelTrigger">Trigger<hr></th>
      <th class="card-subtitle-2" id="alertDetailsLabelNotifications">Notifications<hr></th>
      <th class="card-subtitle-2" id="alertDetailsLabelEndDate">Email Notifications End Date<hr></th>
    </tr>
    <tr class="row-one">
      <td id="alertDetailsTrigger">{{trigger}}</td>
      <td id="alertDetailsEmails">
        <div class="btn-group" dropdown *ngIf="alert.emailSharing !== null">
        <button id="button-basic alertDetailsButtonEmails" dropdownToggle type="button" class="btn btn-outline-dark dropdown-toggle"
                aria-controls="dropdown-basic">
          Recipients <span class="caret"></span>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
            role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngFor="let email of emails"><a class="dropdown-item disabled" href="#">{{email}}</a></li>
        </ul>
      </div>
      <div *ngIf="alert.emailSharing === null">
        Portal
      </div>
      </td>
      <td id="alertDetailsEndDate">{{formatDate(alert.notificationEndDate)}}</td>
    </tr>
    <tr>
      <th class="card-subtitle-2" id="alertDetailsLabelMonitoring">Monitoring<hr></th>
      <th class="card-subtitle-2" id="alertDetailsLabelDateRange">Content Date Range<hr></th>
      <th *ngIf="isAdType(alert.monitor)" class="card-subtitle-2" id="alertDetailsLabelAdFilters">Data Filters<hr></th>
      <th *ngIf="isSearchType(alert.monitor)" class="card-subtitle-2" id="alertDetailsLabelProductFilters">Products in Filter<hr></th>
      <th *ngIf="isSearchType(alert.monitor)" class="card-subtitle-2" id="alertDetailsLabelRegionFilters">Regions in Filter<hr></th>
    </tr>
    <tr>
      <td id="alertDetailsMonitoring">{{monitor}}</td>
      <td id="alertDetailsDateRange">{{formatDate(alert.dataStartDate)}} - {{formatDate(alert.dataEndDate)}}</td>
      <td *ngIf="isAdType(alert.monitor)" id="alertDetailsAdFilters">{{dataFilter}}</td>
      <td *ngIf="isSearchType(alert.monitor)" id="alertDetailsCatalogs">
        <div class="btn-group" dropdown *ngIf="catalogKeys.length > 0">
          <button id="button-basic alertDetailsButtonCatalogs" dropdownToggle type="button" class="btn btn-outline-dark dropdown-toggle"
                  aria-controls="dropdown-basic">
            Filters Applied <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
              role="menu" aria-labelledby="button-basic">
            <li role="menuitem" *ngFor="let key of catalogKeys"><a class="dropdown-item disabled" href="#">{{key}}</a></li>
          </ul>
        </div>
        <div *ngIf="catalogKeys.length === 0">All</div>
      </td>
      <td *ngIf="isSearchType(alert.monitor)" id="alertDetailsRegions">
        <div class="btn-group" dropdown *ngIf="(regions.length + regionsAll.length) > 0">
          <button id="button-basic alertDetailsButtonRegions" dropdownToggle type="button" class="btn btn-outline-dark dropdown-toggle"
                  aria-controls="dropdown-basic">
            Filters Applied <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
              role="menu" aria-labelledby="button-basic">
            <li role="menuitem" *ngFor="let region of regionsAll"><a class="dropdown-item disabled" href="#"><i>{{region}}</i></a></li>
            <li role="menuitem" *ngFor="let region of regions"><a class="dropdown-item disabled" href="#">{{region}}</a></li>
          </ul>
        </div>
        <div *ngIf="isSearchType(alert.monitor) && (regions.length + regionsAll.length) === 0">All</div>
      </td>

    </tr>
  </table>
  <p><strong id="alertReportHeader">
    Report Details
  </strong><a routerLink="{{reportLink}}" style="float:right" id="alertReportLink">
    More Details
  </a></p>
  <div class="report" #report></div>
</div>
