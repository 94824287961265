import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReportsBaseComponent } from './reports-base.component';
import { ReportsService } from '../services/reports.service';
import { CustomerService } from '../services/customer.service';
import { LogService } from '../services/log.service';
import { StateService } from '../services/state.service';
import { ModalService, ModalSize } from '../services/modal.service';
import { ErrorService } from '../services/error.service';
import { InternalNotificationService } from '../services/internal-notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerRole } from '../models/customer-role';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IdentityService } from '../services/identity.service';
import { CustomerModel } from '../models/customer-model';
import { CustomerSelectComponent } from './contractor/customer-select.component';
import { AccountViewModel } from '../models/responses/account-view-model';
import { CustomerViewModel } from '../models/customer-view-model';


@Component({
  selector: 'app-contractor-reports',
  templateUrl: './contractor-reports.component.html',
  styleUrls: [ './contractor-reports.component.scss']
})

export class ContractorReportsComponent extends ReportsBaseComponent {
  @ViewChild('reportElement', { read: ElementRef }) reportElement: ElementRef;
  public reportName: string = null;
  public expirationTimer: any;
  public modalRef: BsModalRef;
  public selectingCompany = true;

  public errorTitle = 'There was an error.';
  public errorTitleAccess = 'Access denied.';

  public errorMessage = '';
  public errorMessageSnowflake = `You are authorized for this report but your account setup is not complete.
  \n
  To complete your account set-up and gain access to the Trimble Construction One Contractor reports, `+
  `please contact your dedicated account representative.`;
  public errorMessageLicense = `It looks like you're trying to access the Trimble Construction One Contractor reports,`+
  ` but it seems you don't have the right license for this feature just yet.

  To access these reports, please get in touch with your dedicated account representative for more information regarding this product.`;




  constructor(
    public reportsService: ReportsService,
    public customerService: CustomerService,
    public logService: LogService,
    public stateService: StateService,
    public modalService: ModalService,
    public errorService: ErrorService,
    public internalNotificationService: InternalNotificationService,
    public userService: UserService,
    public identityService: IdentityService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(reportsService,
      logService,
      stateService,
      modalService,
      errorService);
    //redirect all users who are not Contractors to /home. If they got this far then the user at the least has a valid TID
    // if(this.stateService.currentUser.customerRole !== '' &&//allow empty role bc if a user's company isn't in db they. won't have a role
    //    this.customerService.getCustomerRoleFromString(this.stateService.currentUser.customerRole)
    // !== CustomerRole.contractor){
    //   this.router.navigateByUrl('/home');
    //   return;
    // }
    //contractor reports don't have any header or footer navigational controls, including logout
    this.stateService.setHideHeaderFooterAndNav(true);

    if (!this.stateService.hasValidToken) {
      this.identityService.navigateToTIDLogin(location.pathname);
      return;
    }

    this.reportName = this.route.snapshot.params['name'];
  }

  //TODO: we need error message for users accessing report which they might not have access to.
  //This is different from general error message such as Page not found or mistyped url

  public get reportId(): string {
    if(!this.reportName){
      this.displayError('Unrecognized report');
      return null;
    }
    switch(this.reportName.toLowerCase()){
      case('sysque'):
        return environment.reportIds.contractor.sysque;
      case('fabshop'):
        return environment.reportIds.contractor.fabShop;
      case('accubid'):
        return environment.reportIds.contractor.accubid;
      case('connect2fab'):
        return environment.reportIds.contractor.connect2fab;
      default:
        this.displayError('Unrecognized report');
        return null;
    }
  }

  public get reportFriendlyName(): string {
    if(!this.reportName){
      this.displayError('Unrecognized report');
      return null;
    }
    switch(this.reportName.toLowerCase()){
      case('sysque'):
        return 'SysQue';
      case('fabshop'):
        return 'Fabshop';
      case('accubid'):
        return 'Accubid';
      case('connect2fab'):
        return 'Connect2Fab';
      default:
        this.displayError('Unrecognized report');
        return null;
    }
  }



  public get reportSecondName(): string {
    if(!this.reportName){
      this.displayError('Unrecognized report');
      return null;
    }
    switch(this.reportName.toLowerCase()){
      case('sysque'):
        return 'Analytics';
      case('fabshop'):
        return 'Analytics';
      case('accubid'):
        return 'Anywhere';
      case('connect2fab'):
        return 'Analytics';
      default:
        this.displayError('Unrecognized report');
        return null;
    }
  }




  async ngOnInit(): Promise<void> {
    if(!this.stateService.currentUser){
      if(this.stateService.hasValidToken){
        this.displayError('Error logging in.');
      }
      else{
        this.displayError('');
      }
      return;
    };
    super.ngOnInit();

    this.userService.getAllCustomers().subscribe((options) => {
      this.stateService.setLicenseAccounts(options);
      if((options.length > 1)){
        // customer select
        const initial = {
          companies: options
        };
        // Show the modal
        this.modalRef = this.modalService.show(CustomerSelectComponent, ModalSize.large, true, initial);
        this.modalRef.content.selectEvent.subscribe((choice: AccountViewModel) => {
          // if a selection is made
          // the license request for EMS users is done in the modal component
          if(!choice.isLicensed){
            this.displayError(this.errorTitleAccess, this.errorMessageLicense);
          }
          else if(choice.snowflakeId <= 0){
            this.displayError(this.errorTitleAccess, this.errorMessageSnowflake);
          }
          else {
            // prevents report loading while modal is open
            this.selectingCompany = false;
          }
          this.modalService.hide(this.modalRef);
        });
      this.modalRef.content.errorEvent.subscribe(() => {
        // if there is an error from the modal component
        this.modalService.hide(this.modalRef);
        this.displayError('There was an error retrieving licenses.');
      });
      }
      else if(options.length < 1){
        this.displayError(this.errorTitleAccess, this.errorMessageLicense);
      }
      else{ //if there is only one option
        this.selectingCompany = false;
        if(options[0].customerSource === 'EMS'){
          // check out a license
          this.identityService.createLicenseRequest(options[0].accountNumber, 'sku')
          .subscribe((res) => {
            if(res){ // if the license checkout succeeded
              //sets claims if snowflake id is ok
              this.identityService.setContractorLicense(options[0]);

              if(options[0].snowflakeId <= 0){
                this.displayError(this.errorTitleAccess, this.errorMessageSnowflake);
              }
            }
            else{
              this.displayError(this.errorTitleAccess, this.errorMessageLicense);
            }
          },
          (error) => {
            this.displayError(this.errorTitleAccess, this.errorMessageLicense);
          });
        }
        else{
          if(!this.showError){
             //sets claims if snowflake id is ok
            this.identityService.setContractorLicense(options[0]);
          }
          if(!options[0].isLicensed){
            this.displayError(this.errorTitleAccess, this.errorMessageLicense);
          }
          else if(options[0].snowflakeId <= 0){
            this.displayError(this.errorTitleAccess, this.errorMessageSnowflake);
          }
        }
      }
    },
    (error) => {
      this.displayError('There was an error retrieving user information.');
    });
  }

  ngAfterViewInit() {
    if(!this.showError){
      this.subscriptions.push(this.stateService.settingClaims.subscribe(
        setting => {
          if(setting || this.selectingCompany){
            return;
          }

          this.embedPowerBIReport(this.reportId, this.reportElement.nativeElement);
        }
      ));


      this.subscriptions.push(this.reportLoaded.subscribe(
        (loaded) => {
          if(loaded){
            this.setTokenExpirationListener(-1) /* minutes before expiration */;
          }
        }
      ));
    }
  }

  public setTokenExpirationListener(minutesToRefresh = 3): void {
    const currentTime = Date.now();
    const expiration = Date.parse(this.embedToken.expiration);
    const safetyInterval = minutesToRefresh * 60 * 1000;

    // How long until refresh
    const timeout = expiration - currentTime - safetyInterval;

    // if token already expired, generate new token and set the access token

    if (timeout <= 0) {
      this.embedPowerBIReport(this.reportId, this.reportElement.nativeElement);
      this.logService.writeLine(['embedPowerBIReport() called at the requested interval', timeout]);

    } else {
      this.logService.writeLine('Report Embed Token will be updated in ' + timeout + ' milliseconds.');

      this.expirationTimer = setTimeout(() => {

      this.reportsService.resetHtmlElement(this.reportElement.nativeElement);

      this.embedPowerBIReport(this.reportId, this.reportElement.nativeElement);
      this.logService.writeLine(['embedPowerBIReport() called at the requested interval', timeout]);

        this.setTokenExpirationListener(-1);
      }, timeout);
    }
  }

  public displayError(title: string, text: string = ''){
    this.showError = true;
    this.errorTitle = title;
    this.errorMessage = text;
  }
}
