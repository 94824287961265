/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomerRole } from '../../../../../src/app/models/customer-role';
import { CustomerService } from '../../../../../src/app/services/customer.service';
import { StateService } from '../../../../../src/app/services/state.service';
import { environment } from '../../../../environments/environment';
import { CustomerFeatureType } from '../../../models/customer-feature-type';

@Component({
  selector: 'app-authenticated-nav',
  templateUrl: './authenticated-nav.component.html',
  styleUrls: ['./authenticated-nav.component.scss']
})
export class AuthenticatedNavComponent implements OnInit, OnDestroy {
  @Output() sidebarOpenEvent = new EventEmitter<boolean>();
  public isElligibleCustomerRoleAlerts = false;
  public isElligibleForecastCustomer = false;
  public isElligibleHomeCustomer = true; //all expect Manufacturer:Adevertisers customers have a home page


  public subscriptions: Subscription[] = [];

  constructor(public stateService: StateService, public customerService: CustomerService) { }

  ngOnInit(): void {
    const customerRole = this.customerService.getCustomerRoleFromString(this.stateService.currentUser.customerRole);

    if (customerRole === CustomerRole.manufacturer && this.stateService.currentUser.customerFeatureType.toUpperCase() !== CustomerFeatureType.advertisement) {
      this.isElligibleCustomerRoleAlerts = true;
    }

    if (customerRole === CustomerRole.manufacturer && this.stateService.currentUser.customerFeatureType.toUpperCase() === CustomerFeatureType.advertisement) {
      this.isElligibleHomeCustomer = false;
    }

    if (customerRole === CustomerRole.internal) {
      this.isElligibleForecastCustomer = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public closeSidebar(): void {
    this.sidebarOpenEvent.emit(false);
  }
}
