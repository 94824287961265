import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IdentityService } from '../../services/identity.service';
import { AccountViewModel } from '../../models/responses/account-view-model';
import { LicenseRequestModel } from '../../models/requests/license-request-model';
import { CustomerRole } from '../../models/customer-role';
import { StateService } from '../../services/state.service';
import { CustomerModel } from '../../models/customer-model';

@Component({
  selector: 'app-customer-select',
  templateUrl: './customer-select.component.html',
  encapsulation: ViewEncapsulation.None
})

export class CustomerSelectComponent implements OnInit {
  @Output() selectEvent: EventEmitter<AccountViewModel> = new EventEmitter<AccountViewModel>();
  @Output() errorEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public companies: AccountViewModel[];
  public selection: AccountViewModel = null;
  public loading = false;

  constructor(
    public identityService: IdentityService,
    public stateService: StateService
  ){

  }
  public ngOnInit(){
  }

  public pick(item: AccountViewModel){
    this.selection = item;
  }

  public select(){
    // call # 2 to licensing
    if(this.selection.customerSource === 'EMS'){
      this.loading = true;
      this.identityService.createLicenseRequest(this.selection.accountNumber, 'sku')
        .subscribe((res) => {
          if(res){ // if checking out license succeeded
            //adding selection to state
            this.companies.filter((acc) => acc.customerName === this.selection.customerName);
            this.selection.isLicensed = true;
            this.companies.push(this.selection);
            this.stateService.setLicenseAccounts(this.companies);
            //sets claims if snowflake id is ok
            this.identityService.setContractorLicense(this.selection);
          }
          else{
            this.selection.isLicensed = false;
          }
          this.selectEvent.emit(this.selection);
        },
        (error) => {
          this.selection.isLicensed = false;
          this.errorEvent.emit(true);
        });
    }
    else{
      this.identityService.setContractorLicense(this.selection);

      this.selectEvent.emit(this.selection);
    }
  }
}
