<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

<ul>
  <li *ngIf="isElligibleHomeCustomer">
    <a
      class="nav-link"
      routerLink="/home"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      (click)="closeSidebar()"
      id="navLinkHome"
    >
      <span class="left-nav-icon"
        id="navIconHome">
        <i class="material-icons">home</i>
      </span>
      Home
    </a>
  </li>

  <li>
    <a
      class="nav-link"
      routerLink="/reports"
      [routerLinkActiveOptions]="{ exact: false }"
      routerLinkActive="active"
      (click)="closeSidebar()"
      id="navLinkReports"
    >
      <span class="left-nav-icon"
        id="navIconReports">
        <i class="custom-icon"></i>
      </span>
      Reports
    </a>
  </li>

  <li *ngIf="isElligibleForecastCustomer">
    <a
      class="nav-link"
      routerLink="/forecast"
      [routerLinkActiveOptions]="{ exact: false }"
      routerLinkActive="active"
      (click)="closeSidebar()"
      id="navLinkForecast"
    >
      <span class="left-nav-icon"
        id="navIconForecast">
        <i class="material-symbols-outlined">show_chart</i>
      </span>
      Forecast
    </a>
  </li>

  <li *ngIf="isElligibleCustomerRoleAlerts">
    <a
      class="nav-link"
      routerLink="/alerts"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      (click)="closeSidebar()"
      id="navLinkAlerts"
    >
      <span class="left-nav-icon"
        id="navIconAlerts">
        <em class="modus-icon material-icons">notifications</em>
      </span>
      Alerts
    </a>
  </li>

  <li>
    <a
      class="nav-link"
      routerLink="/help"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      (click)="closeSidebar()"
      id="navLinkHelp"
    >
      <span class="left-nav-icon"
        id="navIconHelp">
        <em class="modus-icons">help</em>
      </span>
      Help
    </a>
  </li>
</ul>
