import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(private http: HttpClient) { }
  public getVersion() {
    return this.http.get(environment.portalVersionUrl);
  }

  public getUserServiceVersion() {
    return this.http.get(environment.userVersionUrl);
  }

  public getNotificationServiceVersion() {
    return this.http.get(environment.notificationVersionUrl);
  }

  public getReportServiceVersion() {
    return this.http.get(environment.reportsVersionUrl);
  }
}
