
<div class="modal-header" id="profileModal">
  <h5 class="modal-title" id="profileTitle">
    <em class="modus-icon material-icons">person</em>
    <span *ngIf="isSelectingCustomer">
      <a target="_blank" (click)="hideChooseCompany(false, true)">
        My Profile
      </a>
      &gt;
      <em class="modus-icon material-icons" id="profileChooseCompanyModal">business</em>
      Choose a Company
  </span>
    <span *ngIf="!isSelectingCustomer">
      My Profile
    </span>
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="busy" *ngIf="busyMessage">
  <div class="modal-progress">
    <div class="progress-container" id="dataLoadingModal">
      <div>
        <img
          src="../../../assets/images/loader2.gif"
          width="90"
          height="75"
          alt="Loading Animation"
        />
      </div>
      <p class="card-caption">{{ busyMessage }}</p>
    </div>
  </div>
</div>
<div *ngIf="!busyMessage && !isSelectingCustomer" class="modal-body">
  <button *ngIf="hasChildren" type="button" class="btn btn-outline-primary btn-sm"
    id="chooseCustomerButton" (click)="showChooseCompany()">
    Choose Customer...
  </button>
    <h4>{{ user.firstName + " " + user.lastName }}</h4>
    <div class="body-text" id="profileEmail">{{ user.email }}</div>
    <div class="body-text" id="profileRole">{{ user.customerRole }}</div>
    <div class="body-text" id="profileCompanyName">{{ user.customerCName }}</div>
    <form [formGroup]="profileForm">
      <div class="form-group">
        <label for="JobTitle">Job Title</label>
        <input
          formControlName="jobTitle"
          class="form-control"
          id="jobTitleModal"
          placeholder="Job Title"
        />
      </div>
      <div class="form-group">
        <label for="Phone">Phone Number</label>
        <input
          formControlName="phoneNumber"
          class="form-control"
          id="phoneNumberModal"
          placeholder="Phone"
        />
      </div>
    </form>
</div>
<div *ngIf="!isSelectingCustomer">
  <div class="modal-footer" *ngIf="!busyMessage">
    <button type="button" id="profileCloseButton" class="btn btn-outline-dark" (click)="close()">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      id="profileSaveButton"
      (click)="profileSave()"
      [disabled]="(profileForm.invalid || !isDirty()) &&
      (profileForm.invalid && !customerChanged)"
    >
      Save changes
    </button>
  </div>
</div>
<app-choose-company
  *ngIf="isSelectingCustomer"
  (closeEvent)="hideChooseCompany(false)"
  (saveEvent)="hideChooseCompany(true)">
</app-choose-company>
