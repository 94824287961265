<!-- Hero -->
<section class="ter-hero-2 is-inverse-color" style="margin-top: '151px'">
  <figure class="ter-hero-2__hero-image ter-hero-2__hero-image--3x2"
    style="background-image:url(../../../assets/images/Manufacturer_1440x960.jpg);"></figure>
  <figure class="ter-hero-2__hero-image ter-hero-2__hero-image--1x1"
    style="background-image:url(../../../assets/images/Manufacturer_1440x960.jpg);">
  </figure>
  <figure class="ter-hero-2__hero-image ter-hero-2__hero-image--4x3"> <img
      src="../../../assets/images/Manufacturer_1440x960.jpg" width="1440" height="960"
      alt="Contact Sales 4:3" typeof="foaf:Image">

  </figure>
  <div class="ter-hero-2__container">
    <aside class="ter-hero-2__content">
      <div class="ter-header">
        <h1 class="font-size-xl" style="font-weight: 600;">
          Analytics for Manufacturers
        </h1>
      </div>
      <div class="ter-sub-header">
        Insights into your contractor customer needs to serve them in the digital construction workflow.  Build customer loyalty & increase your revenue.
      </div>
    </aside>
  </div>
</section>

<!-- Top Content -->
<section style="background: #FFF;">
  <div class="container-fluid wrap">
    <div class="row" style="text-align: center; padding: 30px 0;">
      <h1 class="font-size-xl"
        style="text-align: center; width:100%; font-weight:600; margin-bottom:20px;">
        Want insights to grow your business?
      </h1>
      <div class="ter-text">
        <p>
            With Construction Analytics, you’re able to tap into how your products are being used by your Customers in pre-construction workflows.
        </p>
        <ul>
            <li>
              Get ahead of the projects and serve your contractor at bid time
            </li>
            <li>
              Get your products installed in more projects
            </li>
            <li>
              Get in front of contractors that need your products at the earliest decision making point
            </li>
            <li>
              Understand important gaps in your distributor coverage
            </li>
            <li>
              See how you are performing against your peers
            </li>
        </ul>
        <p>
            With Construction Analytics, you are reaching into the Contractor’s cubicle to better align your business with your Customers’ needs.
        </p>
        <a class="ter-primary-cta ter-button ter-button--primary--1" href="https://go.trimble.com/construction-analytics-general-contact-us.html" routerLinkActive="active" title=""
          style="margin-top:30px;">Contact Us</a>
      </div>
    </div>
  </div>
</section>
