import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'apps/analytics-portal/src/app/services/modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthExchangeResponseModel } from '../../../models/responses/auth-exchange-response-model.model';
import { UserViewModel } from '../../../../../src/app/models/user-view-model';
import { ErrorService } from '../../../../../src/app/services/error.service';
import { IdentityService } from '../../../../../src/app/services/identity.service';
import { StateService } from '../../../../../src/app/services/state.service';
import { ProfilesService } from '@hcworkspace/analytics-portal/data-sharing';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  public statusMessage = 'Initializing...';
  public modalRef: BsModalRef;
  private authExchangeResponseModel: AuthExchangeResponseModel;

  constructor(
    public route: ActivatedRoute,
    public stateService: StateService,
    public router: Router,
    public identityService: IdentityService,
    public errorService: ErrorService,
    public profilesService: ProfilesService,
    public modalService: ModalService
  ) {
    this.stateService.setHideHeaderFooterAndNav(true);
  }

  ngOnInit(): void {
    if (this.stateService.isAuthenticated) {
      this.router.navigate([this.stateService.previousUrl]);
    }
    this.route.queryParams.subscribe(params => {
      const authCode = params['code'];
      if (!authCode) {
        this.router.navigateByUrl('/');
      }
      this.initSubscriptions(authCode);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.stateService.setHideHeaderFooterAndNav(false);
  }

  public async initSubscriptions(authCode: string): Promise<void> {
    this.stateService.setSettingClaims(true);
    // Exchange auth code and call setUser
    this.statusMessage = 'Validating Authentication...';
    this.subscriptions.push(
      this.identityService.exchangeAuthCode(authCode).subscribe(
        // Auth code exchange succeeded
        async (response: AuthExchangeResponseModel) => {
          this.authExchangeResponseModel = response;
          this.statusMessage = 'Acquiring User Information...';
          this.stateService.setAccessToken(response.accessToken);
          this.stateService.setIdToken(response.idToken);
          await this.identityService.setUser(response);
        },
        // Auth code exchange failed
        error => {
          this.errorService.navigateToErrorPage(`exchangeAuthCode failed with error '${error.error}'`);
        }
      )
    );

    // Listen for a new user and call initializeUser only when we have one
    this.subscriptions.push(
      this.stateService.currentUser$.subscribe((user: UserViewModel) => {
        if (user) {
          this.statusMessage = 'Starting Session...';
          this.stateService.setHideHeaderFooterAndNav(false);
          this.identityService.initializeUser(this.authExchangeResponseModel);
        }
      })
    );


  }
}
