/*
 * All rights reserved.
 *
 * The entire contents of this file is protected by U.S. and
 * International Copyright Laws. Unauthorized reproduction,
 * reverse-engineering, and distribution of all or any portion of
 * the code contained in this file is strictly prohibited and may
 * result in severe civil and criminal penalties and will be
 * prosecuted to the maximum extent possible under the law.
 *
 * CONFIDENTIALITY
 *
 * This source code and all resulting intermediate files, as well as the
 * application design, are confidential and proprietary trade secrets of
 * Trimble Inc.
 */
import { Directive, HostListener, ElementRef} from '@angular/core';

@Directive({
  selector: '[appCustomScroll]',
  exportAs: 'appCustomScroll'
})
export class CustomScrollDirective {
    disableBtn = true;
    top: number;
    offSetHeight: number;
    scrollHeight: number;
    constructor(private eleRef: ElementRef) {}

    @HostListener('scroll') onScrollEvent(event: Event) {
        this.top = this.eleRef.nativeElement.scrollTop;
        this.offSetHeight = this.eleRef.nativeElement.offsetHeight;
        this.scrollHeight = this.eleRef.nativeElement.scrollHeight;
        if (this.top === 0) {
          this.disableBtn = true;
        }
        if (this.top > this.scrollHeight - this.offSetHeight - 1) {
          this.disableBtn = false;
        }
    }
}
