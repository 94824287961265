/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Paginated } from '@hcworkspace/sxui/orders/model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { NgxPowerBiService } from 'ngx-powerbi';
import { IEmbedConfiguration } from 'powerbi-client';
import { EmbedReportViewModel } from '../models/embed-report-view-model';
import { ReportViewModel } from '../models/report-view-model';
import { Embed } from 'embed';
import * as pbi from 'powerbi-client';
import { ErrorService } from './error.service';
import * as models from 'powerbi-models';
import { ForecastRequestModel } from '../models/requests/forecast-request';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private powerBiService: NgxPowerBiService;

  constructor(public http: HttpClient) {
    this.powerBiService = new NgxPowerBiService();
  }

  public getEmbedReport(reportId: string,): Observable<EmbedReportViewModel> {
    const apiUrl = this.getUrlForEmbedReport(
     reportId
    );
    return this.http
      .get<EmbedReportViewModel>(apiUrl)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public getAlertEmbedReport(alertDefinitionId: string) {
    return this.http.get<EmbedReportViewModel>(`${environment.reportServiceUrl}Reports/Alert/${alertDefinitionId}`);
  }

  public getChildEmbedReport(reportId: string): Observable<EmbedReportViewModel> {
    const apiUrl = this.getUrlForChildEmbedReport(
      reportId
    );
    return this.http
      .get<EmbedReportViewModel>(apiUrl)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public getInternalEmbedReport(customerId: string, reportId: string, currentRole: string): Observable<EmbedReportViewModel> {
    const apiUrl = this.getUrlForInternalEmbedReport(customerId, reportId, currentRole);
    return this.http
      .get<EmbedReportViewModel>(apiUrl)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  /*
  public getAllReports(currentRole: string, isPremium: boolean) {
    const reportsUrl = environment.reportServiceUrl + 'Reports/Roles/' + currentRole + '/AllReports?Premium=' + isPremium;
    return this.http
      .get<ReportViewModel[]>(reportsUrl)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }
*/
  public getUrlForEmbedReport(reportId: string): string {
    return `${environment.reportServiceUrl}Reports/Customers/${reportId}`;
  }

  public getUrlForChildEmbedReport(reportId: string): string {
    return `${environment.reportServiceUrl}Reports/Customers/${reportId}`;
  }

  public getUrlForInternalEmbedReport(customerId: string, reportId: string, currentRole: string): string {
    return `${environment.reportServiceUrl}Reports/Internal/Roles/${currentRole}/${reportId}?customerId=${customerId}`;
  }
  public embedReportInHtmlElement(htmlElement: HTMLElement, config: IEmbedConfiguration): Embed {
    return this.powerBiService.embed(htmlElement, config);
  }

  public resetHtmlElement(htmlElement: HTMLElement): void {
    this.powerBiService.reset(htmlElement);
  }

  public putBookmark(appId: string, userId: string, bookmarkId: string) {
    const url = `${environment.reportServiceUrl}Bookmark/${appId}/${userId}`;
    return this.http.put(url, { bookmarkId })
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public getIEmbedConfigurationBase(embedReport: EmbedReportViewModel,
    layoutType: models.LayoutType = models.LayoutType.Master,
    bookmarkName: string = null): models.IEmbedConfigurationBase {
    let result = {
      type: 'report',
      id: embedReport.id,
      embedUrl: embedReport.embedUrl,
      accessToken: embedReport.embedToken.token,
      tokenType: models.TokenType.Embed,
      permissions: models.Permissions.Read,
      viewMode: models.ViewMode.View,
      settings: {
        localeSettings: {
          language: 'en',
          formatLocale: 'en',
        },
        layoutType,
        navContentPaneEnabled: false,
        filterPaneEnabled: false,
      }
    } as models.IEmbedConfigurationBase;

    if (bookmarkName !== null) {
      result = {
        ...result,
        bookmark: {
          name: bookmarkName
        }
      } as any;
    }

    return result;
  }

  public getCommodityForecast(forecastReq: ForecastRequestModel): any {
    const url = environment.reportServiceUrl + 'Forecast/RunForecast';

    return this.http
      .post<EmbedReportViewModel>(url, forecastReq)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public getCommodities(pageNumber = 1, pageSize = 100, filter = ''): Observable<Paginated<string>> {
    const paramsObj = { PageNumber: pageNumber.toString(), PageSize: pageSize.toString() };
    const searchParams = new URLSearchParams(paramsObj);

    var url = `${environment.reportServiceUrl}Forecast/Commodities?${searchParams.toString()}`;
    if (filter != null && filter != '') {
      url += '&FilterText=' + filter;
    }

    return this.http.get<Paginated<string>>(url)
      .pipe(catchError(ErrorService.handleHttpErrorResponse));
  }

  public embedPowerBiReport(nativeElement: HTMLElement, config: models.IEmbedConfigurationBase): any {
    this.resetHtmlElement(nativeElement);
    const result: any = this.powerBiService.embed(nativeElement, config);
    result.iframe.style.borderWidth = 0;

    return result;
  }

  public resetEmbedToken(embedContainer: HTMLElement, newReport: EmbedReportViewModel){
    const newAccessToken = newReport.embedToken;
    const report = this.powerBiService.get(embedContainer);
    report.setAccessToken(newAccessToken.token);
  }
}
