import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/analytics-portal/src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { RegionalFiltersViewModel } from '../models/regional-filters-view-model';
import { ResponseModel } from '../models/responses/response-model';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private regions: Observable<object>;
  private search: Observable<object>;
  private ads: Observable<object>;
  constructor(public http: HttpClient) { }

  public getAds() {
    if(!this.ads){
      this.ads = this.http.get(`${environment.notificationServiceUrl}Filters/Ads`)
      .pipe(
        publishReplay(1),
        refCount()
      );
    }
    return this.ads;
  }

  public getSearch() {
    if(!this.search){
      this.search = this.http.get(`${environment.notificationServiceUrl}Filters/Search`)
      .pipe(
        publishReplay(1),
        refCount()
      );
    }
    return this.search;
  }

  public getRegions() {
    if(!this.regions){
      this.regions = this.http.get(`${environment.notificationServiceUrl}Filters/Regions`)
      .pipe(
      publishReplay(1),
      refCount()
      );
    }
    return this.regions;
  }

  public clearRegions(){
    this.regions = null;
  }

  public clearFilters(){
    this.search = null;
    this.ads = null;
  }
}
