import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataSharingComponent } from './components/data-sharing/data-sharing.component';
import { ProfilesService } from './services/profiles.service';
import { DataSharingConfig } from './config/data-sharing-config';
import { MockProfilesService } from './mocks/mock-profiles-service';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [DataSharingComponent],
  exports: [DataSharingComponent],
  providers: [ProfilesService, MockProfilesService],
})
export class DataSharingModule {
  static forRoot(config: DataSharingConfig): ModuleWithProviders<DataSharingModule> {
    return {
      ngModule: DataSharingModule,
      providers: [{ provide: DataSharingConfig, useValue: config }],
    };
  }
}
