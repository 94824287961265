import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ReleaseNote } from '../../../../../src/app/models/release-note-model';
import { ReleaseNotesService } from '../../../../../src/app/services/release-notes.service';
import { StateService } from '../../../../../src/app/services/state.service';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {

  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  public isOpen = false;
  public userRole: string;
  public releaseNotes: ReleaseNote[];
  public currentReleaseNotes: ReleaseNote;
  public historicalReleaseNotes: ReleaseNote[];

  constructor(
    private releaseNotesService: ReleaseNotesService,
    public stateService: StateService,
  ) { }

  ngOnInit(): void {
    this.getReleaseNotes();
  }

  public close(): void {
    this.closeEvent.emit();
  }

  getReleaseNotes() {
    this.userRole = this.releaseNotesService.releaseRole.toUpperCase();
    if (this.userRole === 'MANUFACTURER' || this.userRole === 'INTERNAL') {
      this.currentReleaseNotes = this.releaseNotesService.currentReleaseNotes;
      this.historicalReleaseNotes = this.releaseNotesService.historicalReleaseNotes;
    }
    else if (this.userRole === 'CONTRACTOR'){
      this.currentReleaseNotes = this.releaseNotesService.currentReleaseNotesContractor;
      this.historicalReleaseNotes = this.releaseNotesService.historicalReleaseNotesContractor;
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

}

