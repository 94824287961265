<nav class="caHeader">
  <div class="headerLogo">
      <img
        src="..\assets\images\vp-construction-logo.svg"
        alt="logo"
      />
  </div>
  <h1 *ngIf="!showError">{{reportFriendlyName}} {{reportSecondName}}</h1>
</nav>
<div *ngIf="!showError" #reportElement class="reportView" id="embedReport"></div>
<div *ngIf="showError" class="errorContainer reportView">
  <h1>{{errorTitle}}</h1>
  <p>{{errorMessage}}</p>
</div>
<app-authenticated-footer></app-authenticated-footer>
