/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { DataSharingService } from 'apps/analytics-portal/src/app/services/data-sharing.service';
import { ErrorService } from 'apps/analytics-portal/src/app/services/error.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AboutComponent } from '../home/components/about/about.component';
import { ProfileComponent } from '../shared/profile/profile.component';
import { CustomerRole } from '../models/customer-role';
import { CustomerService } from '../services/customer.service';
import { ModalService, ModalSize } from '../services/modal.service';
import { ReleaseNotesService } from '../services/release-notes.service';
import { StateService } from '../services/state.service';
import { TermsOfServiceComponent } from '../shared/terms-of-service/terms-of-service.component';
import { ContactInformationComponent } from './components/contact-information/contact-information.component';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy{

  @HostBinding('class') class = 'modus-content-rows';
  public bsModalRef: BsModalRef;
  public userGuideLabel: string;
  public subscriptions: Subscription[] = [];

  public isInternal = false;
  public showAbout = false;
  public showContReleaseNotes = false;
  public showContUserGuide = false;
  public showManfReleaseNotes = false;
  public showManfUserGuide = false;
  public showProfile = false;
  public showSubscriptions = false;
  public showTerms = false;
  public showDataSharing = false;

  constructor(public stateService: StateService, public modalService: ModalService, public customerService: CustomerService,
    public releaseNotesService: ReleaseNotesService, public errorService: ErrorService, public dataSharingService: DataSharingService) { }

  ngOnInit(): void {
    const role = this.customerService.getCustomerRoleFromString(this.stateService.effectiveRole);
    this.calculateCardVisibility(role);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  calculateCardVisibility(role: string): void {
    switch (role) {
      case CustomerRole.manufacturer:
        this.isInternal = false;
        this.showAbout = true;
        this.showContReleaseNotes = false;
        this.showContUserGuide = false;
        this.showManfReleaseNotes = true;
        this.showManfUserGuide = true;
        this.showProfile = true;
        this.showSubscriptions = true;
        this.showTerms = true;
        break;

      case CustomerRole.contractor:
        this.isInternal = false;
        this.showAbout = false;
        this.showContReleaseNotes = false;
        this.showContUserGuide = true;
        this.showManfReleaseNotes = false;
        this.showManfUserGuide = false;
        this.showProfile = false;
        this.showSubscriptions = false;
        this.showTerms = false;
        break;

      case CustomerRole.internal:
        this.isInternal = true;
        this.showAbout = false;
        this.showContReleaseNotes = true;
        this.showContUserGuide = true;
        this.showManfReleaseNotes = true;
        this.showManfUserGuide = true;
        this.showProfile = false;
        this.showSubscriptions = false;
        this.showTerms = false;
        break;

      default:
        break;
    }
  }

  public openModalReleaseNotes(releaseRole: string): void {
    this.releaseNotesService.releaseRole = releaseRole;
    this.bsModalRef = this.modalService.show(ReleaseNotesComponent, ModalSize.medium);
    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.closeModal();
    });
  }

  public openModalContactInfo(): void {
    this.bsModalRef = this.modalService.show(ContactInformationComponent, ModalSize.medium);
    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.closeModal();
    });
  }

  public openModalDataSharing(): void {
    this.dataSharingService.setUpDataSharingModal(true, true);
  }

  public dataSharingSubmitted(preference: boolean) {
    this.stateService.setDataSharingAccepted(preference);
    // this.bsModalRef.hide();
    this.modalService.hide(this.bsModalRef);
  }

  public openModalProfile(): void {
    this.bsModalRef = this.modalService.show(ProfileComponent, ModalSize.medium);

    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.closeModal();
    });
  }

  public openModalAbout(): void {
    this.bsModalRef = this.modalService.show(AboutComponent, ModalSize.small);

    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.closeModal();
    });
  }

  public openModalTerms(): void {
    this.bsModalRef = this.modalService.show(TermsOfServiceComponent, ModalSize.large);

    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.closeModal();
    });

    this.bsModalRef.content.agreeEvent.subscribe(() => {
      this.closeModal();
    });
  }

  public closeModal(): void {
    this.modalService.hide(this.bsModalRef);
  }
}
