<div class="modus-content-rows">
  <div class="modus-content-columns">
    <div class="modus-content">
      <div *ngIf="lockPremium" class="non-premium-message">
        <h1>Content Usage Dashboard</h1>
        <p></p>
        This report provides high-level data of client usage of your products across different workflows.
        <p></p>
        <p>Click <a target="_blank" (click)="sendMarketingEmail()">here</a> to be contacted by an account representative to gain access to the full power of Construction Analytics.</p>
      </div>
      <div *ngIf="!lockPremium">
        <div class="top-title">
          <h1>Content Usage Dashboard</h1>
          <p>This dashboard provides an overview of the usage of a manufacturer's content in the Trimble construction
            management ecosystem.</p>
        </div>
        <div class="widgets-container">
          <div class="report-container" *ngFor="let template of reportTiles">
            <div class="title">
              <div class="card-text">{{template.title}}</div>
              <span [tooltip]="lockPremium && template.premium? 'Requires Premium' : null" class="tooltip-wrapper">
                <div class="button">
                  <button *ngIf="template.buttonLink !== null" class="btn btn-sm btn-primary"
                    [routerLink]="template.buttonLink" [disabled]="lockPremium && template.premium">
                    More details
                  </button>
                </div>
              </span>
            </div>
            <img src="../../../../assets/images/loader2.gif" alt="Loading Animation" />
            <div class="report" #reportViews id="{{template.name}}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
