<div class="alerts-header" *ngIf="isPremium">
  <h4 id="alertsHeader">Alerts</h4>
</div>
<div class="modus-content-columns">
  <div *ngIf="!isPremium" class="modus-content p-4 non-premium-message">
    <h1 id="alertsHeaderNonPremium">Alerts</h1>
    <p></p>
    <p id="alertsDescriptionNonPremium">This feature enables users to receive reports outside the tool and share these reports with other users</p>
    <p></p>
    <p id="alertsTextMarketingEmail">Click
      <a target="_blank" (click)="sendMarketingEmail()" id="alertsLinkMarketingEmail">
        here
      </a> to be contacted by an account representative to gain access to the full power of Construction Analytics.</p>
  </div>
  <div class="modus-panel panel-lg shadow-lg" *ngIf="isPremium">
    <div class="add-button-header">
      <button id="alertsButtonAddNew" class="btn btn-primary" (click)="initiateCreationProcess()">Add New</button>
    </div>
    <div class="panel-body" id="alertsList">
      <div class="scroll-container p-2" *ngIf="hasItems">
        <ul id="list-group" class="list-group" [ngClass]="{'no-selected' : !selectedItem}">
          <li class="list-group-item"
            *ngFor="let item of items | async | sortAlerts: items; let i = index"
            [id]="'alertsListItem'+ i"
            (click)="openNotification(item)" [ngClass]="{'selected' : item === selectedItem}">
            <em class="modus-icon material-icons">notifications</em>
            <div class="body-text-wrapper">
              <div class="body-text" [id]="'alertsListName'+i">{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="empty-container" *ngIf="!hasItems">
        You are all caught up!
      </div>
    </div>
  </div>
  <div role="content-container" id="content-container" *ngIf="isPremium">
    <div class="modus-content p-4" id="default-content alertsDefaultContent" *ngIf="!isCreating && !selectedItem">
      <h1 id="alertsDefaultHeader">Alerts Setup</h1>
      <p id="alertsDefaultText">Create and manage alerts to receive an email notification for alerts-related activity.</p>
      <hr>
    </div>
    <app-alerts-report [alert]="selectedItem" [regionFilters]="regions"
      *ngIf="!isCreating && selectedItem" [class.invisible]="!showSelectedItem"
      (deleteEvent)="deleteAlert()" (editEvent)="editAlert()" (closeEvent)="closeAlert()"></app-alerts-report>
    <div *ngIf="isCreating" class="alert-setup-container">
      <app-alert-setup [alert]="selectedItem" (returnEvent)="disableEditorComponent($event)"></app-alert-setup>
    </div>
  </div>
</div>
