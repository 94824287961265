/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { AlertViewModel } from 'apps/analytics-portal/src/app/models/alert-view-model';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { CustomerModel } from '../models/customer-model';
import { CustomerRole } from '../models/customer-role';
import { UserViewModel } from '../models/user-view-model';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { CustomerService } from './customer.service';
import { ErrorService } from './error.service';
import { ModalService, ModalSize } from './modal.service';
import { StorageService } from './storage.service';
import { AccountViewModel } from '../models/responses/account-view-model';
import { CustomerFeatureType } from '../models/customer-feature-type';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public readonly accessToken$: Observable<string>;
  public readonly currentUser$: Observable<UserViewModel>;
  public readonly dataSharingAccepted$: Observable<boolean>;
  public readonly hasValidToken$: Observable<boolean>;
  public readonly hideHeaderFooterAndNav$: Observable<boolean>;
  public readonly idToken$: Observable<string>;
  public readonly isAuthenticated$: Observable<boolean>;
  public readonly alerts$: Observable<AlertViewModel[]>;
  public readonly tokenTimeout$: Observable<moment.Moment>;
  public readonly settingClaims$: Observable<boolean>;
  public refreshTimer: any;
  public previousUrl = '/';

  public bsModalRef: BsModalRef = null;
  public refreshModalIsOpen = false;

  public readonly settingClaims = new BehaviorSubject<boolean>(false);

  private readonly subjectCurrentUser = new BehaviorSubject<UserViewModel>(null);
  private readonly subjectAccessToken = new BehaviorSubject<string>(null);
  private readonly subjectDataSharingAccepted = new BehaviorSubject<boolean>(null);
  private readonly subjectSelectedRole = new BehaviorSubject<string>(null);
  private readonly subjectSelectedParentCustomer = new BehaviorSubject<CustomerModel>(null);
  private readonly subjectSelectedChildCustomer = new BehaviorSubject<CustomerModel>(null);
  private readonly subjectHasValidToken = new BehaviorSubject<boolean>(false);
  private readonly subjectHasChildren = new BehaviorSubject<boolean>(false);
  private readonly subjectHideHeaderFooterAndNav = new BehaviorSubject<boolean>(false);
  private readonly subjectIdToken = new BehaviorSubject<string>(null);
  private readonly subjectIsAuthenticated = new BehaviorSubject<boolean>(false);
  private readonly subjectHasContractorLicense = new BehaviorSubject(false);
  private readonly subjectAlerts = new BehaviorSubject<AlertViewModel[]>([]);
  private readonly subjectLicenseAccounts = new BehaviorSubject<AccountViewModel[]>(null);
  private readonly subjectTokenTimeout = new BehaviorSubject<moment.Moment>(moment().add(1, 'hours'));

  constructor(
    public modalService: ModalService, public customerService: CustomerService, private router: Router,
    public storageService: StorageService) {
    this.accessToken$ = this.subjectAccessToken.asObservable();
    this.currentUser$ = this.subjectCurrentUser.asObservable();
    this.dataSharingAccepted$ = this.subjectDataSharingAccepted.asObservable();
    this.hasValidToken$ = this.subjectHasValidToken.asObservable();
    this.hideHeaderFooterAndNav$ = this.subjectHideHeaderFooterAndNav.asObservable();
    this.idToken$ = this.subjectIdToken.asObservable();
    this.isAuthenticated$ = this.subjectIsAuthenticated.asObservable();
    this.alerts$ = this.subjectAlerts.asObservable();
    this.tokenTimeout$ = this.subjectTokenTimeout.asObservable();
  }


  public get accessToken(): string {
    return this.subjectAccessToken.getValue();
  }

  public get currentUser(): UserViewModel {
    return this.subjectCurrentUser.getValue();
  }

  public get dataSharingAccepted(): boolean | null {
    return this.subjectDataSharingAccepted.getValue();
  }

  public get hideHeaderFooterAndNav(): boolean {
    return this.subjectHideHeaderFooterAndNav.getValue();
  }

  public get hasValidToken(): boolean {
    return this.subjectHasValidToken.getValue();
  }
 /**
  *Check if user has access to child companies
  *to show/hide choose company options.
  *
  * @readonly
  * @type {boolean}
  * @memberof StateService
  */
  public get hasChildren(): boolean {
    return this.subjectHasChildren.getValue();
  }

  public get idToken(): string {
    return this.subjectIdToken.getValue();
  }

  public get isAuthenticated(): boolean {
    return this.subjectIsAuthenticated.getValue();
  }

  public get contractorLicense(): boolean {
    return this.subjectHasContractorLicense.getValue();
  }

  public get alerts(): AlertViewModel[] {
    return this.subjectAlerts.getValue();
  }

  public get licenseAccount(): AccountViewModel[] {
    return this.subjectLicenseAccounts.getValue();
  }
  public get tokenTimeout(): moment.Moment {
    return this.subjectTokenTimeout.getValue();
  }

  public get effectiveRole(): string {
    if(this.currentUser.customerRole.toLowerCase() === CustomerRole.internal.toLowerCase()){
      if (this.subjectSelectedRole.getValue() === null) {
        if(this.storageService.getReportRoleChoice() === null){
          this.subjectSelectedRole.next(this.currentUser.customerRole.toLowerCase());
        }
        else{
          this.subjectSelectedRole.next(this.storageService.getReportRoleChoice());
        }
      }
      return this.subjectSelectedRole.getValue();
    }
    else{
      return this.currentUser.customerRole;
    }
  }
  /**
   * The selected parent customer from choose company.
   *
   * @readonly
   * @type {CustomerModel}
   * @memberof StateService
   */
  public get effectiveParentCustomer(): CustomerModel {
    this.clearBadStorage();
    if(this.currentUser.customerRole.toLowerCase() === CustomerRole.internal.toLowerCase()){
      //FOR INTERNAL
      if (this.subjectSelectedParentCustomer.getValue() === null) {
        if(this.storageService.getParentCustomerId() === null){
          // no parent id is set or stored
          this.subjectSelectedParentCustomer.next(this.currentUserCustomer);
        }
        else{
          // parent id is not set but one is stored
          this.subjectSelectedParentCustomer.next(this.parentCustomerFromStorage);
        }
      }
      // parent id is set
      return this.subjectSelectedParentCustomer.getValue();
    }
    else{
      // FOR NON INTERNAL
      return this.currentUserCustomer;
    }
  }
 /**
  *The selected child customer from choose company.
  *
  * @readonly
  * @type {CustomerModel}
  * @memberof StateService
  */
  public get effectiveChildCustomer(): CustomerModel {
    this.clearBadStorage();
    if (this.subjectSelectedChildCustomer.getValue() === null) {
      if(this.storageService.getChildCustomerId() === null){
        // no child id is set or stored
        this.subjectSelectedChildCustomer.next(this.currentUserCustomer);
      }
      else{
        // child id is not set but one is stored
        this.subjectSelectedChildCustomer.next(this.childCustomerFromStorage);
      }
    }
    // child id is set
    return this.subjectSelectedChildCustomer.getValue();
  }
  /**
   * Convert the user's customer information into a customer Model.
   *
   * @readonly
   * @private
   * @type {CustomerModel}
   * @memberof StateService
   */
  private get currentUserCustomer(): CustomerModel{
    return {
      id: this.currentUser.customerId,
      name: this.currentUser.customerCName,
      role:
        this.customerService.getCustomerRoleFromString(this.currentUser.customerRole) as CustomerRole
    } as CustomerModel;
  }
  /**
   * Retrieve stored parent customer selection.
   *
   * @readonly
   * @private
   * @type {CustomerModel}
   * @memberof StateService
   */
  private get parentCustomerFromStorage(): CustomerModel{
    return {
      id: +this.storageService.getParentCustomerId(),
      name: this.storageService.getParentCustomerName(),
      role: this.storageService.getReportRoleChoice()
    } as CustomerModel;
  }
/**
 * Retrieve stored child customer selection.
 *
 * @readonly
 * @private
 * @type {CustomerModel}
 * @memberof StateService
 */
private get childCustomerFromStorage(): CustomerModel{
    return {
      id: +this.storageService.getChildCustomerId(),
      name: this.storageService.getChildCustomerName(),
      role: this.storageService.getReportRoleChoice()
    } as CustomerModel;
  }

  public showModalProgress(loadingText: string = ''): void {
    this.bsModalRef = this.modalService.show(SpinnerComponent, ModalSize.small, true);

    if (loadingText && loadingText.length) {
      this.bsModalRef.content.setLoadingText(loadingText);
    }
  }

  public hideModalProgress(): void {
    this.modalService.hide(this.bsModalRef);
  }

  public setAccessToken(accessToken: string) {
    this.subjectAccessToken.next(accessToken);
  }

  public setCurrentUser(user: UserViewModel) {
    this.subjectCurrentUser.next(user);
  }

  public setAlerts(alerts: AlertViewModel[]) {
    this.subjectAlerts.next(alerts);
  }

  public setLicenseAccounts(accounts: AccountViewModel[]){
    this.subjectLicenseAccounts.next(accounts);
  }

  public deleteAlert(alert: AlertViewModel){
    let alertList = this.alerts;
    alertList = alertList.filter((x) =>  x.alertDefinitionId !== alert.alertDefinitionId);
    this.setAlerts(alertList);
  }

  public setDataSharingAccepted(dataSharingAccepted: boolean) {
    this.subjectDataSharingAccepted.next(dataSharingAccepted);
  }

  public setHasValidToken(hasValidToken: boolean) {
    this.subjectHasValidToken.next(hasValidToken);
  }
  /**
   * True if the user can access child companies.
   *
   * @param hasChildren
   * @memberof StateService
   */
  public setHasChildren(hasChildren: boolean) {
    this.subjectHasChildren.next(hasChildren);
  }

  public setHideHeaderFooterAndNav(hideHeaderFooterAndNav: boolean) {
    this.subjectHideHeaderFooterAndNav.next(hideHeaderFooterAndNav);
  }

  public setIdToken(idToken: string) {
    this.subjectIdToken.next(idToken);
  }

  public setIsAuthenticated(isAuthenticated: boolean) {
    this.subjectIsAuthenticated.next(isAuthenticated);
  }

  public setContractorLicense(licensed: boolean){
    this.subjectHasContractorLicense.next(licensed);
  }
/**
 *True if the process of changing the custom claims still needs to be completed.
 *False if it has been completed.
 *
 * @param  settingClaims
 * @memberof StateService
 */
public setSettingClaims(settingClaims: boolean) {
    this.settingClaims.next(settingClaims);
  }

  public setTokenTimeout(expires: moment.Moment) {
    this.subjectTokenTimeout.next(expires);
  }

  public isDirty(initialViewModel: any, currentViewModel: any): boolean {
    const currentViewModelProperties = Object.getOwnPropertyNames(currentViewModel);

    for (let i = 0; i < currentViewModelProperties.length; i++) {
      const propertyName = currentViewModelProperties[i];

      // if values of same property are not equal, currentViewModel is dirty

      if (currentViewModel[propertyName] !== initialViewModel[propertyName]) {
        return true;
      }
    }

    return false;
  }

  public initBackButtonBehavior() {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
      });
  }

  public getDefaultRouterPath(): string {
    const role = this.customerService.getCustomerRoleFromString(this.currentUser.customerRole);
    const isPremium = (this.currentUser.customerFeatureType === CustomerFeatureType.premium);
    return role === CustomerRole.manufacturer && isPremium ? '/home' : environment.defaultRouterPath;
  }

  public setEffectiveRole(role: string){
    this.subjectSelectedRole.next(role);
  }

 /**
  *Select a parent customer other than that of the user.
  *
  * @param  parentCustomer
  * @memberof StateService
  */
  public setEffectiveParentCustomer(parentCustomer: CustomerModel){
    this.subjectSelectedParentCustomer.next(parentCustomer);
  }
 /**
  *Select a child customer other than that of the user.
  *
  * @param childCustomer
  * @memberof StateService
  */
  public setEffectiveChildCustomer(childCustomer: CustomerModel){
    this.subjectSelectedChildCustomer.next(childCustomer);
  }

 /**
  *Reset storage if the customer choice doesn't make sense for the current user.
  *
  * @private
  * @memberof StateService
  */
  private clearBadStorage(){
    //if user isn't internal and the stored parent is not their own
    if((this.currentUser.customerRole.toLowerCase() !== CustomerRole.internal.toLowerCase()
    && this.currentUser.customerId !== +this.storageService.getParentCustomerId()) ||
    // or they are internal but there's no role
    (this.currentUser.customerRole.toLowerCase() === CustomerRole.internal.toLowerCase() &&
      this.storageService.getReportRoleChoice() === null) ||
    //or they're a contractor
    (this.currentUser.customerRole.toLowerCase() === CustomerRole.contractor.toLowerCase())
      ){

      this.storageService.clearReportRoleChoice();
      this.storageService.clearParentCustomer();
      this.storageService.clearChildCustomer();
    }
  }

}
