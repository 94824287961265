<div class="modus-footer d-flex align-items-center">
  <div class="small">
    © Copyright 2021, Trimble Inc. &nbsp;
    <a
      href="https://www.trimble.com/privacy.aspx"
      target="trimble_privacy_center"
      >Privacy Center</a
    >
  </div>
</div>
