<div class="container">
  <p class="display-3">An Unexpected Error Occurred!</p>
  <p>
    We are very sorry for the inconvenience.
    <a (click)="logout()">Please click here</a> to start a new session.
  </p>
  <p *ngIf="additionalErrorInformation">
    Additional Information: {{ additionalErrorInformation }}
  </p>
</div>
