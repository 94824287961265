/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlertsComponent } from 'apps/analytics-portal/src/app/alerts/alerts.component';
import { DataSharingWrapperComponent } from 'apps/analytics-portal/src/app/data-sharing-wrapper/data-sharing-wrapper.component';
import { LoginComponent } from './auth/components/login/login.component';
import { AuthenticationGuard } from './auth/guards/authentication-guard';
import { ForecastComponent } from './forecast/forecast.component';
import { HelpComponent } from './help/help.component';
import { TermsComponent } from './home/components/terms/terms.component';
import { HomeComponent } from './home/home.component';
import { TabbedReportsComponent } from './reports/tabbed-reports.component';
import { ErrorComponent } from './shared/error/error.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { VideoIntroComponent } from './unauthenticated/features/video-intro/video-intro.component';
import { ContractorReportsComponent } from './reports/contractor-reports.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: VideoIntroComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'login', component: LoginComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard] },
  { path: 'reports', component: TabbedReportsComponent,
    canActivate: [AuthenticationGuard],
    children:[{path: ':tab', component: TabbedReportsComponent}]
  },
  { path: 'contractor/:name', component: ContractorReportsComponent
  },
  { path: 'forecast', component: ForecastComponent,
    canActivate: [AuthenticationGuard]
  },
  { path: 'help', component: HelpComponent, canActivate: [AuthenticationGuard] },
  { path: 'tos', component: TermsComponent, canActivate: [AuthenticationGuard] },
  { path: 'alerts', component: AlertsComponent, canActivate: [AuthenticationGuard]},

  //legacy routes
  { path: 'need-help-buffer', redirectTo: '/help'},

  { path: '**', component: PageNotFoundComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
