<div class="modal-header" id="aboutModal">
  <em class="modus-icon material-icons">info</em>
  <h5 class="modal-title" id="aboutTitle">About</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body" id="aboutBody" >
  <div class="logo-trimble">
    <img src="../../../../assets/images/trimble-logo.svg" alt="logo"/>
  </div>
  <div class="logo-ca">
    <img
      src="../../../../assets/images/MEP-Trimble-Analytics-Logo-RGB-Horizontal-v01-r00.svg"
      alt="logo"
    />
  </div>
  <h1>{{portalVersionNo}}</h1>
  <div class="versions">
    <div id="aboutPortal"><strong>Portal</strong><span>{{portalVersionNo}}</span></div>
    <div id="aboutUser"><strong>User</strong><span>{{userVersionNo}}</span></div>
    <div id="aboutNotifications"><strong>Notification</strong><span>{{notificationVersionNo}}</span></div>
    <div id="aboutReports"><strong>Reports</strong><span>{{reportsVersionNo}}</span></div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" id="aboutCloseButton" (click)="close()">Close</button>
</div>
