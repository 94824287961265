import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockProfilesService {
  public getUserRelations() {
    console.log('in the mock');
    return of({
      data: [],
    });
  }

  public deleteUserRelation() {
    return of({});
  }

  public postUserRelation() {
    return of({});
  }

  public accepted() {
    return null;
  }

  public getConsentRelationUuid() {
    return 'test';
  }
}
