/*
 * Copyright © 2021, Trimble Inc.
 * All rights reserved.
 *
 * The entire contents of this file is protected by U.S. and
 * International Copyright Laws. Unauthorized reproduction,
 * reverse-engineering, and distribution of all or any portion of
 * the code contained in this file is strictly prohibited and may
 * result in severe civil and criminal penalties and will be
 * prosecuted to the maximum extent possible under the law.
 *
 * CONFIDENTIALITY
 *
 * This source code and all resulting intermediate files, as well as the
 * application design, are confidential and proprietary trade secrets of
 * Trimble Inc.
 */

import { AboutComponent } from './home/components/about/about.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProfileComponent } from './shared/profile/profile.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabbedReportsComponent } from './reports/tabbed-reports.component';
import { DataSharingModule, ProfilesService } from '@hcworkspace/analytics-portal/data-sharing';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { SuccessMessageComponent } from './shared/success-message/success-message.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TermsComponent } from './home/components/terms/terms.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UnauthenticatedHeaderComponent } from './unauthenticated/unauthenticated-header/unauthenticated-header.component';
import { UnauthenticatedFooterComponent } from './unauthenticated/unauthenticated-footer/unauthenticated-footer.component';
import { AuthModule } from './auth/auth.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthInterceptorService } from './auth/interceptors/auth-interceptor.service';
import { AuthenticatedHeaderComponent } from './authenticated/components/authenticated-header/authenticated-header.component';
import { AuthenticatedFooterComponent } from './authenticated/components/authenticated-footer/authenticated-footer.component';
import { AuthenticatedNavComponent } from './authenticated/components/authenticated-nav/authenticated-nav.component';
import { VideoIntroComponent } from './unauthenticated/features/video-intro/video-intro.component';
import { BpmComponent } from './unauthenticated/features/bpm/bpm.component';
import { ReleaseNotesComponent } from './help/components/release-notes/release-notes.component';
import { CustomScrollDirective } from './directives/custom-scroll.directive';
import { ContactInformationComponent } from './help/components/contact-information/contact-information.component';
import { TrackingComponent } from './tracking/tracking.component';
import { CommonModule } from '@angular/common';
import { RefreshTokenComponent } from './shared/refresh-token/refresh-token.component';
import { ErrorComponent } from './shared/error/error.component';
import { TermsOfServiceComponent } from './shared/terms-of-service/terms-of-service.component';
import { ChooseCompanyComponent } from './shared/profile/choose-company/choose-company.component';
import { HotToastModule } from '@ngneat/hot-toast';
import { HomeDefaultComponent } from './home/components/home-default/home-default.component';
import { HomeManufacturerComponent } from './home/components/home-manufacturer/home-manufacturer.component';
import { dataSharingConfig } from 'apps/analytics-portal/src/app/module-configs/data-sharing.config';
import { DataSharingWrapperComponent } from './data-sharing-wrapper/data-sharing-wrapper.component';
import { AlertsComponent } from './alerts/alerts.component';
import { SortAlerts } from 'apps/analytics-portal/src/app/pipes/sort-alerts.pipe';
import { AlertSetupComponent } from './alerts/alert-setup/alert-setup.component';
import { AlertsReportComponent } from './alerts/components/alerts-report/alerts-report.component';
import { ForecastComponent } from './forecast/forecast.component';
import { ForecastSelectionComponent } from './forecast/selection/forecast-selection.component';
import { ForecastReportComponent } from './forecast/report/forecast-report.component';
import { ContractorReportsComponent } from './reports/contractor-reports.component';
import { CustomerSelectComponent } from './reports/contractor/customer-select.component';

@NgModule({
  declarations: [
    AboutComponent,
    AppComponent,
    CustomScrollDirective,
    HelpComponent,
    HomeComponent,
    PageNotFoundComponent,
    ProfileComponent,
    TabbedReportsComponent,
    ContractorReportsComponent,
    CustomerSelectComponent,
    SpinnerComponent,
    SuccessMessageComponent,
    TermsComponent,
    UnauthenticatedHeaderComponent,
    UnauthenticatedFooterComponent,
    BpmComponent,
    AuthenticatedHeaderComponent,
    AuthenticatedFooterComponent,
    AuthenticatedNavComponent,
    VideoIntroComponent,
    ReleaseNotesComponent,
    ContactInformationComponent,
    TrackingComponent,
    RefreshTokenComponent,
    ErrorComponent,
    TermsOfServiceComponent,
    ChooseCompanyComponent,
    SortAlerts,
    HomeDefaultComponent,
    HomeManufacturerComponent,
    DataSharingWrapperComponent,
    AlertsComponent,
    AlertSetupComponent,
    AlertsReportComponent,
    ForecastComponent,
    ForecastSelectionComponent,
    ForecastReportComponent,
  ],
  imports: [
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    CommonModule,
    DataSharingModule.forRoot(dataSharingConfig),
    BsDatepickerModule.forRoot(),
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    ModalModule,
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    ReactiveFormsModule,
    SortableModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    AuthModule,
    OAuthModule.forRoot(),
    HotToastModule.forRoot({
      dismissible: true,
      style: {
        bottom: '0',
      },
    }),
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    BsModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    ProfilesService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
