/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { environment } from '../../../../src/environments/environment';
import { LogService } from '../../../../src/app/services/log.service';
import { StateService } from '../../../../src/app/services/state.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private stateService: StateService,
    private errorHandler: OAuthResourceServerErrorHandler,
    private logService: LogService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.doesRequestNeedToken(request)) {
      return next.handle(request);
    }

    const token = this.stateService.accessToken;
    this.logService.writeLine('Access Token: ' + token);

    const authRequest = request.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: 'Bearer ' + token
      }
    });

    return next
      .handle(authRequest)
      .pipe(catchError(err => this.errorHandler.handleError(err)));
  }

  private doesRequestNeedToken(request: HttpRequest<any>): boolean {
    const url = request.url.toLowerCase();
    if (!url.startsWith('http')) {
      return false;
    }

    const authorizedUris = this.getUrisThatNeedAuthorization();
    const found = authorizedUris.find(u => url.startsWith(u.toLowerCase()));
    return !!found;
  }

  private getUrisThatNeedAuthorization(): string[] {
    return [
      environment.sessionUrl,
      environment.reportServiceUrl,
      environment.customerListUrl,
      environment.notificationServiceUrl,
      environment.userServiceUrl,
      environment.profilesBaseUrl,
      environment.authenticationUrl + '/licenses'
    ];
  }
}
