<div *ngIf="!showManufacturerFeatureFlag && !showDistributorFeatureFlag">
  <!-- Hero -->
  <div class="hero hero-1 hero-analytics">
    <div class="container-fluid wrap hero-content">
      <div class="row">
        <div class="col-xs-12 hero-title">
          <!-- Construction Analytics -->
          <img src="/assets/images/TrimbleAnalyticsWht.svg" alt="Construction Analytics" class="hero-logo" />
        </div>
        <div class="col-xs-12 hero-text">
          <div style="width: 80%; margin: 0 auto">
            Thousands of professionals and millions of digital items every single
            day. Construction Analytics sees the big picture and makes sense of it
            all.
          </div>
        </div>

        <div style="text-align: center; width: 100%; padding: 25px 0 0 0">
          <button mat-raised-button onclick="launchLightbox('wXXp4FGxRZUnnrp1GRqdUt')" class="play-button btn">
            <div class="inside-play-button">
              Have a Look&nbsp;&nbsp;<em class="material-icons">play_circle_outline</em>
            </div>
          </button>
          <div id="hiddenLightbox">
            <div class="vidyard-player-embed" data-uuid="wXXp4FGxRZUnnrp1GRqdUt" data-v="4" data-type="lightbox"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Top Content -->
  <div class="container-fluid" style="background-color: #eaeaef">
    <div class="row analytics-intro">
      <h1 class="font-size-xl article-title">What is Construction Analytics?</h1>
      <div class="ter-text container-fluid wrap">
        <p>
          Construction Analytics captures data from Trimble’s pre-construction
          workflows and extracts intelligence from it.
          <strong>Thousands of professionals</strong> use
          <strong>millions of parts</strong> on Trimble’s platform to estimate,
          quote, model, prefabricate, and layout every day. Yes, millions.
        </p>
        <p style="margin-bottom: 1.5rem">
          The Construction industry is rapidly digitizing. With better data and
          insights from Construction Analytics, Contractors, Manufacturers, and
          Distributors win.
        </p>
        <a class="ter-primary-cta ter-button ter-button--primary--1"
          href="https://go.trimble.com/construction-analytics-general-contact-us.html" routerLinkActive="active"
          style="display:none;">Activate Your Free Account</a>
      </div>
    </div>
  </div>

  <!-- Blurb 3 Content -->
  <div class="container-fluid" style="background-color: #004e83">
    <div class="row analytics-intro">
      <div class="ter-text container-fluid wrap" style="color: #fff">
        <h1 class="font-size-xl article-title" style="color: #fff">
          The Problem
        </h1>
        <p>
          Construction is hard. Competitive pressures are greater than they have
          ever been. Good data is either hard to come by or too much to make sense
          of.
        </p>
        <h1 class="font-size-xl article-title" style="color: #fff">
          The Solution
        </h1>
        <p style="margin-bottom: 0">
          Construction Analytics centralizes data and decision making. With
          Construction Analytics, Contractors can better understand risk to their
          projects and their businesses. Manufacturers can better understand
          existing and future opportunities as well as their position in the
          marketplace. Distributors can perform better demand planning and be
          better equipped to fulfill Customer needs.
        </p>
      </div>
    </div>
  </div>

  <!-- Then and Now Graphics -->
  <div class="container-fluid" style="background:#fff;">
    <div class="container" style="margin:0 auto;">
      <div class="row then-now-row">
        <div class="col-12 col-md-6 left-then-now">
          <img src="../../assets/images/then-now/then2-780.png" class="then-now" alt="Before Construction Analytics" />
        </div>
        <div class="col-12 col-md-6 right-then-now">
          <img src="../../assets/images/then-now/now2-780.png" class="then-now" alt="After Construction Analytics" />
        </div>
      </div>
    </div>
  </div>

  <!-- Cards -->
  <section class="ter-feat-8 Inverse" style="padding-top: 0">
    <div class="ter-feat-8__top-container">
      <aside class="ter-feat-8__top-content"></aside>
    </div>
    <div class="ter-feat-8__grid-container">
      <div class="ter-feat-8__grid-item">
        <article class="ter-card">
          <figure class="ter-card__image">
            <img src="./assets/images/Manufacturer_350x233.jpg" width="1600" height="900" alt="Building Parts"
              typeof="foaf:Image" />
          </figure>
          <div class="ter-card__body">
            <h4 class="ter-card__header">Manufacturer Solutions</h4>
            <div class="ter-card__cta">
              <p>
                See where Customers are using your digital content - and where
                they are not. See where you are losing to competition. See how you
                are performing against your competition. See which levers will
                shape your business and help you to grow. See where you can better
                serve your Customers. Engage with your Customers and Distributors.
              </p>
              <a (click)="showManufacturerFeature()" routerLinkActive="active">Learn More</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</div>
<app-bpm *ngIf="showManufacturerFeatureFlag"></app-bpm>
