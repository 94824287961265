import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-unauthenticated-footer',
  templateUrl: './unauthenticated-footer.component.html',
  styleUrls: ['./unauthenticated-footer.component.scss']
})
export class UnauthenticatedFooterComponent implements OnInit {
  public currentYear = '';

  constructor() { }

  ngOnInit(): void {
    this.currentYear = moment().format('YYYY');
  }

}
