import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ReportsService } from '../../../services/reports.service';
import { StateService } from '../../../services/state.service';
import { EmbedReportViewModel } from '../../../models/embed-report-view-model';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { CustomerRole } from '../../../models/customer-role';
import { CustomerService } from '../../../services/customer.service';
import { CustomerFeatureType } from 'apps/analytics-portal/src/app/models/customer-feature-type';
import { EmbedTokenViewModel } from '../../../models/embed-token-view-model';
import { LogService } from '../../../services/log.service';
import { InternalNotificationService } from '../../../services/internal-notification.service';
import { ErrorService } from '../../../services/error.service';
import { PortalPageName } from '../../../models/portal-page-name';

@Component({
  selector: 'app-home-manufacturer',
  templateUrl: './home-manufacturer.component.html',
  styleUrls: ['./home-manufacturer.component.scss']
})
export class HomeManufacturerComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') class = 'modus-content-rows';
  @ViewChildren('reportViews', {read: ElementRef}) reportViews: QueryList<ElementRef>;

  public subscriptions: Subscription[] = [];
  public lockPremium = true;
  public reportTiles: {
    title: string;
    name: string;
    premium: boolean;
    report: EmbedReportViewModel;
    element: ElementRef;
    buttonLink: string;
  }[];

  private expirationTimers: any[] = [];

  constructor(
    public reportService: ReportsService,
    public logService: LogService,
    public stateService: StateService,
    public customerService: CustomerService,
    public errorService: ErrorService,
    public internalNotificationService: InternalNotificationService) { }


  public get isChildCustomerSelected(): boolean {
    return this.stateService.effectiveChildCustomer.id !== this.stateService.effectiveParentCustomer.id;
  }

  ngAfterViewInit(): void {
    // connect viewchildren to array elements
    this.addElementsToTiles();

      if (!this.lockPremium){
        this.subscriptions.push(this.stateService.settingClaims.subscribe(
          setting => {
             if(!setting){
              if(this.stateService.currentUser.customerRole.toLowerCase() === CustomerRole.internal.toLowerCase()){
                this.initReportsInternal();
              }
              else {
              if(this.isChildCustomerSelected){
                this.initReportsChild();
              }
              else{
                this.initReportsExternal();
              }
            }
            }
          }
        ));
      }
  }

  ngOnInit(): void {
    this.lockPremium = (this.stateService.currentUser.customerFeatureType === CustomerFeatureType.basic);
    // populate tiles array
    this.createTiles();
  }

  ngOnDestroy(): void {
    this.expirationTimers.forEach(e => clearInterval(e));
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   *Embed a power BI report into a tile.
   *
   * @param embedReport settings from ReportService
   * @param nativeElement Html element that will hold the report
   * @param id Power BI report Id
   * @memberof HomeManufacturerComponent
   */
  public embedReport(embedReport: EmbedReportViewModel, nativeElement: any, id: string): void {
    const config = this.reportService.getIEmbedConfigurationBase(embedReport);
    const report = this.reportService.embedPowerBiReport(nativeElement, config);
    report.on('loaded', event => {
      nativeElement.classList.add('loaded');
      this.setTokenExpirationListener(embedReport.embedToken, id, nativeElement);
    } );
  }

  public createTiles(){
    this.reportTiles = [
      {title: 'Engagement Trends', name: 'engagementTrends',
        premium: false, report: null, element: null,
        buttonLink: null},
      {title: 'Advertisements', name: 'ads',
        premium: false, report: null, element: null,
        buttonLink: '/reports/ads'},
      {title: 'Search', name: 'search',
        premium: false, report: null, element: null,
        buttonLink: '/reports/itemSearch'},
      {title: 'Downloads', name: 'downloads',
        premium: false, report: null, element: null,
        buttonLink: '/reports/downloads'},
      {title: 'Quotes', name: 'quotes',
        premium: false, report: null, element: null,
        buttonLink: '/reports/quotes'},
      {title: 'Models', name: 'models',
        premium: true, report: null, element: null,
        buttonLink: '/reports/models'}
    ];
  }

/**
 *Connect ViewChild elements to members of the tile array - needs to happen after the elements are generated
 *
 * @memberof HomeManufacturerComponent
 */
public addElementsToTiles() {
    this.reportViews.forEach((reportElement, index) => {
      this.reportTiles[index].element = reportElement;
    });
  }

/**
 *Get Report information from Report Service for external users.
 *
 * @memberof HomeManufacturerComponent
 */
public initReportsExternal(): void {
    const ids = environment.homeReportIds.manufacturer;
    //const customerGuid = this.stateService.currentUser.customerCTidv1;

    this.reportTiles.forEach((tile) => {
      this.subscriptions.push(
        this.reportService.getEmbedReport(ids[tile.name])
        .subscribe((x: EmbedReportViewModel) => {
          this.embedReport(x, tile.element.nativeElement, ids[tile.name]);
        }));
    });
  }

/**
 *Get Report information from Report Service for internal users.
 *
 * @memberof HomeManufacturerComponent
 */
public initReportsInternal(): void {
    const ids = environment.homeReportIds.manufacturer;
    const role = this.customerService.getCustomerRoleFromString(this.stateService.effectiveRole);
    const customerId = this.stateService.effectiveChildCustomer.id.toString();

    this.reportTiles.forEach((tile) => {
      this.subscriptions.push(
        this.reportService.getInternalEmbedReport(customerId, ids[tile.name], role)
        .subscribe((x: EmbedReportViewModel) => {
          this.embedReport(x, tile.element.nativeElement, ids[tile.name]);
        }));
    });
  }

/**
 *Get Report information from Report Service for external users with a child company selected.
 *
 * @memberof HomeManufacturerComponent
 */
public initReportsChild(): void {
    const ids = environment.homeReportIds.manufacturer;
    const role = this.customerService.getCustomerRoleFromString(this.stateService.effectiveRole);

    this.reportTiles.forEach((tile) => {
      this.subscriptions.push(
        this.reportService
        .getChildEmbedReport(ids[tile.name])
        .subscribe((x: EmbedReportViewModel) => {
          this.embedReport(x, tile.element.nativeElement, ids[tile.name]);
        }));
    });
  }
/**
 *Non premium users send marketing request for "Dashboard" page.
 *
 * @memberof HomeManufacturerComponent
 */
public sendMarketingEmail(){
    this.subscriptions.push(this.internalNotificationService.sendRequestPremium(PortalPageName.Dashboard).subscribe(() => {
    },
    (error) => {
      this.errorService.navigateToErrorPage('Error sending premium account request');
    }));
  }

/**
 *Handle Power BI timeouts.
 *
 * @param token Power BI token
 * @param reportId
 * @param element
 * @param minutesToRefresh  How many minutes before the expiration to refresh. Default is one minute after expiration.
 * @memberof HomeManufacturerComponent
 */
public setTokenExpirationListener(token: EmbedTokenViewModel, reportId: string, element: any, minutesToRefresh = -1){
    const currentTime = Date.now();
    const expiration = Date.parse(token.expiration);
    const safetyInterval = minutesToRefresh * 60 * 1000;
    const timeout = expiration - currentTime - safetyInterval;

    if(timeout <= 0){
      if(this.stateService.currentUser.customerRole.toLowerCase() === CustomerRole.internal.toLowerCase()){
        this.initReportsInternal();
      }
      else if(this.isChildCustomerSelected){
        this.initReportsChild();
      }
      else{
        this.initReportsExternal();
      }
    }
    else {
      this.logService.writeLine('Report Embed Token will be updated in ' + timeout + ' milliseconds.');
      this.expirationTimers.push(
        setTimeout(() => {
          if(this.stateService.currentUser.customerRole.toLowerCase() === CustomerRole.internal.toLowerCase()){
            const role = this.customerService.getCustomerRoleFromString(this.stateService.effectiveRole);
            const customerId = this.stateService.effectiveChildCustomer.id.toString();
            this.subscriptions.push(
              this.reportService.getInternalEmbedReport(customerId, reportId, role)
              .subscribe((x: EmbedReportViewModel) => {
                this.reportService.resetEmbedToken(element, x);
                this.setTokenExpirationListener(x.embedToken, reportId, element);
              }));
          }
          else {
            this.subscriptions.push(
              this.reportService.getEmbedReport(reportId).subscribe((x: EmbedReportViewModel) => {
                this.reportService.resetEmbedToken(element, x);
                this.setTokenExpirationListener(x.embedToken, reportId, element);
              }));
          }
          this.logService.writeLine('Token reset');
        }, timeout)
      );
    }
  }
}
