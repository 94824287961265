import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DataSharingConfig } from '../config/data-sharing-config';
import { GetUserRelationsResponseModel } from '../models/get-user-relations-response-model';
import { PostUserRelationResponseModel } from '../models/post-user-relation-response-model';
import { DeleteUserRelationResponseModel } from '../models/delete-user-relation-response-model';
import { Relation } from '../models/relation-model';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  constructor(public http: HttpClient, @Inject(DataSharingConfig) public dataSharingConfig: DataSharingConfig) {}

  public getUserRelations(userUuid: string) {
    return this.http.post<GetUserRelationsResponseModel>(
      `${this.dataSharingConfig.profilesBaseUrl}/profiles/users/${userUuid}/relations/_filter`,
      {
        relations: [
          {
            type: 'consents',
            relationName: 'provides',
          },
        ],
      }
    );
  }

  public postUserRelation(userUuid: string, accepted: boolean | null) {
    return this.http.post<PostUserRelationResponseModel>(
      `${this.dataSharingConfig.profilesBaseUrl}/profiles/users/${userUuid}/relations`,
      {
        context: `trn:profiles:users:${userUuid}`,
        targetProfileTrn: accepted
          ? this.dataSharingConfig.acceptedConsentTrn
          : this.dataSharingConfig.rejectedConsentTrn,
        relationName: 'provides',
        attributes: {},
      }
    );
  }

  public deleteUserRelation(relationUuid: string | null) {
    return this.http.delete<DeleteUserRelationResponseModel>(
      `${this.dataSharingConfig.profilesBaseUrl}/profiles/relations/${relationUuid}`
    );
  }

  public getConsentRelationUuid(relations: Relation[]): any {
    const relation = relations.find(
      (relation) =>
        relation.targetProfileTrn === this.dataSharingConfig.acceptedConsentTrn ||
        relation.targetProfileTrn === this.dataSharingConfig.rejectedConsentTrn
    );
    return relation ? relation.uuid : null;
  }

  public accepted(relations: Relation[]): any {
    let accepted: boolean | null = null;
    relations.forEach((relation) => {
      if (relation.targetProfileTrn === this.dataSharingConfig.acceptedConsentTrn) accepted = true;
      if (relation.targetProfileTrn === this.dataSharingConfig.rejectedConsentTrn) accepted = false;
    });
    return accepted;
  }
}
