import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['../alerts-report.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirmEvent: EventEmitter<any> = new EventEmitter<any>();
  public alertName = '';
  constructor() { }

  ngOnInit(): void {
  }

  public close(): void {
    this.closeEvent.emit();
  }

  public confirm(): void {
    this.confirmEvent.emit();
  }

}
