<div id="forecast-selection-container">
    <div class="forecast-header">
        <h4>
            Forecast Model Settings
        </h4>
    </div>

    <div class="forecast-selector">
        <div class="form-container">
            <form [formGroup]="forecastForm" class="forecast-form" (ngSubmit)="submit()" ngNativeValidate>
                <!-- <div class="aggregation">
                    <!- May not be needed ->
                    <label for="forecastAggregation">Aggregation Level</label>

                    <div>
                        <input id="forecast-commodity-aggregation" type="radio"  value="ThirtyDays" name="forecastAggregation"
                            formControlName="forecastAggregation">
                        <label for="forecast-commodity-aggregation" class="radio-label">Commodity</label>
                    </div>
                </div> -->

                <div class="product-or-commodity">
                    <label for="forecastProductOrCommodity">Product or Commodity to Model</label>

                    <ng-select
                        formControlName="forecastProductOrCommodity"
                        id="forecast-product-or-commodity"
                        [items]="itemList"
                        [virtualScroll]="true"
                        [multiple]="false"
                        (search)="addFilter($event)"
                        (scroll)="onScroll($event)"
                        (scrollToEnd)="onScrollToEnd()"
                        (clear)="clearSearch()"
                        (close)="clearSearch()"
                        ngDefaultControl
                    >
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{item}}">{{item}}</div>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="forecast-prediction-window">
                    <label for="forecastPredictionRange">Forecast Prediction Range</label>

                    <div>
                        <input id="30-day-prediction-range" type="radio"  value="Thirty" name="forecastPredictionRange"
                            formControlName="forecastPredictionRange" class="radio-button">
                        <label for="30-day-prediction-range" class="radio-label">30 Days</label>
                    </div>

                    <div>
                        <input id="60-day-prediction-range" type="radio" value="Sixty" name="forecastPredictionRange"
                            formControlName="forecastPredictionRange" class="radio-button">
                        <label for="60-day-prediction-range" class="radio-label">60 Days</label>
                    </div>

                    <div>
                      <input id="90-day-prediction-range" type="radio" value="Ninety" name="forecastPredictionRange"
                          formControlName="forecastPredictionRange" class="radio-button">
                      <label for="90-day-prediction-range" class="radio-label">90 Days</label>
                    </div>
                </div>

                <div class="submit">
                    <button class="btn btn-primary" (click)="submit()" [disabled]="forecastForm.invalid" type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>
