<footer class="footer" role="contentinfo">
  <section class="footer__top" style="display:block;">
    <div class="footer__top-content">
      <div class="container-fluid wrap">
        <div class="row between-xs">
          <div class="col-xs-12">
            <div class="trimble-logo">
              <svg id="Layer_1" viewBox="0 0 149.5 36" xmlns="http://www.w3.org/2000/svg">
                <path class="st0"
                  d="M65.6 12.7h6.2V32h-6.2zM73.9 12.7h6.2v2.6h.1c1.3-2 3.3-3 5.5-3 1.9 0 3.8 1.2 3.9 3h.1c1.3-2 3.3-3 5.5-3 2.5 0 4.3 1.3 4.3 4.1V32h-6.2V18.3c0-1-.3-1.7-1.5-1.7-.7 0-1.2.3-1.6.8l-.4.5V32h-6.2V18.3c0-1-.3-1.7-1.5-1.7-.7 0-1.2.3-1.6.8l-.3.5V32H74V12.7h-.1zM101.6 4.6h6.2v9.2h.1c.9-.9 2.5-1.6 3.8-1.6 4.3 0 6.6 4.3 6.6 9.9 0 4.4-1.8 10.1-7.1 10.1-2.4 0-4-1.1-5.2-3l-1.2 2.8h-3.2V4.6zm6.2 21.2c-.1 1.6.8 2.5 2 2.5 1.7 0 2-1.7 2-5.3v-1.5c0-3.8 0-5.8-1.9-5.8-.8 0-1.3.5-1.7.8l-.3.3-.1 9zM119.8 4.6h6.2v27.3h-6.2zM133.9 23.1c0 1.7.3 4.6 2.8 4.6 1.5 0 2.8-1.1 3.1-2.6l4.2 1.5c-.3.9-2.2 5.3-7.7 5.3-5.9 0-8.6-4.8-8.6-10.1 0-5.5 2.8-10.1 8.6-10.1 6 0 8.1 5.1 8.1 10.2v1.3h-10.5v-.1zm4.6-3.6c0-1.8-.2-3.9-2.3-3.9-1.8 0-2.3 2.1-2.3 3.9h4.6zM65.6 4.5h6.2V10h-6.2zM145 29.9c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2m.4 0c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6s-.7-1.6-1.6-1.6c-.9 0-1.6.7-1.6 1.6m2.7 1.1h-.4l-.7-1h-.4v1h-.3v-2.2h1c.2 0 .4 0 .6.1.2.1.3.3.3.5 0 .4-.3.6-.7.6l.6 1zm-1.1-1.3c.3 0 .8.1.8-.3 0-.3-.3-.3-.5-.3h-.6v.7h.3v-.1zM35.4 4.4h19.3v5.3h-6.2v22.1h-6.9V9.7h-6.2z">
                </path>
                <path class="st0"
                  d="M51.9 12.5H58v2.9h.1c.8-2.4 3.5-3.4 6-3.4v6.3c-.8-.3-1.6-.5-2.5-.5-2.1 0-2.7.5-3.4 1.5v12.5H52l-.1-19.3zM23 25c-.1.4-.2.8-.4 1.1-.1.1-.1.2-.1.4-.3.3-.5.4-.5.5-1.4 1.2-2.6 1.8-4.3 2.4-.6.2-2 .6-3.7.5l-2.7 1.4c3.2.7 5.9.1 6.9-.3 2-.6 3.3-1.4 4.9-2.7.3-.3 2-1.7 3.2-4.1.2-.4.4-.9.6-1.4L23 25zM13.6 6.2h.8l-.9.3 2.4 1.3c1.9-.1 3.7.8 5 2.1-.3 0-.6.1-.9.1l4 2.2v-.1c.2.1.3.2.4.4l2.7 1.4c0-.1-.1-.2-.1-.3s0-.1-.1-.2c0-.1-.1-.2-.1-.3-.8-2.2-2.3-4.1-4.1-5.6l-.2-.2c-2.9-2.2-5.8-2.7-7.2-2.8h-.2c-.9-.1-1.8 0-2.7.1h-.2c-.4.1-.7.1-1 .2-.2.1-.5.1-.7.2L13 6.3c.3-.1.4-.1.6-.1M3.2 22.5c-.4-.9-.7-2.1-.7-2.6-.1-1.1-.1-1.1-.1-2.2 0-.3 0-.8.1-1.2.1-.8.3-1.8.8-3.1V9.9c-.4.6-.8 1.2-1.2 1.9-1 2-1.2 3.4-1.4 4.4-.1.5-.1 1.1-.1 1.5 0 1.2 0 1.2.1 2.4.1.7.5 2.3 1 3.4.4.9.8 1.6 1.2 2.2 0 .1.1.1.1.2.1.1.1.2.2.2v-3.9c.1.1 0 .2 0 .3">
                </path>
                <path class="st0"
                  d="M23.4 16.1c-.2.3-.4.5-.6.8.7 1.2 1.2 2.4 1.5 3.6.1-.2.3-.4.4-.6 1.1-1.8 1.3-3.9.5-6.1 0-.1-.1-.1-.1-.2-.1-.2-.1-.4-.2-.5s-.2-.2-.2-.3c-.1-.2-.2-.3-.3-.4l-.4-.2c.3 1.4.1 2.7-.6 3.9M12.7 6.3h-.1c-.6.1-1.1.2-1.7.4.5.1.9.3 1.4.4.4-.3.8-.5 1.2-.6l-.4-.2h-.4M26.9 22.9l8.4-4.6-8.2-4.4c1.2 3.6.7 6.7-.2 9M15.3 7.8c-.3 0-.7.1-1 .2 1.1.6 2.3 1.5 3.4 2.5.8-.2 1.5-.4 2.3-.5l-4.1-2.2h-.6M19.3 25.1c-4.7.7-9.6-1.5-12.5-5.2-1.1 1.3-1.8 2.6-2.1 3.8-.1.5-.1.9-.1 1.2.2.3.4.5.6.8.7.8 2.6 2.8 5.7 3.7 1.1.3 2.1.4 3.1.5l9-4.9c.1-.4.1-.7.1-1.1-1.1.6-2.4 1-3.8 1.2M7 17.1c1.1-1 2.3-2.1 3.6-3-.1-.2-.2-.4-.2-.6-.6-1.7-.4-3.4.4-4.8.1-.1.1-.2.2-.2-1-.3-1.9-.4-2.7-.3-.5.5-.9 1-1.3 1.6-.6 1.1-1 2.3-1 3.5 0 .9.2 1.8.5 2.7.1.3.3.7.5 1.1M12 12.9c0 .1.1.1.1.2 1.2-.7 2.5-1.4 3.8-1.9-1.1-.9-2.2-1.6-3.2-2.1-.1.2-.3.3-.4.5-.6 1-.7 2.2-.3 3.3zM18.9 17c.6-.1 1.2-.3 1.6-.5-.9-1.4-1.9-2.7-3.2-3.9-.2.1-.4.2-.6.2-1.3.5-2.5 1.1-3.7 1.9 1.4 1.6 3.7 2.6 5.9 2.3M7.2 6.4c.9-.5 1.8-1 3.4-1.4L3.2 1v9c1.8-2.3 3.7-3.5 4-3.6M21.9 15.2c.6-.9.7-2.1.3-3.3 0-.1-.1-.2-.1-.3-.8 0-1.8.1-2.8.4.9 1 1.8 2.1 2.6 3.2">
                </path>
                <path class="st0"
                  d="M19 23.4c1.5-.2 2.8-.7 3.8-1.5-.3-1.2-.8-2.6-1.5-3.9-.7.4-1.4.6-2.3.8-2.8.4-5.8-.9-7.6-3.1-1.3.9-2.6 2-3.6 3 2.6 3.2 7.1 5.3 11.2 4.7M5.7 18.4c-.3-.6-.6-1.2-.9-1.9-.6-1.8-.7-3.7-.3-5.4-.3.4-.6.9-.9 1.4-.1.3-.3.6-.4.8v8.8c.6-1.1 1.4-2.4 2.5-3.7M25.3 22l-.6.6c0 .2 0 .4.1.7.2-.4.4-.8.5-1.3M3.9 26.9c-.2-.3-.4-.5-.6-.7v9.5l8-4.3c-.3-.1-.6-.1-.9-.2-3.6-1.1-5.8-3.4-6.5-4.3M27.8 29.7c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2m.4 0c0 .9.7 1.6 1.6 1.6s1.6-.7 1.6-1.6-.7-1.6-1.6-1.6c-.9 0-1.6.7-1.6 1.6m2.7 1.1h-.4l-.7-1h-.4v1h-.3v-2.2h1c.2 0 .4 0 .6.1s.3.3.3.5c0 .4-.3.6-.7.6l.6 1zm-1.1-1.3c.3 0 .8.1.8-.3 0-.3-.3-.3-.5-.3h-.6v.7h.3v-.1z">
                </path>
              </svg>
            </div>
          </div>
        </div>

        <div class="row between-xs">
          <div class="col-xs-12 col-sm-12 col-md-4">
            <p class="font-sans-serif">
              Trimble provides the widest breadth of technology solutions
              for each phase of the building lifecycle. With the industry’s
              only constructible process and a full range of tools and
              content to streamline team collaboration, Trimble solutions
              make data from complex projects more meaningful and actionable
              to improve productivity and achieve operational excellence.
            </p>

            <p>
              <a href="https://www.trimble.com/" target="_blank" rel="noopener noreferrer" title="Opens in a new window">More About Trimble</a>
            </p>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-7">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md">
                <nav class="footer-links pad-bottom-lg pad-bottom-md-none">
                  <ul class="footer-links__list list-unstyled">
                    <li class="footer-links__list-item">
                      <a href="https://mep.trimble.com/en/support-north-america" target="_blank" rel="noopener noreferrer" title="Opens in a new window">Support</a>
                    </li>
                    <li class="footer-links__list-item">
                      <a href="https://go.trimble.com/construction-analytics-general-contact-us.html" routerLinkActive="active">Request a Demo</a>
                    </li>
                    <li class="footer-links__list-item">
                      <a href="https://mep.trimble.com/en/legal">Terms of Service</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div class="pad-v-sm">
              <hr />
            </div>

            <ul class="social-links width-100 list-inline list-inline--center pad-bottom-lg pad-bottom-md-none">
              <li class="social-links__item twitter">
                <a href="https://twitter.com/TrimbleMEP" target="_blank" rel="noopener noreferrer" rel="noopener noreferrer" title="Opens in a new window"><svg
                    viewBox="0 0 512 512">
                    <path
                      d="M419.6 168.6c-11.7 5.2-24.2 8.7-37.4 10.2 13.4-8.1 23.8-20.8 28.6-36 -12.6 7.5-26.5 12.9-41.3 15.8 -11.9-12.6-28.8-20.6-47.5-20.6 -42 0-72.9 39.2-63.4 79.9 -54.1-2.7-102.1-28.6-134.2-68 -17 29.2-8.8 67.5 20.1 86.9 -10.7-0.3-20.7-3.3-29.5-8.1 -0.7 30.2 20.9 58.4 52.2 64.6 -9.2 2.5-19.2 3.1-29.4 1.1 8.3 25.9 32.3 44.7 60.8 45.2 -27.4 21.4-61.8 31-96.4 27 28.8 18.5 63 29.2 99.8 29.2 120.8 0 189.1-102.1 185-193.6C399.9 193.1 410.9 181.7 419.6 168.6z">
                    </path>
                  </svg></a>
              </li>
              <li class="social-links__item facebook">
                <a href="https://www.facebook.com/TrimbleMEP/" target="_blank"
                  title="Opens in a new window" rel="noopener noreferrer"><svg viewBox="0 0 512 512">
                    <path
                      d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z">
                    </path>
                  </svg></a>
              </li>
              <li class="social-links__item youtube">
                <a href="https://www.youtube.com/c/TrimbleMEP" target="_blank"
                  title="Opens in a new window" rel="noopener noreferrer"><svg viewBox="0 0 512 512">
                    <path
                      d="M422.6 193.6c-5.3-45.3-23.3-51.6-59-54 -50.8-3.5-164.3-3.5-215.1 0 -35.7 2.4-53.7 8.7-59 54 -4 33.6-4 91.1 0 124.8 5.3 45.3 23.3 51.6 59 54 50.9 3.5 164.3 3.5 215.1 0 35.7-2.4 53.7-8.7 59-54C426.6 284.8 426.6 227.3 422.6 193.6zM222.2 303.4v-94.6l90.7 47.3L222.2 303.4z">
                    </path>
                  </svg></a>
              </li>
              <li class="social-links__item email">
                <a href="https://www.linkedin.com/showcase/trimble-mep" target="_blank"
                  title="Opens in a new window" rel="noopener noreferrer"><svg viewBox="0 0 512 512">
                    <path
                      d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z">
                    </path>
                  </svg></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- OneTrust Cookies Consent Notice start -->
    <!-- <script src="https://cdn.cookielaw.org/consent/4d0fcae6-44d8-488c-af62-3633faee64e7.js" type="text/javascript"
      charset="UTF-8"></script> -->
    <script type="text/javascript">
        //function OptanonWrapper() { }
    </script>
    <!-- OneTrust Cookies Consent Notice end -->
  </section>

  <section class="footer__bottom">
    <div class="container-fluid wrap">
      <div class="row between-xs">
        <div class="col-xs-12 col-sm">
          <nav class="legal-links">
            <ul class="legal-links__list list-inline list-inline--left">
              <li class="legal-links__list-item">
                <a href="http://www.trimble.com/Corporate/Privacy.aspx" target="_blank"
                  title="Opens in a new window" rel="noopener noreferrer">Privacy Policy</a>
              </li>
              <li class="legal-links__list-item">© {{currentYear}} Trimble Inc.</li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </section>
</footer>