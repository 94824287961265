import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfilesService } from '@hcworkspace/analytics-portal/data-sharing';
import { UserViewModel } from 'apps/analytics-portal/src/app/models/user-view-model';
import { DataSharingService } from 'apps/analytics-portal/src/app/services/data-sharing.service';
import { IdentityService } from 'apps/analytics-portal/src/app/services/identity.service';
import { StateService } from 'apps/analytics-portal/src/app/services/state.service';


@Component({
  selector: 'app-data-sharing-wrapper',
  templateUrl: './data-sharing-wrapper.component.html',
  styleUrls: ['./data-sharing-wrapper.component.scss']
})
export class DataSharingWrapperComponent implements OnInit {

  public userId: string;

  constructor(
    public stateService: StateService,
    public identityService: IdentityService,
    public profilesService: ProfilesService,
    public dataSharingService: DataSharingService,
    public activatedRoute: ActivatedRoute) {
      this.stateService.setHideHeaderFooterAndNav(true);
    }

  async ngOnInit(): Promise<void> {
    const userRelationsList = await this.identityService.getUserRelationsList();
    const dataSharingPreference = this.profilesService.accepted(userRelationsList);
    this.stateService.setDataSharingAccepted(dataSharingPreference);
  }

}
