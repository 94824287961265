import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorService } from '../../../../src/app/services/error.service';
import { IdentityService } from '../../../../src/app/services/identity.service';
import { ModalService } from '../../../../src/app/services/modal.service';
import { StateService } from '../../../../src/app/services/state.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  public additionalErrorInformation: string = null;

  constructor(
    public errorService: ErrorService,
    public stateService: StateService,
    public identityService: IdentityService,
    public modalService: ModalService) {
    this.stateService.setHideHeaderFooterAndNav(true);
  }

  ngOnInit(): void {
    this.modalService.hideAll();
    this.additionalErrorInformation = this.errorService.errorMessage;
  }

  ngOnDestroy(): void {
    this.stateService.setHideHeaderFooterAndNav(false);
  }

  public logout(): void {
    this.identityService.logout();
  }
}
