/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Injectable } from '@angular/core';
import { StorageType } from '../models/storage-type';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public readonly keyReportRoleChoice: string = 'reportRoleChoice';
  public readonly keyParentCustomerId: string = 'parentCustomerId';
  public readonly keyParentCustomerName: string = 'parentCustomerName';
  public readonly keyRedirectUrl: string = 'redirectUrl';
  public readonly keyChildCustomerId: string = 'childCustomerId';
  public readonly keyChildCustomerName: string = 'childCustomerName';
  public isClientStorageSupported = false;

  constructor() {
    this.isClientStorageSupported = window.sessionStorage !== undefined;
  }

  // ReportRoleChoice

  public getReportRoleChoice(): string {
    return this.read(this.keyReportRoleChoice, StorageType.session);
  }

  public setReportRoleChoice(value: string): void {
    this.write(this.keyReportRoleChoice, value, StorageType.session);
  }

  public clearReportRoleChoice(): void {
    this.clear(this.keyReportRoleChoice, StorageType.session);
  }

  // ReportParentCustomerId

  public getParentCustomerId(): string {
    return this.read(this.keyParentCustomerId, StorageType.session);
  }

  public getParentCustomerName(): string {
    return this.read(this.keyParentCustomerName, StorageType.session);
  }

  public setParentCustomer(id: string, name: string): void {
    this.write(this.keyParentCustomerId, id, StorageType.session);
    this.write(this.keyParentCustomerName, name, StorageType.session);
  }

  public clearParentCustomer(): void {
    this.clear(this.keyParentCustomerId, StorageType.session);
    this.clear(this.keyParentCustomerName, StorageType.session);
  }

  // ReportChildCustomerId

  public getChildCustomerId(): string {
    return this.read(this.keyChildCustomerId, StorageType.session);
  }

  public getChildCustomerName(): string {
    return this.read(this.keyChildCustomerName, StorageType.session);
  }

  public setChildCustomer(id: string, name: string): void {
    this.write(this.keyChildCustomerId, id, StorageType.session);
    this.write(this.keyChildCustomerName, name, StorageType.session);
  }

  public clearChildCustomer(): void {
    this.clear(this.keyChildCustomerId, StorageType.session);
    this.clear(this.keyChildCustomerName, StorageType.session);
  }

  // RedirectUrl

  public getRedirectUrl(): string {
    return this.read(this.keyRedirectUrl, StorageType.local);
  }

  public setRedirectUrl(value: string): void {
    this.write(this.keyRedirectUrl, value, StorageType.local);
  }

  public clearRedirectUrl(): void {
    this.clear(this.keyRedirectUrl, StorageType.local);
  }

  // Base methods

  public clear(key: string, type: StorageType): void {
    if (!this.isClientStorageSupported) {
      return;
    }
    const storage = this.getStorageFromType(type);
    return storage.removeItem(key);
  }

  public read(key: string, type: StorageType): string {
    if (!this.isClientStorageSupported) {
      return null;
    }
    const storage = this.getStorageFromType(type);
    return storage.getItem(key);
  }

  public write(key: string, value: string, type: StorageType): void {
    if (!this.isClientStorageSupported) {
      return;
    }
    const storage = this.getStorageFromType(type);
    storage.setItem(key, value);
  }

  public getStorageFromType(type: StorageType): Storage {
    let result: Storage = null;

    switch (type) {
      case StorageType.session:
        result = window.sessionStorage;
        break;

      case StorageType.local:
        result = window.localStorage;
        break;
    }

    return result;
  }
}
