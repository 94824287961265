<h1 *ngIf="alert" id="alertSetupTitle">{{alert.name ? alert.name : "Alerts Setup"}}</h1>
<p *ngIf="alert.name" id="alertSetupDescription">Currently editing alert.</p>
<p *ngIf="!alert.name" id="alertSetupDescription">Create and manage alerts to receive an email notification for product-related activity.</p>
<hr class="divider">
<div id="step-1" *ngIf="step===1">
    <p class="info-text">Details <span class="step-text" id="alertSetupStep1"><em>Step 1 of 4</em></span></p>
    <div class="form-container">
        <form [formGroup]="alertForm" class="setup-form" (ngSubmit)="proceedToNextStep(2)" ngNativeValidate>
            <div class="form-border shadow-sm">
              <div class="form-wrapper-1">
                <div class="form-group step-one-form">
                  <div>
                    <label for="monitorSelection">What do you want to monitor?</label>
                    <div>
                        <input id="alertSetupMonitorAdImpressions" type="radio"  value="ADIMPRESSIONS" name="monitorSelection"
                            formControlName="monitorSelection">
                        <label for="alertSetupMonitorAdImpressions" class="radio-label">Ad Impression Count</label>
                    </div>

                    <div>
                        <input id="alertSetupMonitorAdClicks" type="radio" value="ADCLICKS" name="monitorSelection"
                            formControlName="monitorSelection">
                        <label for="alertSetupMonitorAdClicks" class="radio-label">Ad Click Count</label>
                    </div>

                    <div>
                      <input id="alertSetupMonitorSearch" type="radio" value="SEARCH" name="monitorSelection"
                          formControlName="monitorSelection">
                      <label for="alertSetupMonitorSearch" class="radio-label item-search-count-label">Item Search Count</label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                    <label for="triggerSelection">How frequently do you want to receive notifications?</label>
                    <div>
                        <input id="alertSetupTriggerDaily" type="radio" value="DAILY" name="triggerSelection"
                            formControlName="triggerSelection" (click)="onTriggerFreqChange('DAILY')">
                        <label for="alertSetupTriggerDaily" class="radio-label">Daily</label>
                    </div>

                    <div>
                        <input id="alertSetupTriggerWeekly" type="radio" value="WEEKLY" name="triggerSelection"
                            formControlName="triggerSelection" (click)="onTriggerFreqChange('WEEKLY')">
                        <label for="alertSetupTriggerWeekly" class="radio-label">Weekly</label>
                    </div>

                    <div>
                        <input id="alertSetupTriggerMonthly" type="radio" value="MONTHLY" name="triggerSelection"
                            formControlName="triggerSelection" (click)="onTriggerFreqChange('MONTHLY')">
                        <label for="alertSetupTriggerMonthly" class="radio-label">Monthly</label>
                    </div>

                    <div>
                        <input id="alertSetupTriggerCount" type="radio" value="COUNT" name="triggerSelection"
                            formControlName="triggerSelection" (click)="onTriggerFreqChange('COUNT')">
                        <label for="alertSetupTriggerCount" class="radio-label">Only when a specific count is reached</label>
                    </div>
                    <div class="form-group">
                        <label for="alertSetupInputCount">What count should trigger a notification?</label>
                        <div><input class="form-control" id="alertSetupInputCount" type="number" placeholder="e.g. 20"
                                name="triggerCount" formControlName="triggerCount" min="1" max="9999" oninput="this.value =
                                !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="alertSetupDateRange">What date range should be included in the notification?</label>
                    <input type="text"
                        placeholder="Select a date"
                        class="form-control"
                        id="alertSetupDateRange"
                        name="start"
                        formControlName="start"
                        bsDaterangepicker
                        [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-dark-blue' }"
                        (bsValueChange)="validateDateRange($event)" >
                        <label *ngIf="!validDateRange && dateRangeRequired" style="color: red;" for="start-date">Please select an end date that is in the future.</label>
                </div>

              </div>
            </div>
            <button class="btn btn-primary next-button" [disabled]="this.alertForm.value.monitorSelection === '' ||
              this.alertForm.value.triggerSelection === '' ||
              this.alertForm.value.start === null ||
              (this.alertForm.value.triggerSelection === 'COUNT' && this.alertForm.value.triggerCount === null) ||
              !validDateRange || this.alertForm.value.triggerCount > 9999" type="submit"
              id="alertSetupNext1">Next</button>
            <button class="btn btn-outline-dark next-button" id="alertSetupCancel1" (click)="cancel()">Cancel</button>
        </form>
    </div>
</div>

<div id="step-2" *ngIf="step===2">
  <p class="info-text">Notification Filters <span class="step-text" id="alertSetupStep"><em>Step 2 of 4</em></span></p>
    <p class="info-text" id="alertSetupAdsDescription" *ngIf="adSelection">Which advertisement(s) do you want to include in each notification report?</p>
    <div class="form-container">
        <form [formGroup]="alertForm" class="setup-form" (ngSubmit)="proceedToNextStep(3)">
            <div class="form-border shadow-sm">
              <div class="ads-page" id="alertSetupAdsPage" *ngIf="adSelection">
                <p *ngIf="noAds" id="alertSetupAdNoneFound">No active ads found for this company.</p>
                <div class="ad-container" *ngFor="let ad of adList; let i = index">
                    <div class="form-group">
                        <div style="display: flex; margin: 0px; width: 100%;">
                            <input class="form-control" [id]="'alertSetupAdsCheckbox'+i" type="checkbox" [value]="ad.id" [checked]="this.alertForm.value.selectedAds.indexOf(ad.id) > -1" (change)="onCheckChange($event)">
                            <p class="description-text" [id]="'alertSetupAdsDescription'+i">{{ad.description}}</p>
                            <div style="flex-grow: 1;"></div>
                            <p>{{ad.webPageName}}</p>
                        </div>
                        <img src="{{ad.adImageUri}}" (error)="ad.adImageUri = '../../../assets/images/Blocker-Image.png'" class="ad-image shadow-sm" [id]="'alertSetupAdImage' + i" alt="Ad-Image">
                    </div>
                </div>
              </div>
                <div class="searches-page" *ngIf="searchSelection">
                  <div class="form-group">
                    <p style="margin-bottom:0">Regional Filters</p>
                    <label for="region-state" class="regional" id="alertSetupLabelState"> State(s)</label>
                    <div class="regional">
                      <ng-select
                          id="alertSetupInputState"
                          formControlName="states"
                          [items]="regionSelect"
                          bindLabel="stateName"
                          [closeOnSelect]="false"
                          [multiple]="true"
                          [searchFn]="searchStates"
                          [clearable]="!regionalSelectAll"
                          (clear)="selectAllStates()"
                          (add)="selectState($event)"
                          (remove)="removeState($event)">
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!regionalSelectAll">
                            <div

                            class="ng-value" *ngFor="let item of items | slice:0:10; ; let i = index">
                                <span class="ng-value-label" [id]="'alertSetupCardState'+i">{{item.stateName}}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true" [id]="'alertSetupClearCardState' + i">×</span>
                            </div>
                            <div class="ng-value" *ngIf="items.length > 10">
                                <span class="ng-value-label">{{items.length - 10}} more...</span>
                            </div>
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-clear="clearRegions" *ngIf="regionalSelectAll">
                            <div class="ng-value">
                            <span class="ng-value-label">All States Selected</span>
                            </div>
                          </ng-template>
                          <ng-template ng-header-tmp>
                            <div>
                              <button class="btn btn-link" type="button" (click)="selectAllStates()"> Select All </button>
                            </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                            [ngModelOptions]="{ standalone : true }"/> {{item.stateName}}
                          </ng-template>
                      </ng-select>
                    </div>
                    <label for="region-county" class="regional" id="alertSetupLabelCounties"> County(ies)</label>
                    <div *ngIf="missingCountyMessage" class="regional">
                      <label class="text-danger">
                        Select at least one county per state.
                      </label>
                    </div>
                    <div class="regional">
                      <ng-select
                          id="alertSetupInputCounties"
                          formControlName="counties"
                          [items]="selectedStates"
                          bindLabel="countyId"
                          [closeOnSelect]="false"
                          [clearable]="!regionalSelectAll"
                          (clear)="invalidRegions(false)"
                          groupBy="counties"
                          [selectableGroup]="true"
                          [multiple]="true"
                          [searchFn]="searchCounties"
                          (remove)="invalidRegions(false)"
                          (add)="invalidRegions(true)"
                          notFoundText="Select more states to see county options">
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!regionalSelectAll">
                            <div class="ng-value" *ngFor="let item of items | slice:0:10; let i = index">
                                <span class="ng-value-label" [id]="'alertSetupCardCounty'+i">{{formatCountyCard(item)}}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true" [id]="'alertSetupClearCardCounty' + i">×</span>
                            </div>
                            <div class="ng-value" *ngIf="items.length > 10">
                                <span class="ng-value-label">{{items.length - 10}} more...</span>
                            </div>
                          </ng-template>
                          <ng-template ng-multi-label-tmp let-clear="clearRegions" *ngIf="regionalSelectAll">
                            <div class="ng-value">
                            <span class="ng-value-label">All Counties Selected</span>
                            </div>
                          </ng-template>
                          <ng-template *ngIf="!regionalSelectAll" ng-header-tmp>
                            <div>
                              <button class="btn btn-link" type="button" (click)="selectAllCounties()"> Select All </button>
                            </div>
                          </ng-template>
                          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                            [ngModelOptions]="{ standalone : true }"/> {{item.stateName}}
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                            [ngModelOptions]="{ standalone : true }"/> {{item.countyName}}
                          </ng-template>
                      </ng-select>
                      <p></p>
                    </div>
                      <label for="catalog-number" id="alertSetupLabelCatalogs"> Product Category(ies) and Catalog Number(s)</label>
                      <div *ngIf="maxCatalogKeysMessage">
                      <label class="text-danger">
                        Please limit your selection to 30,000 catalog numbers, select all, or create multiple alerts.
                      </label>
                    </div>
                      <div>
                        <ng-select
                            id="alertSetupInputCatalogs"
                            formControlName="catalogNumbers"
                            [items]="catalogNumberBuffer"
                            bindLabel="catalogKey"
                            [virtualScroll]="true"
                            [closeOnSelect]="false"
                            [multiple]="true"
                            notFoundText="No active items found for this company."
                            groupBy="commodity"
                            [selectableGroup]="true"
                            [selectableGroupAsModel]="false"
                            (search)="searchCommodities($event)"
                            [searchFn]="searchFn"
                            (scroll)="onScroll($event)"
                            (scrollToEnd)="onScrollToEnd()"
                            (clear)="clearAllCatalogs()"
                            (add)="maxedCatalogKeys()"
                            (remove)="removeCatalog()">
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!largeSelectAll">
                              <div class="ng-value" *ngFor="let item of items | slice:0:10; let i = index">
                                  <span class="ng-value-label" [id]="'alertSetupCardCatalog'+i">{{item.catalogKey}}</span>
                                  <span class="ng-value-icon right" (click)="clear(item)" [id]="'alertSetupClearCardCatalog' + i" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 10">
                                  <span class="ng-value-label">{{items.length - 10}} more...</span>
                              </div>
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-clear="clearAllCatalogs" *ngIf="largeSelectAll">
                              <div class="ng-value">
                              <span class="ng-value-label">All {{hideCatalogs.length}} Selected</span>
                              </div>
                            </ng-template>
                            <ng-template ng-header-tmp>
                              <div>
                                <button class="btn btn-link" type="button" id="alertSetupSelectAllCatalogs" (click)="selectAllCatalogs()"> Select All </button>
                              </div>
                            </ng-template>
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="!largeSelectAll">
                              <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                              [ngModelOptions]="{ standalone : true }"/> {{item.commodity | uppercase}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="!largeSelectAll">
                              <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                              [ngModelOptions]="{ standalone : true }"/> {{item.catalogKey}}
                            </ng-template>
                            <ng-template ng-notfound-tmp *ngIf="largeSelectAll">
                              <div class="ng-option disabled">
                                  All Catalogs Selected
                              </div>
                          </ng-template>
                        </ng-select>
                      </div>
                  </div>
                </div>
            </div>
            <button class="btn btn-outline-dark next-button" id="alertSetupCancel2" (click)="cancel()">Cancel</button>
            <button class="btn btn-primary next-button" id="alertSetupNext2" [disabled]="disableFilterSelection()" type="submit">Next</button>
            <button class="btn btn-outline-dark next-button" id="alertSetupBack2" (click)="proceedToNextStep(1)" type="submit">Back</button>
        </form>
    </div>
</div>

<div id="step-3" *ngIf="step===3">
    <p class="info-text">Details <span class="step-text" id="alertSetupStep"><em>Step 3 of 4</em></span></p>
    <div class="form-container">
        <form [formGroup]="alertForm" class="setup-form" (ngSubmit)="proceedToNextStep(4)">
            <div class="form-border shadow-sm">
              <div class="searches-page">
                <div class="form-group">
                    <label for="alertSetupInputName">What is the name of this alert?</label>
                    <div><input id="alertSetupInputName" class="form-control" placeholder="Name" name="alertName"
                            formControlName="alertName" maxlength="100">
                    </div>
                </div>

                <div class="form-group">
                    <label for="alertSetupInputEndDate">When did you want to stop receiving email notifications?</label>
                    <div *ngIf="adjustedDate"><label class="text-danger">Existing date ({{formatDate(this.alert.notificationEndDate)}}) is no longer valid and will be changed.</label></div>
                    <input type="text"
                        id="alertSetupInputEndDate"
                        placeholder="Datepicker"
                        class="form-control"
                        name="notificationEndDate"
                        formControlName="notificationEndDate"
                        bsDatepicker
                        [bsConfig]="{ isAnimated: true, adaptivePosition: true, containerClass: 'theme-dark-blue' }"
                        [bsValue]="this.minDate"
                        [minDate]="this.minDate">
                </div>
                <div class="form-group">
                    <label for="alertSetupSelectEmailSharing">Who should receive notifications?</label>
                    <ng-select
                        id="alertSetupSelectEmailSharing"
                        formControlName="alertsEmailSharing"
                        [items]="emails"
                        [multiple]="true"
                        addTagText="Add "
                        [addTag]="newEmail"
                        (add)="onAdd($event)"
                        (remove)="onRemove($event)"
                        (change)="validateEmailList()">
                    </ng-select>
                  <label *ngIf="this.validEmailList === false" style="color: red;" for="email-sharing">One or more email addresses are invalid.</label>
                </div>
              </div>
            </div>
            <button class="btn btn-outline-dark next-button" id="alertSetupCancel3" (click)="cancel()" type="submit">Cancel</button>
            <button class="btn btn-primary next-button" type="submit" id="alertSetupNext3"
            [disabled]="this.alertForm.value.alertName === null || this.alertForm.value.alertName === '' ||
            this.alertForm.value.notificationEndDate === null || this.validEmailList === false">Next</button>
            <button class="btn btn-outline-dark next-button" id="alertSetupBack3" (click)="proceedToNextStep(2)" type="submit">Back</button>
        </form>
    </div>
</div>

<div id="step-4" *ngIf="step===4">
    <p class="info-text" id="alertSetupSummaryTitle">Summary <span class="step-text"><em id="alertSetupStep">Step 4 of 4</em></span></p>
    <div class="form-container">
        <form [formGroup]="alertForm" class="setup-form" (ngSubmit)="submit(true)">
            <div class="form-border shadow-sm">
              <div>
                <div class="form-group">
                    <label for="monitorSelection">What do you want to monitor?</label>
                    <div>
                        <input id="alertSetupSummaryMonitorAdImpressions" type="radio" value="ADIMPRESSIONS" name="monitorSelection"
                            formControlName="monitorSelection">
                        <label for="alertSetupSummaryMonitorAdImpressions" class="radio-label">Ad Impressions Count</label>
                    </div>

                    <div>
                        <input id="alertSetupSummaryMonitorAdClicks" type="radio" value="ADCLICKS" name="monitorSelection"
                            formControlName="monitorSelection">
                        <label for="alertSetupSummaryMonitorAdClicks" class="radio-label">Ad Click Count</label>
                    </div>

                    <div>
                      <input id="alertSetupSummaryMonitorSearch" class="item-search-count" type="radio" value="SEARCH" name="monitorSelection"
                          formControlName="monitorSelection">
                      <label for="alertSetupSummaryMonitorSearch" class="radio-label item-search-count-label">Item Search Count</label>
                  </div>
                </div>

                <div class="form-group">
                    <label for="triggerSelection">How frequently do you want to receive notifications?</label>
                    <div>
                        <input id="alertSetupSummaryTriggerDaily" type="radio" value="DAILY" name="triggerSelection"
                            formControlName="triggerSelection">
                        <label for="alertSetupSummaryTriggerDaily" class="radio-label">Daily</label>
                    </div>

                    <div>
                        <input id="alertSetupSummaryTriggerWeekly" type="radio" value="WEEKLY" name="triggerSelection"
                            formControlName="triggerSelection">
                        <label for="alertSetupSummaryTriggerWeekly" class="radio-label">Weekly</label>
                    </div>

                    <div>
                        <input id="alertSetupSummaryTriggerMonthly" type="radio" value="MONTHLY" name="triggerSelection"
                            formControlName="triggerSelection">
                        <label for="alertSetupSummaryTriggerMonthly" class="radio-label">Monthly</label>
                    </div>

                    <div>
                        <input id="alertSetupSummaryTriggerCount" type="radio" value="COUNT" name="triggerSelection"
                            formControlName="triggerSelection">
                        <label for="alertSetupSummaryTriggerCount" class="radio-label">Only when a specific count is reached</label>
                    </div>
                    <div class="form-group" *ngIf="triggerIsCount">
                        <label for="alertSetupCount">What count should trigger a notification?</label>
                        <div><input class="form-control  summary-input" id="alertSetupCount" type="number" placeholder="e.g. 20"
                                name="triggerCount" formControlName="triggerCount">
                        </div>
                    </div>
                </div>


                <div class="form-group" *ngIf="triggerIsCount">
                    <label for="alertSetupDateRange">What date range should be included in the notification?</label>
                    <input type="text"
                        placeholder="Select a date"
                        class="form-control  summary-input"
                        id="alertSetupDateRange"
                        name="start"
                        formControlName="start"
                        bsDaterangepicker
                        [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-dark-blue' }" >
                </div>

                <div class="form-group" *ngIf="adSelection">
                    <label for="ads-selection">Number of Ads Selected</label>
                    <div style="display: flex;">
                        <div><input class="form-control summary-input" id="ads-selection" value="{{this.alertForm.value.selectedAds.length}}" [disabled]="true">
                    </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="searchSelection">
                  <label for="alertSetupSummaryStates">States Selected</label>
                  <div>
                      <div><input class="form-control summary-input" id="alertSetupSummaryStates" value="{{statesSelected}}" [disabled]="true">
                  </div>
                  </div>
                  <label for="alertSetupSummaryCounties">Counties Selected</label>
                  <div>
                      <div><input class="form-control summary-input" id="alertSetupSummaryCounties" value="{{countiesSelected}}" [disabled]="true">
                  </div>
                  </div>
                  <label for="alertSetupSummaryCatalogs">Number of Catalog Numbers Selected</label>
                  <div>
                      <div><input class="form-control summary-input" id="alertSetupSummaryCatalogs" value="{{catalogsSelected}}" [disabled]="true">
                  </div>
                  </div>
                </div>
                <div class="form-group">
                    <label for="alertSetupSummaryName">What is the name of this alert?</label>
                    <div><input class="form-control summary-input" id="alertSetupSummaryName" placeholder="Name" name="alertName"
                            formControlName="alertName">
                    </div>
                </div>
                <div class="form-group">
                    <label for="alertSetupSummaryEndDate">When did you want to stop receiving email notifications?</label>
                    <input type="text"
                        id="alertSetupSummaryEndDate"
                        placeholder="n/a"
                        class="form-control summary-input"
                        name="notificationEndDate"
                        formControlName="notificationEndDate"
                        bsDatepicker
                        [disabled] = "true"
                        [bsConfig]="{ isAnimated: true, adaptivePosition: true, containerClass: 'theme-dark-blue' }"
                        [bsValue]="this.minDate"
                        [minDate]="this.minDate">
               </div>
               <div class="form-group">
                <label for="alertSetupSummaryEmail">Who should receive notifications?</label>
                <ng-select
                id="alertSetupSummaryEmail"
                formControlName="alertsEmailSharing"
                [items]="emails"
                [multiple]="true"
                addTagText="Add "
                [addTag]="newEmail"
                [disabled]="true">
              </ng-select>
            </div>

              </div>
            </div>
            <button class="btn btn-outline-dark next-button" id="alertSetupCancel4" (click)="cancel()" type="button">Cancel</button>
            <button class="btn btn-primary next-button" id="alertSetupSubmit" type="submit">{{alert.name ? "Save" : "Submit"}}</button>
            <button class="btn btn-outline-dark next-button" id="alertSetupBack4" (click)="proceedToNextStep(3)" type="button">Back</button>
        </form>
    </div>
</div>
