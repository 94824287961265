<nav id="caHeader">
  <div id="headerButtonMenu">
    <a
      data-modus-item="menu-btn"
      class="btn btn-lg btn-icon-only btn-text-dark mr-2"
      (click)="toggleSidebar()"
      ><em class="modus-icons menu-btn">menu</em></a
    >
  </div>
  <div id="headerLogo">
    <a routerLink="/home">
      <img
        src="../assets/images/MEP-Trimble-Analytics-Logo-RGB-Horizontal-v01-r00.svg"
        alt="logo"
      />
    </a>
  </div>
  <h1 *ngIf="hasChildren" id="headerParentCustomerName"><a
    target="_blank"
    (click)="openModalProfile(true)"
    tooltip="Choose Customer">
    {{parentCustomerName()}}
  </a></h1>
  <h1 *ngIf="!hasChildren" id="parentCustomerName">
    {{parentCustomerName()}}
  </h1>
  <h1 *ngIf="childIsSelected()" class="customer-breadcrumb">&gt;</h1>
  <h1 *ngIf="childIsSelected()" id="headerChildCustomerName">{{childCustomerName()}}</h1>
  <div id="headerButtonProfile">
    <a
      class="btn btn-lg btn-icon-only btn-text-dark"
      tooltip="My Profile"
      data-placement="bottom"
      (click)="openModalProfile(false)"
    >
      <em class="modus-icon material-icons">person</em>
    </a>
  </div>
  <div id="headerButtonLogout">
    <a
      class="btn btn-lg btn-icon-only btn-text-dark"
      tooltip="Logout"
      data-placement="bottom"
      (click)="logout()"
    >
      <em class="modus-icon material-icons">exit_to_app</em>
    </a>
  </div>
</nav>
