import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertMonitor } from '../../../models/alert-monitor';
import { AlertTrigger } from '../../../models/alert-trigger';
import { AlertViewModel } from '../../../models/alert-view-model';
import { EmbedReportViewModel } from '../../../models/embed-report-view-model';
import { EmbedTokenViewModel } from '../../../models/embed-token-view-model';
import { RegionalFiltersViewModel } from '../../../models/regional-filters-view-model';
import { RegionalViewModel } from '../../../models/regional-view-model';
import { LogService } from '../../../services/log.service';
import { ModalService, ModalSize } from '../../../services/modal.service';
import { ReportsService } from '../../../services/reports.service';
import { StateService } from '../../../services/state.service';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-alerts-report',
  templateUrl: './alerts-report.component.html',
  styleUrls: ['./alerts-report.component.scss']
})
export class AlertsReportComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() alert: AlertViewModel = null;
  @Input() regionFilters: RegionalFiltersViewModel[] = null;
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('report', { read: ElementRef }) report: ElementRef;

  public bsModalRef: BsModalRef;
  public subscriptions: Subscription[] = [];
  public catalogKeys: string[];
  public regions: string[];
  public regionsAll: string[];
  public emails: string[];
  private expirationTimers: any[] = [];

  constructor(public modalService: ModalService,
    public reportService: ReportsService,
    public stateService: StateService,
    public logService: LogService ) { }

  public get triggerType(): string {
    const triggerNum = +this.alert.trigger;
    if(triggerNum === AlertTrigger.DAILY ||
      triggerNum === AlertTrigger.MONTHLY ||
      triggerNum === AlertTrigger.WEEKLY){
      return 'frequency';
    }
    else if(triggerNum === AlertTrigger.COUNT){
      return 'count';
    }
    else{
      return null;
    }
  }

  public get trigger(): string {
    const triggerNum = this.alert.trigger;
    switch(triggerNum){
      case AlertTrigger.COUNT:
        let counter = this.monitor;
        counter = counter.substring(counter.indexOf(' '));
        return this.alert.count + counter;
      case AlertTrigger.DAILY:
        return 'Daily';
      case AlertTrigger.WEEKLY:
        return 'Weekly';
      case AlertTrigger.MONTHLY:
        return 'Monthly';
      default:
        return null;
    }
  }

  public get monitor(): string {
    switch(this.alert.monitor){
      case AlertMonitor.ADCLICKS:
        return 'Ad Clicks';
      case AlertMonitor.ADIMPRESSIONS:
        return 'Ad Impressions';
      case AlertMonitor.SEARCH:
        return 'Item Searches';
      case AlertMonitor.MODELDOWNLOADS:
        return 'Model Downloads';
      case AlertMonitor.PRODUCTDOWNLOADS:
        return 'Product Downloads';
      default:
        return null;
    }
  }

  public get dataFilter(): string {
    if(this.alert.ads.length > 1){
      return 'Multiple Ads Selected';
    }
    else if(this.alert.ads.length === 0){
      return 'No Ads Selected';
    }
    else{
      return 'Single Ad Selected';
    }
  }

  public get reportLink(): string {
    if(this.alert.monitor === AlertMonitor.ADCLICKS ||
      this.alert.monitor === AlertMonitor.ADIMPRESSIONS){
        return '/reports/ads';
    } else if(this.alert.monitor === AlertMonitor.SEARCH){
      return '/reports/itemSearch';
    }
    else {
      return '/reports';
    }
  }

  public isAdType(monitor: AlertMonitor): boolean {
    return monitor === AlertMonitor.ADCLICKS || monitor === AlertMonitor.ADIMPRESSIONS;
  }

  public isSearchType(monitor: AlertMonitor): boolean {
    return monitor === AlertMonitor.SEARCH;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initReportsExternal();
  }
  ngOnDestroy(): void {
    this.expirationTimers.forEach(e => clearInterval(e));
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  ngOnChanges(changes: SimpleChanges): void {
    let diffAlert = true;
    if(!changes.alert.firstChange &&
      changes.alert.currentValue.alertDefinitionId
       === changes.alert.previousValue.alertDefinitionId){
        diffAlert = false;
       }
    this.catalogKeys = [];
    this.regions = [];
    this.regionsAll = [];
    this.emails = [];
    if(this.alert.catalogKeys !== null){
      this.formatRegionsAndCatalogs();
    }

    if(this.alert.emailSharing !== null) {
      this.alert.emailSharing.forEach((a) => {
        if(!a.isDeleted) { this.emails.push(a.email); }
      });
    }

     if(!changes.alert.firstChange && diffAlert){
      this.expirationTimers.forEach(e => clearInterval(e));
      this.reportService.resetHtmlElement(this.report.nativeElement);
      this.initReportsExternal();
     }
  }
  /**
   * Formatting list of search filters for dropdown.
   *
   * @memberof AlertsReportComponent
   */
  public formatRegionsAndCatalogs(){
    // Turn Catalog Keys into a string list
      this.catalogKeys = this.alert.catalogKeys;


    for(let i = 0; i < this.alert.regionalFilters.length; i++){
      //State by state
      const thisState = this.alert.regionalFilters[i];
      // Match state to the one in the complete filter list
      const detailedState = this.regionFilters.find(a => a.stateCode === thisState.state);
      // States where all counties are selected
      if(detailedState.counties.length === thisState.counties.length){
        this.regionsAll = [thisState.state + ' - ' + 'All Counties', ...this.regionsAll];
      }
      // States where specific counties are selected
      else{
        const detailedCounties = detailedState.counties.filter(a => thisState.counties.indexOf(a.countyId) > -1);

        detailedCounties.forEach(county => {
          this.regions = [...this.regions, thisState.state + ' - ' + county.countyName];
        });
      }
    }
    // Sort alphabetically
    this.regionsAll.sort((a, b)=> a > b ? 1 : -1);
    this.regions.sort((a, b)=> a > b ? 1 : -1);
  }

  public formatDate(inputDate: string) {
    const date = moment(inputDate).utcOffset(0);
    const local = date.local();
    return local.format('MM/DD/yyyy');
  }

  public initReportsExternal(): void {
    const alertId = this.alert.alertDefinitionId;

    this.subscriptions.push(
      this.reportService.getAlertEmbedReport(alertId).subscribe((x: EmbedReportViewModel) => {
        this.embedReport(x, this.report.nativeElement);
      },
      error => {
        console.error('Get Embed Report Error:', error);
      })
      );
  }

  public embedReport(embedReport: EmbedReportViewModel, nativeElement: any): void {
    const config = this.reportService.getIEmbedConfigurationBase(embedReport);
    const report = this.reportService.embedPowerBiReport(nativeElement, config);
    report.on('loaded', event => {
      this.setTokenExpirationListener(embedReport.embedToken, nativeElement);
    });
  }

  public setTokenExpirationListener(token: EmbedTokenViewModel, element: any, minutesToRefresh = -1) {
    const currentTime = Date.now();
    const expiration = Date.parse(token.expiration);
    const safetyInterval = minutesToRefresh * 60 * 1000;
    const timeout = expiration - currentTime - safetyInterval;

    if(timeout <= 0){
      this.initReportsExternal();
    }
    else {
      this.logService.writeLine('Report Embed Token will be updated in ' + timeout + ' milliseconds.');
      this.expirationTimers.push(
        setTimeout(() => {
          const alertId = this.alert.alertDefinitionId;
          this.subscriptions.push(
            this.reportService.getAlertEmbedReport(alertId).subscribe((x: EmbedReportViewModel) => {
              this.reportService.resetEmbedToken(element, x);
              this.setTokenExpirationListener(x.embedToken, element);
            }));
          this.logService.writeLine('Token reset');
        }, timeout)
      );
    }
  }

  public deleteAlert(){
    this.bsModalRef = this.modalService.show(ConfirmDeleteComponent, ModalSize.medium);
    this.bsModalRef.content.alertName = this.alert.name;

    this.subscriptions.push(
    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.modalService.hide(this.bsModalRef);
    })
    );
    this.subscriptions.push(
    this.bsModalRef.content.confirmEvent.subscribe(() => {
      this.reportService.resetHtmlElement(this.report.nativeElement);
      this.deleteEvent.emit();
      this.modalService.hide(this.bsModalRef);
    })
    );
  }

  public editAlert(){
    this.reportService.resetHtmlElement(this.report.nativeElement);
    this.editEvent.emit();
  }

  public closeAlert() {
    this.closeEvent.emit();
  }

}
