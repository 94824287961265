/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileComponent } from '../../../shared/profile/profile.component';
import { IdentityService } from '../../../../../src/app/services/identity.service';
import { ModalService, ModalSize } from '../../../../../src/app/services/modal.service';
import { StateService } from '../../../services/state.service';
import { CustomerService } from '../../../services/customer.service';
import { take } from 'rxjs/operators';
import { CustomerRole } from '../../../models/customer-role';

@Component({
  selector: 'app-authenticated-header',
  templateUrl: './authenticated-header.component.html',
  styleUrls: ['./authenticated-header.component.scss']
})
export class AuthenticatedHeaderComponent implements OnInit {
  @Output() sidebarOpenEvent = new EventEmitter<boolean>();
  @Input() isSidebarOpen: boolean;
  public bsModalRef: BsModalRef;
  public hasChildren = false;

  constructor(public identityService: IdentityService,
    public modalService: ModalService,
    public stateService: StateService,
    public customerService: CustomerService) { }

  ngOnInit(): void {
    //to see if customer name should be clickable
    this.checkHasChildren();
  }

  public logout(): void {
    this.identityService.logout();
  }

  public openModalProfile(chooseCustomer: boolean): void {
    this.bsModalRef = this.modalService.show(ProfileComponent, ModalSize.medium);

    //go straight to choose company
    if(chooseCustomer){
      this.bsModalRef.content.isSelectingCustomer = true;
      this.bsModalRef.content.skipProfile = true;
    }

    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.modalService.hide(this.bsModalRef);
    });
  }

  public toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.sidebarOpenEvent.emit(this.isSidebarOpen);
  }

  public parentCustomerName(): string {
    return this.stateService.effectiveParentCustomer.name;
  }

/**
 *Initial check on if the user's company has any child options.
 *
 * @memberof AuthenticatedHeaderComponent
 */
public checkHasChildren(){
    if(this.stateService.currentUser.customerRole.toLowerCase()
      === CustomerRole.internal.toLowerCase()){
      this.hasChildren = true;
      this.stateService.setHasChildren(true);
    }
    else{
      this.customerService.getAllChildrenForCustomer(
        this.stateService.currentUser.customerId
      ).pipe(take(1)).subscribe((result => {
        if(result.length > 1) {
          this.hasChildren = true;
          this.stateService.setHasChildren(true);
        }
        else {
          this.stateService.setHasChildren(false);
        }
      }));
   }
  }

  public childCustomerName(): string {
    return this.stateService.effectiveChildCustomer.name;
  }

  public childIsSelected(): boolean {
    return this.stateService.effectiveParentCustomer.id !== this.stateService.effectiveChildCustomer.id;
  }
}
