/*
 * All rights reserved.
 *
 * The entire contents of this file is protected by U.S. and
 * International Copyright Laws. Unauthorized reproduction,
 * reverse-engineering, and distribution of all or any portion of
 * the code contained in this file is strictly prohibited and may
 * result in severe civil and criminal penalties and will be
 * prosecuted to the maximum extent possible under the law.
 *
 * CONFIDENTIALITY
 *
 * This source code and all resulting intermediate files, as well as the
 * application design, are confidential and proprietary trade secrets of
 * Trimble Inc.
 */

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment';

@Component({
  selector: 'app-bpm',
  templateUrl: './bpm.component.html',
  styleUrls: ['../features.scss']
})
export class BpmComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(environment.appName + ' for Building Parts Manufacturers');
  }

}
