import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/analytics-portal/src/environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SuccessMessageComponent } from '../shared/success-message/success-message.component';
import { ModalService, ModalSize } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class InternalNotificationService {
  public bsModalRef: BsModalRef;

  constructor(public http: HttpClient, public modalService: ModalService) { }

  public sendRequestPremium(pageName: string) {
    this.showRequestPremiumSuccess();
    return this.http.post(`${environment.notificationServiceUrl}Internal/RequestPremium/${pageName}`, null);
  }

  public showRequestPremiumSuccess(){
    this.bsModalRef = this.modalService.show(SuccessMessageComponent, ModalSize.medium, false);
    this.bsModalRef.content.header = 'Request Sent';
    this.bsModalRef.content.message =
    'Thank you! Your request is being processed. Please expect a member of our team to reach out shortly.';
    this.bsModalRef.content.closeEvent.subscribe(() => {
      this.modalService.hide(this.bsModalRef);
    });
  }
}
