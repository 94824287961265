<div class="modal-header" id="releaseNotesHeader">
  <h5 class="modal-title" id="releaseNotesTitleModal">Release Notes</h5>
  <button type="button" class="close" id="releaseNotesCloseButton" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="row release-notes-row" id="currentReleaseNotes" *ngIf="currentReleaseNotes !== undefined" >
  <div class="col-sm release-note">
    <a href="{{ currentReleaseNotes.url }}" target="_blank" rel="noopener noreferrer" id="releaseNotesAnchor">
      <img src="{{ currentReleaseNotes.img }}" class="thumbnail-img" alt="release notes thumbnail"/><br />
      <span class="desc" style="font-size: 120%;">{{ currentReleaseNotes.title }} - {{currentReleaseNotes.date}}</span>
    </a>
  </div>
</div>
<div *ngIf="historicalReleaseNotes.length !== 0" class="accordion" id="accordionCodeExample">
  <div class="card">
    <div class="card-header" [ngClass]="{'collapsed' : !isOpen}" id="accordionHeadingOne"  toggle="collapse" data-target="#codeCollapseOne"
      aria-expanded="false" aria-controls="codeCollapseOne" (click)="toggle()">
      <h6 class="mb-0" id="previousReleaseNotes">
        Previous Release Notes
      </h6>
    </div>
    <div id="codeCollapseOne" class="collapse" aria-labelledby="accordionHeadingOne"
      data-parent="#accordionCodeExample" [ngClass]="{'show': isOpen }" >
      <div class="card-body">
        <div class="release-notes" *ngFor="let note of historicalReleaseNotes; let i = index">
          <div class="row release-notes-row">
            <div class="col-sm release-note">
              <a href="{{ historicalReleaseNotes[i].url }}" target="_blank" rel="noopener noreferrer">
                <span class="desc">{{ historicalReleaseNotes[i].title }} - {{historicalReleaseNotes[i].date}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="historicalReleaseNotes.length === 0 && currentReleaseNotes === undefined">
  <h3 id="releaseNotesNotPresent">
    No release notes available for {{userRole.toLowerCase()}} users yet!
  </h3>
</div>
