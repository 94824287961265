/*
* Copyright © 2021, Trimble Inc.
* All rights reserved.
*
* The entire contents of this file is protected by U.S. and
* International Copyright Laws. Unauthorized reproduction,
* reverse-engineering, and distribution of all or any portion of
* the code contained in this file is strictly prohibited and may
* result in severe civil and criminal penalties and will be
* prosecuted to the maximum extent possible under the law.
*
* CONFIDENTIALITY
*
* This source code and all resulting intermediate files, as well as the
* application design, are confidential and proprietary trade secrets of
* Trimble Inc.
*/

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '../../../../../src/app/services/state.service';
import { TermsOfServiceComponent } from '../../../../../src/app/shared/terms-of-service/terms-of-service.component';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})

export class TermsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('terms') terms: TermsOfServiceComponent;

  constructor(public stateService: StateService,
      public storageService: StorageService,
     public router: Router) {
    this.stateService.setHideHeaderFooterAndNav(true);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.terms.closeEvent.subscribe(() => {
      this.terms.decline();
    });

    this.terms.agreeEvent.subscribe(() => {
      if(this.storageService.getRedirectUrl().indexOf('contractor') > 0){
        this.router.navigateByUrl(this.storageService.getRedirectUrl());
      }
      else{
        this.router.navigateByUrl(this.stateService.getDefaultRouterPath());
      }
    });
  }

  ngOnDestroy(): void {
    this.stateService.setHideHeaderFooterAndNav(false);
  }
}
