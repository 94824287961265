import { Injectable, OnDestroy } from '@angular/core';
import { DataSharingComponent } from '@hcworkspace/analytics-portal/data-sharing';
import { ErrorService } from 'apps/analytics-portal/src/app/services/error.service';
import { ModalService, ModalSize } from 'apps/analytics-portal/src/app/services/modal.service';
import { StateService } from 'apps/analytics-portal/src/app/services/state.service';
import { ToastService } from 'apps/analytics-portal/src/app/services/toast.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService implements OnDestroy{
  public modalRef: BsModalRef;
  public subscriptions: Subscription[] =[];

  constructor(
    public modalService: ModalService,
    public stateService: StateService,
    public errorService: ErrorService,
    public toastService: ToastService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public setUpDataSharingModal(closeButton: boolean, getUserRelations: boolean, navigateToRedirectUrl: () => void = () => {}) {
    this.modalService.hideAll();
      this.modalRef = this.modalService.show(DataSharingComponent, ModalSize.medium, true, {
        imagePath: '../../../../assets/images/backgrounds/data-sharing-background.png',
        logoPath: '../../../../assets/images/Logo-Construction-Analytics-RGB-Full-Horizontal@120.png',
        title: 'Welcome to Trimble Analytics',
        // eslint-disable-next-line max-len
        text: 'Construction Analytics is your organization\'s performance engine, calibrated to your needs.\n\nBenefit from real-time insights on item use trends, track project status, and more across your organization.  For contractor users only, by using Construction Analytics, you may be sharing certain project level information including company name, project name, company state, item number and details, and quantity with other Construction Analytics users in your organization and your preferred parts manufacturers\n\nPlease allow 24 hours for your changes to take effect.',
        buttonText: 'Continue',
        consentRelationUuid: null,
        accepted: null,
        userUuid: this.stateService.currentUser.userId,
        getUserRelations,
        closeButton
      });
      this.subscriptions.push(this.modalRef.content.errorResponse.subscribe(
        () => {
          // this.modalRef.hide();
          this.modalService.hide(this.modalRef);
          this.toastService.error('We encountered an issue saving your data sharing preference. You will be prompted again at sign in.');
          navigateToRedirectUrl();
        }
      ));
      this.subscriptions.push(this.modalRef.content.acceptSubmitted.subscribe(() => {
        this.dataSharingSubmitted(true, navigateToRedirectUrl);
      }));
      this.subscriptions.push(this.modalRef.content.rejectSubmitted.subscribe(() => this.dataSharingSubmitted(false)));
      this.modalRef.content.closeEvent.subscribe(() => this.modalService.hide(this.modalRef));
  }

  public dataSharingSubmitted(preference: boolean, navigateToRedirectUrl: () => void = () => {}) {
    this.stateService.setDataSharingAccepted(preference);
    // this.modalRef.hide();
    this.modalService.hide(this.modalRef);
    navigateToRedirectUrl();
  }
}
